import type { FallbackProps } from 'react-error-boundary';
import React, { useMemo } from 'react';
import { ReloadRequiredDialog } from '@components/common/molecules';

const isDynamicallyImportedModuleError = (message: string) =>
  /Failed to fetch dynamically imported module/.test(message);

const GlobalErrorDialog: React.FC<FallbackProps> = ({ error }) => {
  /**
   * エラーのタイトル
   */
  const errorTitle = useMemo(() => {
    return isDynamicallyImportedModuleError(error.message)
      ? 'dialog.dynamically_imported_module_error.title'
      : 'dialog.other_error.title';
  }, [error]);
  /**
   * エラーのテキスト
   */
  const errorMessage = useMemo(() => {
    return isDynamicallyImportedModuleError(error.message)
      ? 'dialog.dynamically_imported_module_error.message'
      : error.message;
  }, [error]);

  return (
    <ReloadRequiredDialog
      isOpen={true}
      title={errorTitle}
      message={errorMessage}
    />
  );
};
export default React.memo(GlobalErrorDialog);
