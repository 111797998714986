import {
  Box,
  colors,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import type { FileItem } from '@utils/file';
import { getExtension, getFileTree, ignoredHiddenFiles } from '@utils/file';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useMemo, useCallback } from 'react';
import { File, Folder } from 'react-feather';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useTranslation } from 'react-i18next';

type Props = {
  files: File[];
  hideHiddenFiles: boolean;
  height?: string;
};

const FileBrowser: React.FC<Props> = ({
  files,
  hideHiddenFiles,
  height = 'auto',
}) => {
  const { t } = useTranslation();

  const fileTree = useMemo(
    () => getFileTree(hideHiddenFiles ? ignoredHiddenFiles(files) : files),
    [files, hideHiddenFiles],
  );

  const getTreeElement = useCallback((item: FileItem) => {
    // 拡張子
    const ext = getExtension(item.name);
    const isDirectory = item.type === 'directory';
    return (
      <React.Fragment
        key={`${item.originalFile.webkitRelativePath}_${item.name}`}
      >
        {!item.isRootDirectory && (
          <ListItem
            sx={{
              backgroundColor: isDirectory
                ? 'rgb(245, 248, 250)'
                : 'transparent',
            }}
          >
            <Box
              display="flex"
              px={8 * (item.level - 1) + 4}
              py={2}
              alignItems="center"
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                {isDirectory ? (
                  <Folder fill={colors.blue[500]} color={colors.blue[500]} />
                ) : !isNullOrUndefined(ext) ? (
                  <Box width={20} height={24}>
                    <FileIcon extension={ext} {...defaultStyles[ext]} />
                  </Box>
                ) : (
                  <File />
                )}
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </Box>
          </ListItem>
        )}
        {isDirectory && (
          <ListItem disablePadding>
            <FileList dense>
              {item.children.map((i) => getTreeElement(i))}
            </FileList>
          </ListItem>
        )}
      </React.Fragment>
    );
  }, []);

  const treeUi = useMemo(() => {
    if (fileTree.length === 0)
      return (
        <Box>
          <Typography>
            {t(
              'vehicle_condition.parameter.register.select_directory.file_not_found',
            )}
          </Typography>
        </Box>
      );
    return (
      <Wrapper height={height}>
        <FileList dense>{getTreeElement(fileTree[0])}</FileList>
      </Wrapper>
    );
  }, [fileTree, getTreeElement, t, height]);

  return <>{treeUi}</>;
};

export default React.memo(FileBrowser);

const Wrapper = styled(Box)`
  border: solid 1px ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  overflow: auto;
`;

const FileList = styled(List)`
  padding: 0;
  width: 100%;

  li {
    border-bottom: solid 1px ${({ theme }) => theme.palette.grey[300]};

    &:last-of-type {
      border-bottom: none;
    }
  }
`;
