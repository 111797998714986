import JSZip from 'jszip';
import isNullOrUndefined from './isNullOrUndefined';
import type { DefaultExtensionType } from 'react-file-icon';

export type FileItem = {
  name: string;
  path: string;
  children: FileItem[];
  level: number;
  type: 'file' | 'directory';
  isRootDirectory: boolean;
  extensition: string | null;
  originalFile: File;
};

/**
 * ファイル名から拡張子を返す
 * @param filename
 * @returns
 */
export const getExtension = (filename: string): DefaultExtensionType | null => {
  if (isNullOrUndefined(filename)) return null;
  const nameSplit = filename.split('.');
  let ext = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : null;
  if (ext === 'yaml') ext = 'yml';
  return ext as DefaultExtensionType;
};

/**
 * 不可視ファイルを排除
 * @param files
 * @returns
 */
export const ignoredHiddenFiles = (files: File[]) =>
  files.filter((f) => f.name.charAt(0) !== '.');

/**
 *
 * @param files
 * @returns
 */
export const getFileTree = (files: File[]) => {
  if (files.length === 0) return [];

  const result: FileItem[] = [];
  const level: {
    // eslint-disable-next-line
    [key: string]: any;
    result: FileItem[];
  } = { result };

  files.forEach((file) => {
    file.webkitRelativePath.split('/').reduce((current, name) => {
      if (!current[name]) {
        current[name] = { result: [] };
        current.result.push({
          name,
          path: '',
          children: current[name].result,
          originalFile: file,
          level: 0,
          type: 'file',
          isRootDirectory: false,
          extensition: null,
        });
      }
      return current[name];
    }, level);
  });

  const updateItem = (item: FileItem, isRoot = false) => {
    const isDirectory = item.children.length > 0;
    const isRootDirectory = isRoot;
    const path = item.originalFile.webkitRelativePath.substring(
      0,
      item.originalFile.webkitRelativePath.lastIndexOf(item.name),
    );
    const pathLength = path.split('/').length;
    const level = isRootDirectory ? 0 : pathLength - 1;
    const extensition = getExtension(item.name);
    const type = isDirectory ? 'directory' : 'file';
    item.path = path;
    item.type = type;
    item.isRootDirectory = isRootDirectory;
    item.level = level;
    item.extensition = extensition;
    if (item.children.length > 0) {
      item.children.forEach((childItem) => {
        updateItem(childItem);
      });
    }
  };

  updateItem(result[0], true);

  return result;
};

/**
 * ZIP圧縮
 */
export const compressIndividualParameter = async (
  files: File[],
  description: string,
): Promise<Blob | null> => {
  const zip = new JSZip();
  const rootFolder = zip.folder('calibration_parameter');
  if (isNullOrUndefined(rootFolder)) return null;
  rootFolder.file(
    'meta.json',
    `{
  "calibration_parameter": {
    "description": "${description.replaceAll('\n', '\\n')}"
  }
}`,
  );
  const filesFolder = rootFolder.folder('files');
  if (isNullOrUndefined(filesFolder)) return null;

  const fileTree = getFileTree(files);

  const addFile = (folder: JSZip, item: FileItem) => {
    if (item.children.length > 0) {
      const childFolder = folder.folder(item.name);
      item.children.forEach((childItem) => {
        addFile(childFolder!, childItem);
      });
      return;
    }
    folder.file(item.name, item.originalFile, { binary: true });
  };

  fileTree[0].children.forEach((item) => {
    addFile(filesFolder, item);
  });

  try {
    const blob = await zip.generateAsync({ type: 'blob' });
    console.log(blob);
    return blob;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const convertBytesToMb = (byte: number) => {
  if (isNullOrUndefined(byte)) {
    return '0';
  }
  return (byte / 1000 / 1000).toFixed(3);
};
