import axios from 'axios';
import { useCallback } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

// 各サービスのステータスエンドポイント
const STATUS_ENDPOINT = {
  'TIER IV Account': `${import.meta.env.VITE_OIDC_PROVIDER_ENDPOINT}status`,
  'Web.Auto Auth': `https://${import.meta.env.VITE_AUTH_API_DOMAIN}/status`,
  FMS: `https://${import.meta.env.VITE_FMS_API_DOMAIN}/status`,
} as const;

const useServiceStatus = () => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();

  /**
   * サービスのステータス確認
   */
  const checkServiceStatus = useCallback(async () => {
    const statuses = await Promise.all(
      Object.entries(STATUS_ENDPOINT).map(async ([key, value]) => {
        try {
          const res = await axios.get(value);
          console.log(
            `%c${key} status:`,
            `color: #2196f3`,
            res.data.status.toUpperCase(),
          );
          return res.data.status;
        } catch {
          showBoundary(
            new Error(
              t('api.service_status', { status: 'failed', service_name: key }),
            ),
          );
          return null;
        }
      }),
    );

    if (statuses.includes(null)) {
      // いずれかのステータス取得に失敗した場合
      return false;
    }

    if (statuses.includes('active')) {
      // メンテナンス中の場合は 503 ページに遷移
      location.href = '/503.html';
      return false;
    }

    return true;
  }, [t, showBoundary]);

  return {
    checkServiceStatus,
  };
};

export default useServiceStatus;
