import type {
  RegisterScheduleType,
  BasicPlace,
  LoopPlace,
  EditTimetable,
  SelectedLanelet,
  TimetablePlaceData,
  SearchedRoute,
  TimetableDayData,
} from './types';
import { DateTime } from 'luxon';
import type {
  Timetable,
  TimetableDayOfTheWeek,
} from '@data/fms/schedule/types';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const MAX_VIA_POINT_NUM = 9;

export const defaultBasicPlaces: BasicPlace[] = [
  {
    isManual: false,
    name: '',
    point_id: -1,
    location: {
      lat: 0,
      lng: 0,
    },
    viaPointLaneletIds: [],
    partialRouteSearchErrorIndexs: [],
    allow_goal_modification: false,
  },
];

export const defaultLoopPlaces: LoopPlace[] = [
  {
    name: '',
    point_id: -1,
    location: {
      lat: 0,
      lng: 0,
    },
    viaPointLaneletIds: [],
    partialRouteSearchErrorIndexs: [],
    allow_goal_modification: false,
  },
];

export const defaultTimetableDay = (): TimetableDayData => ({
  routes: [],
});

export const defaultTimetable = (): EditTimetable => ({
  monday: defaultTimetableDay(),
  tuesday: defaultTimetableDay(),
  wednesday: defaultTimetableDay(),
  thursday: defaultTimetableDay(),
  friday: defaultTimetableDay(),
  saturday: defaultTimetableDay(),
  sunday: defaultTimetableDay(),
});

// 選択中のスケジュールタイプ
export const selectedScheduleTypeAtom =
  atomWithReset<RegisterScheduleType>('basic');

// ルートサーチ結果の配列
export const searchedRoutesAtom = atomWithReset<(SearchedRoute | null)[]>([]);

//
export const isTimetableStartTimeAdjustedAtom = atomWithReset<{
  [key: string]: boolean;
}>({ '0': true });

// 編集中の片道スケジュールルート
export const editBasicPlacesAtom =
  atomWithReset<BasicPlace[]>(defaultBasicPlaces);

// 片道スケジュールで編集中の手動運転
export const editingManualDriveSettingAtom = atomWithReset<{
  no: number | null;
  duration: number | null;
}>({
  no: null,
  duration: null,
});

// 編集中の巡回ケジュールルート
export const editLoopPlacesAtom = atomWithReset<LoopPlace[]>([]);

// タイムテーブル
export const editWeekTimetableAtom = atomWithReset<EditTimetable>(
  defaultTimetable(),
);

export const initialWeekTimetableAtom = atomWithReset<EditTimetable>(
  defaultTimetable(),
);

export const selectedDayOfWeekAtom =
  atomWithReset<TimetableDayOfTheWeek>('monday');

export const registeredDayOfWeekTimetableAtom = atomWithReset<Timetable[]>([]);

export const selectedDayOfWeekTimetableAtom = atom((get) => {
  const editTimetable = get(editWeekTimetableAtom);
  const selectedDayOfWeek = get(selectedDayOfWeekAtom);
  return editTimetable[selectedDayOfWeek];
});

type SelectedViaPoint = {
  isEditing: boolean;
  routeIndex: number;
  lane: SelectedLanelet | null;
};

// 選択されたレーン情報
export const selectedViaPointAtom = atomWithReset<SelectedViaPoint>({
  isEditing: false,
  routeIndex: 0,
  lane: null,
});

/**
 * 編集中の経路を返す
 */
export const editPlacesSelector = atom<
  BasicPlace[] | LoopPlace[] | TimetablePlaceData[]
>((get) => {
  const selectedScheduleType = get(selectedScheduleTypeAtom);
  if (selectedScheduleType === 'basic') {
    return get(editBasicPlacesAtom);
  }
  if (selectedScheduleType === 'loop') {
    return get(editLoopPlacesAtom);
  }
  if (selectedScheduleType === 'timetable') {
    return get(selectedDayOfWeekTimetableAtom).routes;
  }
  return [];
});

/**
 * 運行ダイヤの設定されている予定開始時刻が昇順になっているかどうか
 */
export const validTimetableStartTimesSelector = atom((get) => {
  const places = get(selectedDayOfWeekTimetableAtom).routes;
  const valid = places.every((place, i) => {
    if (i === 0 || i === places.length - 1) return true;
    const prev = places[i - 1];
    return (
      DateTime.fromISO(prev.startTime).endOf('minute') <
      DateTime.fromISO(place.startTime).endOf('minute')
    );
  });
  return valid;
});

/**
 * RouteSearch 用の ID を返す
 */
export const getRouteSearchId = (param: {
  index: number;
  origin_point: number | null;
  destination_point: number | null;
  laneletIds?: string[];
}) => {
  const { index, origin_point, destination_point, laneletIds } = param;
  let id = index.toString();
  if (!origin_point) {
    id += `_destination_point=${destination_point}`;
  } else {
    id += `_origin_point=${origin_point}&destination_point=${destination_point}`;
  }
  if (laneletIds && laneletIds.length > 0) {
    id += `&lanelet_ids=${laneletIds.join(',')}`;
  }
  return id;
};
