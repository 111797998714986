import { useCallback, useRef, useState } from 'react';

// ping を送る間隔（ミリ秒数）
const PING_INTERVAL = 3000;
// ping を送ってから非接続とみなす期間（ミリ秒数）
const TIMEOUT_THRESHOLD = 3000;

export const useFMSWebSocketHeartBeat = () => {
  const [isConnecting, setIsConnecting] = useState(true);
  const unConnectTimer = useRef<number | undefined>();
  const checkTimer = useRef<number | undefined>();
  const socketRef = useRef<WebSocket>();

  const checkConnection = useCallback((socket: WebSocket) => {
    socketRef.current = socket;
    socket.send('ping');
    unConnectTimer.current = window.setTimeout(() => {
      // TIMEOUT_THRESHOLD ミリ秒待っても pong が受信できない場合
      unConnectTimer.current = undefined;
      setIsConnecting(false);
    }, TIMEOUT_THRESHOLD);
  }, []);

  const checkReceivedData = useCallback(
    (e: MessageEvent) => {
      if (e.data === 'pong') {
        setIsConnecting(true);
        if (unConnectTimer.current) {
          // pong チェックタイマーをクリア
          window.clearTimeout(unConnectTimer.current);
        }
        checkTimer.current = window.setTimeout(() => {
          // CHECK_DELAY ミリ秒数後に ping を送信
          if (!socketRef.current) return;
          checkConnection(socketRef.current);
        }, PING_INTERVAL);
      }
    },
    [checkConnection],
  );

  const closeHeartBeat = useCallback(() => {
    window.clearTimeout(unConnectTimer.current);
    window.clearTimeout(checkTimer.current);
  }, []);

  return {
    isConnecting,
    checkConnection,
    checkReceivedData,
    closeHeartBeat,
  };
};
