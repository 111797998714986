import { appliedNoEntryZoneSettingAtom } from '@data/fms/areamap/states';
import { isCurrentlyAppliedNoEntryZone } from '@data/fms/noEntryZone/states';
import { mapMenuAtom } from '@data/ui/states';
import styled from '@emotion/styled';
import { Block } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const NoEntryZonesMenu: React.FC = () => {
  const { t } = useTranslation();
  const [mapMenu, setMapMenu] = useAtom(mapMenuAtom);
  const appliedNoEntryZoneSetting = useAtomValue(appliedNoEntryZoneSettingAtom);

  const checks = useMemo(
    () => Object.keys(mapMenu.noEntryZones),
    [mapMenu.noEntryZones],
  );

  const isAllUnselected = useMemo(
    () => !checks.filter((key) => mapMenu.noEntryZones[key]).length,
    [checks, mapMenu.noEntryZones],
  );

  const handleChangeShowAllNoEntryZones = useCallback(() => {
    setMapMenu((prevState) => {
      const checks = Object.keys(prevState.noEntryZones);
      const isAllSelected = checks.every((key) => prevState.noEntryZones[key]);
      return {
        ...prevState,
        noEntryZones: Object.fromEntries(
          Object.keys(prevState.noEntryZones).map((key) => [
            key,
            !isAllSelected,
          ]),
        ),
      };
    });
  }, [setMapMenu]);

  const handleChangeShowNoEntryZone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.currentTarget.dataset.id;
      if (!id) return;
      setMapMenu((prevState) => ({
        ...prevState,
        noEntryZones: {
          ...prevState.noEntryZones,
          [id]: !prevState.noEntryZones[id],
        },
      }));
    },
    [setMapMenu],
  );

  if (!appliedNoEntryZoneSetting?.no_entry_zones.length) {
    return null;
  }

  return (
    <>
      <Divider sx={{ mb: 3 }} />
      <Typography>{t('map_menu.no_entry_zones.title')}</Typography>
      <FormControlLabel
        label={t('map_menu.no_entry_zones.all')}
        control={
          <Checkbox
            checked={!isAllUnselected}
            size="small"
            indeterminate={
              !isAllUnselected &&
              !checks.every((key) => mapMenu.noEntryZones[key])
            }
            onChange={handleChangeShowAllNoEntryZones}
          />
        }
      />
      <Box display="flex" flexDirection="column" ml={2}>
        {appliedNoEntryZoneSetting.no_entry_zones.map((zone) => (
          <FormControlLabel
            key={zone.no_entry_zone_id}
            label={
              <>
                {isCurrentlyAppliedNoEntryZone(zone) && (
                  <CurrentLabel>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item sx={{ width: 14, height: 14, mr: 1 }}>
                        <Block
                          fontSize="small"
                          sx={{ width: 14, height: 14 }}
                        />
                      </Grid>
                      <Grid item>
                        {t(
                          'driving_environment.no_entry_zone_setting.map.currently_applied_no_entry_zone',
                        )}
                      </Grid>
                    </Grid>
                  </CurrentLabel>
                )}
                <span style={{ wordBreak: 'break-word' }}>{zone.name}</span>
              </>
            }
            control={
              <Checkbox
                size="small"
                inputProps={
                  {
                    'data-id': zone.no_entry_zone_id,
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                checked={mapMenu.noEntryZones[zone.no_entry_zone_id]}
                onChange={handleChangeShowNoEntryZone}
              />
            }
          />
        ))}
      </Box>
    </>
  );
};

export default NoEntryZonesMenu;

const CurrentLabel = styled(Box)`
  display: inline-block;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 4px;
  padding: 4px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.06em;
  color: white;
  font-size: 12px;
`;
