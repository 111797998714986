import type { HTMLAttributes } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Routes from '@routes';
import theme from '@theme';
import { colors, CssBaseline, GlobalStyles, styled } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import * as Sentry from '@sentry/browser';
import { Warning } from '@mui/icons-material';
import { MediaClient } from '@tier4/webauto-media-client';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import './data/i18n';
import useGlobalStyle from '@theme/globalStyle';
import store from 'store2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { Provider } from 'jotai';

// 通知最大数
const MAX_SNACK_BAR_NUM = 8;

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const sentryEnvType = import.meta.env.VITE_SENTRY_ENV_TYPE;
const commitHash = import.meta.env.VITE_COMMIT_HASH;

if (
  !isNullOrUndefined(sentryDSN) &&
  !isNullOrUndefined(sentryEnvType) &&
  !isNullOrUndefined(commitHash)
) {
  Sentry.init({
    dsn: sentryDSN,
    environment: sentryEnvType,
    ignoreErrors: [
      'Failed to fetch dynamically imported module',
      'error response 458: No such session',
    ],
    denyUrls: [/maps\.googleapis\.com/i],
    release: commitHash,
    integrations: [Sentry.browserTracingIntegration()],
  });
}

Chart.register(...registerables, annotationPlugin);

MediaClient.Config.mediaApiBaseUrl = `https://${
  import.meta.env.VITE_MEDIA_API_DOMAIN
}`;
MediaClient.Config.mediaStreamBaseUrl = `wss://${
  import.meta.env.VITE_MEDIA_API_DOMAIN
}`;

const domRoot = document.getElementById('app');

const RoutesWrapper = () => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      dateLibInstance={DateTime}
      adapterLocale={store.local.get('i18nextLng')}
      dateFormats={
        store.local.get('i18nextLng') === 'ja'
          ? {
              monthAndYear: 'yyyy年M月',
              monthShort: 'M月',
            }
          : undefined
      }
    >
      <StyledSnackbarProvider
        domRoot={domRoot!}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        maxSnack={MAX_SNACK_BAR_NUM}
        autoHideDuration={null}
        SnackbarProps={
          {
            'data-id': 'snackbar',
            'data-testid': 'snackbar',
          } as unknown as HTMLAttributes<HTMLDivElement>
        }
        iconVariant={{
          error: (
            <Warning fontSize="small" sx={{ p: 0, marginInlineEnd: '8px' }} />
          ),
        }}
      >
        <Routes />
      </StyledSnackbarProvider>
    </LocalizationProvider>
  );
};

const router = createBrowserRouter([{ path: '*', Component: RoutesWrapper }]);

const Root = () => {
  const globalStyle = useGlobalStyle();

  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={globalStyle} />
      <Provider>
        <RouterProvider router={router} />
      </Provider>
    </>
  );
};

const App = () => (
  <>
    <Helmet titleTemplate="%s | FMS Console" defaultTitle="FMS Console" />
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </>
);

// アプリレンダリング
const root = createRoot(domRoot!);
root.render(<App />);

const StyledSnackbarProvider = styled(SnackbarProvider)`
  max-width: 640px;
  word-break: break-word;

  &.notistack-MuiContent-info {
    background-color: ${colors.blue[700]};
  }
  &.notistack-MuiContent-success {
    background-color: ${colors.green[600]};
  }
  &.notistack-MuiContent-warning {
    background-color: ${colors.amber[700]};
  }
  &.notistack-MuiContent-error {
    background-color: ${colors.red[700]};
  }
  &.notistack-MuiContent {
    flex-wrap: nowrap;
    align-items: flex-start;

    #notistack-snackbar {
      align-items: flex-start;
    }
  }
`;
