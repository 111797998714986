import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import type { WebAutoApplicationEnvironmentOption } from '@data/fms/environment/types';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 走行環境オプション情報取得API
 */
export const useWebAutoApplicationEnvironmentOptionAPI = (): {
  state: APIState;
  getWebAutoApplicationEnvironmentOption: () => Promise<WebAutoApplicationEnvironmentOption | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getWebAutoApplicationEnvironmentOptionAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<WebAutoApplicationEnvironmentOption, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/web_auto_application_environment_option`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getWebAutoApplicationEnvironmentOption = useAtomCallback(
    useCallback(
      async (get) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribeWebAutoAppOption)) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_web_auto_application_environment', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await getWebAutoApplicationEnvironmentOptionAPI(
          project!.id,
          environment!.environment_id,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_web_auto_application_environment', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [
        notifyError,
        getWebAutoApplicationEnvironmentOptionAPI,
        getHasScope,
        t,
        getErrorMessage,
      ],
    ),
  );

  return {
    state,
    getWebAutoApplicationEnvironmentOption,
  };
};
