import { BASE_RETRY_DELAY, MAX_RETRY_JITTER } from './constants';

/**
 * @param {number} count
 * @returns {number}
 */
export const getExponentialBackoffDelay = (count: number) => {
  // バックオフ（リトライ回数^2 * 1000）
  const backoff = Math.pow(2, count) * BASE_RETRY_DELAY;
  // ジッター（0以上1未満のランダム値 * 2000）
  const jitter = Math.floor(Math.random() * MAX_RETRY_JITTER);
  return backoff + jitter;
};
