export const VEHICLE_FIRMWARE = {
  AI: 'Autoware.AI',
  T4Beta: 'Autoware.T4-beta',
  Simulator: 'Autoware.IV-simulator',
} as const;

export const VEHICLE_CATEGORIES = ['actual_vehicle', 'simulator', ''] as const;

export const ROS_VERSION = {
  ROS1: 'ROS1',
  ROS2: 'ROS2',
} as const;
