import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
};

const ReloadRequiredDialog: React.FC<Props> = ({
  isOpen,
  title,
  message,
}: Props) => {
  const { t } = useTranslation();

  /**
   * リロードボタンクリック
   */
  const handleClickReloadButton = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText>
          <Trans i18nKey={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClickReloadButton}>
          {t('common.action.reload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ReloadRequiredDialog);
