import isNullOrUndefined from './isNullOrUndefined';

export const oidcErrorDescription = (): string | null => {
  const params = new URLSearchParams(location.search.substring(1));
  const error = params.get('error');
  const errorDescription = params.get('error_description');
  if (!isNullOrUndefined(error) && !isNullOrUndefined(errorDescription)) {
    return errorDescription;
  }
  return null;
};
