import { useLogFileAPI } from '@api/datahub/getLogFile';
import type { SyslogFile } from '@data/datahub/log/types';
import { Badge, Button } from '@mui/material';
import { useConcatWord } from '@utils/i18n';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  syslogFiles: SyslogFile[];
};

const SyslogDownloadButton: React.FC<Props> = ({ syslogFiles }: Props) => {
  const { t } = useTranslation();
  const { concat } = useConcatWord();
  const { downloadSyslogFiles } = useLogFileAPI();

  const handleClickDownload = useCallback(async () => {
    downloadSyslogFiles(syslogFiles);
  }, [syslogFiles, downloadSyslogFiles]);

  return (
    <Badge color="primary" badgeContent={syslogFiles.length}>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        disabled={syslogFiles.length === 0}
        onClick={handleClickDownload}
        data-testid="syslog-download-btn"
      >
        {concat([t('vehicle.syslog'), t('common.action.download')], true)}
      </Button>
    </Badge>
  );
};

export default React.memo(SyslogDownloadButton);
