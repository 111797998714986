import React, { useEffect, useMemo } from 'react';
import { Marker, useGoogleMap } from '@react-google-maps/api';
import { colors } from '@mui/material';
import { usePrevious } from 'react-use';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { LatLng } from '@data/fms/place/types';

type Props = {
  no: number;
  latLng: LatLng;
  type: 'error' | 'foa';
  selectedTime: string;
  foreground?: boolean;
  visible?: boolean;
};

const ErrorFoaMarker: React.FC<Props> = ({
  type,
  no,
  latLng,
  selectedTime,
  foreground = false,
  visible = true,
}: Props) => {
  const map = useGoogleMap();
  const prevNo = usePrevious(no);
  const prevLatLng = usePrevious(latLng);
  const prevSelectedTime = usePrevious(selectedTime);

  const markerColor = useMemo(
    () => (type === 'error' ? colors.red[700] : colors.lightGreen[700]),
    [type],
  );
  const iconSvg = useMemo(
    () =>
      [
        `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30">
          <g transform="translate(-258 -192)">
            <path d="M14.857,24H8a4,4,0,0,1-4-4V4A4,4,0,0,1,8,0H32a4,4,0,0,1,4,4V20a4,4,0,0,1-4,4H25.143L20,30Z" transform="translate(254 192)" fill="${markerColor}"/>
            <text transform="translate(274 208)" fill="#fff" font-size="12" font-family="Barlow-SemiBold, Barlow" font-weight="600">
              <tspan x="0" y="0" text-anchor="middle">${no}</tspan>
            </text>
          </g>
        </svg>`,
      ].join('\n'),
    [no, markerColor],
  );
  const icon = useMemo(
    () => ({
      url: `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(iconSvg)}`,
      anchor: new window.google.maps.Point(16, 31),
    }),
    [iconSvg],
  );

  useEffect(() => {
    if (isNullOrUndefined(map) || isNullOrUndefined(foreground)) return;
    if (
      prevLatLng?.lat === latLng.lat &&
      prevLatLng.lng === latLng.lng &&
      prevNo === no &&
      prevSelectedTime === selectedTime
    ) {
      return;
    }
    map.setCenter(latLng);
  }, [
    map,
    foreground,
    latLng,
    prevLatLng,
    no,
    prevNo,
    selectedTime,
    prevSelectedTime,
  ]);

  return (
    <Marker
      zIndex={foreground ? 1000 : 50}
      clickable={false}
      position={latLng}
      icon={icon}
      visible={visible}
    />
  );
};

export default React.memo(ErrorFoaMarker);
