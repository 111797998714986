import type { EventData } from './types';

// ネットワーク途絶 || シャットダウン イベントかどうか
export const isNetworkDisconnectedData = (data: unknown) => {
  if (typeof data !== 'object') return false;
  if (!data) return false;

  const { type } = data as EventData;

  if (
    type !== 'shutdown_start' &&
    type !== 'shutdown_end' &&
    type !== 'network_connected' &&
    type !== 'network_disconnected'
  ) {
    return false;
  }

  return true;
};
