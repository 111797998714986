import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { areaMapVersionsAtom } from '@data/fms/areamap/states';
import type {
  NoEntryZoneSetting,
  NoEntryZoneSettingSummary,
} from '@data/fms/noEntryZone/type';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomCallback } from 'jotai/utils';

/**
 *  通行禁止区域設定一覧取得API
 */
export const useNoEntryZoneSettingsAPI = (): {
  state: APIState;
  getNoEntryZoneSettings: () => Promise<NoEntryZoneSettingSummary[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<NoEntryZoneSettingSummary[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/no_entry_zone_settings`,
        );
        return new Success(res?.data?.no_entry_zone_settings ?? []);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getNoEntryZoneSettings = useAtomCallback(
    useCallback(
      async (get) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return [];
        }
        const res = await request(project.id, environment.environment_id);
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getNoEntryZoneSettings,
  };
};

/**
 *  特定の通行禁止区域設定取得API
 */
export const useNoEntryZoneSettingAPI = (): {
  state: APIState;
  getNoEntryZoneSetting: (
    noEntryZoneSettingId: string,
  ) => Promise<NoEntryZoneSetting | null>;
  getAppliedNoEntryZoneSetting: () => Promise<NoEntryZoneSetting | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      noEntryZoneSettingId: string,
    ): Promise<Result<NoEntryZoneSetting, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/no_entry_zone_settings/${noEntryZoneSettingId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getNoEntryZoneSetting = useAtomCallback(
    useCallback(
      async (get, _, noEntryZoneSettingId: string) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }
        const res = await request(
          project.id,
          environment.environment_id,
          noEntryZoneSettingId,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  // エリアマップバージョンに反映されている通行禁止区域設定を取得
  const getAppliedNoEntryZoneSetting = useAtomCallback(
    useCallback(
      async (get) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        const areaMapVersions = get(areaMapVersionsAtom);
        const currentAreaMapVersion = areaMapVersions.find(
          (version) => environment?.area_map_version_id === version.version_id,
        );
        if (!project || !environment || !currentAreaMapVersion) {
          notifyError({
            message: t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }
        if (!currentAreaMapVersion.current_applied_no_entry_zone_setting_id) {
          return null;
        }
        const res = await request(
          project.id,
          environment.environment_id,
          currentAreaMapVersion.current_applied_no_entry_zone_setting_id,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.no_entry_zone_setting.get', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [request, t, getErrorMessage, notifyError],
    ),
  );

  return {
    state,
    getNoEntryZoneSetting,
    getAppliedNoEntryZoneSetting,
  };
};
