import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useConcatWord = (): {
  concat: (args: string[], isReverse?: boolean) => string;
} => {
  const { i18n } = useTranslation();
  const concat = useCallback(
    (args: string[], isReverse = false) => {
      const isEn = i18n.language === 'en-US';
      const cloned = args.concat();
      if (isReverse && isEn) cloned.reverse();
      return cloned.reduce((prev, current) => {
        if (isEn) {
          return `${prev} ${current}`;
        }
        return `${prev}${current}`;
      });
    },
    [i18n.language],
  );

  return { concat };
};
