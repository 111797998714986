import { atom } from 'jotai';
import type { MaintenanceEvent } from './types';

export const maintenanceEventsAtom = atom<MaintenanceEvent[]>([]);

// 表示用のメンテ情報
export const displayMaintenanceEventSelector = atom((get) => {
  const events = get(maintenanceEventsAtom);
  // インシデントレベルが informational && ステータスが canceled or completed
  // の場合は表示しない
  const event = events.find(
    ({ incident_level, status }) =>
      incident_level !== 'informational' &&
      status !== 'canceled' &&
      status !== 'completed',
  );
  if (!event) return null;
  return event;
});

// メンテ情報を表示しているときとしていないときの Header Height
export const headerHeightSelector = atom((get) => {
  const displayMaintenanceEvent = get(displayMaintenanceEventSelector);
  return displayMaintenanceEvent ? 102 : 64;
});
