import { isAuthErrorAtom } from '@data/auth';
import { useCallback } from 'react';
import type { Token } from '@tier4/webauto-auth-core';
import AuthenticationManager from './authenticationManager';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useSetAtom } from 'jotai';

export const usePostAuthorizationAPI = (): (() => Promise<Token>) => {
  const setIsAuthError = useSetAtom(isAuthErrorAtom);

  /**
   * 認可情報取得
   */
  const getToken = useCallback(async (): Promise<Token> => {
    const tokenSource = await AuthenticationManager.getTokenSource();
    const token = await tokenSource.getToken();
    if (isNullOrUndefined(token)) {
      setIsAuthError(true);
    }
    return token;
  }, [setIsAuthError]);

  return getToken;
};
