import { atom } from 'jotai';
import type { LaneletsCenterLinePoints, Lanes } from './types';
import { atomWithReset } from 'jotai/utils';

export const laneletsCenterLinePointsLoadingAtom = atom(false);

/**
 * APIから取得したレーン情報
 */
export const laneletsCenterLinePointsAtom =
  atomWithReset<LaneletsCenterLinePoints>({
    area_map_version_id: '',
    lanelets_center_line_points: [],
  });

/**
 * APIから取得した配列をレーンIDを key にした Lanes オブジェクトに変換して返す
 */
export const laneletsSelector = atom<Lanes>((get) => {
  const laneletsCenterLinePoints = get(laneletsCenterLinePointsAtom);
  const lanes: Lanes = {};
  laneletsCenterLinePoints.lanelets_center_line_points.forEach((data, i) => {
    const prevLaneId =
      laneletsCenterLinePoints.lanelets_center_line_points[i - 1]?.lanelet_id;
    const laneId = data.lanelet_id;
    if (prevLaneId !== laneId) {
      lanes[laneId] = [];
    }
    lanes[laneId].push({
      lat: data.location.lat,
      lng: data.location.lng,
    });
  });
  return lanes;
});
