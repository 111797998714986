import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 車両発車指示API
 */
export const useVehicleStartAPI = (): {
  state: APIState;
  postVehicleStart: (vehicleId: string) => Promise<boolean>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError, notifySuccess } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const postVehicleStartAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
    ): Promise<Result<boolean, AxiosResponse>> => {
      try {
        await fmsAPI.post(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/start`,
        );
        return new Success(true);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const postVehicleStart = useAtomCallback(
    useCallback(
      async (get, _, vehicleId: string) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (isNullOrUndefined(project) && isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.post_vehicle_start', { status: 'failed' }),
          });
          setState('hasError');
          return false;
        }
        setState('loading');
        const res = await postVehicleStartAPI(
          project!.id,
          environment!.environment_id,
          vehicleId,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.post_vehicle_start', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return false;
        }
        notifySuccess({
          message: t('api.fms.post_vehicle_start', { status: 'successfully' }),
        });
        setState('hasValue');
        return res.value;
      },
      [notifyError, notifySuccess, postVehicleStartAPI, t, getErrorMessage],
    ),
  );

  return {
    state,
    postVehicleStart,
  };
};
