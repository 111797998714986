import type { TaskStatus } from '../schedule/types';

/**
 * 地図上のルートの重なり順
 */
export const taskRouteSortOrder: TaskStatus[] = [
  'doing',
  'aborting',
  'todo',
  'done',
  'aborted',
  'disabled',
];
