import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation } from 'react-router-dom';
import { darken } from 'polished';
import { spacing } from '@mui/system';
import type { DrawerProps } from '@mui/material';
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRootPath } from '@data/fms/environment/hooks';
import { drawerWidth } from '@components/common/templates/Layout';
import { useFilteredRoutes } from '@routes/states';
import type { RouteInfoType } from '@routes/types';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(Box)`
  background-color: ${({ theme }) => theme.sidebar.background};
  height: 100%;
`;

const LinkList = styled(MuiList)`
  background-color: ${({ theme }) => theme.sidebar.background};
`;

const Brand = styled(ListItem)`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.sidebar.header.color};
  background-color: ${({ theme }) => theme.sidebar.header.background};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  min-height: 64px;
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(6)};

  &:hover {
    background-color: ${({ theme }) => theme.sidebar.header.background};
  }
`;

type CategoryType = {
  to?: string;
  exact?: boolean;
  isCurrent: boolean;
};

const Category = styled(ListItemButton, {
  shouldForwardProp: (propName) => propName !== 'isCurrent',
})<CategoryType>`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};

  svg {
    color: ${({ theme }) => theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  ${({ isCurrent, theme }) => {
    if (isCurrent) {
      return css`
        background-color: ${darken(0.05, theme.sidebar.background)};

        span {
          color: ${theme.sidebar.color};
        }

        &:hover {
          background: ${darken(0.05, theme.sidebar.background)};
        }
      `;
    }
    return '';
  }}
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${({ theme }) => theme.sidebar.color};
    font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
    font-weight: ${({ theme }) => theme.sidebar.category.fontWeight};
    padding: 0 ${({ theme }) => theme.spacing(4)};
  }
`;

const Logo = styled('img')`
  pointer-events: none;
  width: 24px;
  height: 24px;
`;

type SidebarLinkPropsType = {
  id: string;
  name: string;
  icon: JSX.Element;
  classes?: string;
  onClick?: () => void;
  to: string;
  exact?: boolean;
};

const idToTestid = (str: string): string => {
  const id = str.replace(/(_\w)/g, (match) => match[1].toUpperCase());
  return `menu-${id}-link`;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = React.memo(
  ({ id, name, icon, to, onClick }: SidebarLinkPropsType) => {
    const location = useLocation();

    const isCurrent = useMemo(() => {
      if (id === 'top') {
        return location.pathname === to;
      }
      return location.pathname.includes(to.replace(/(?=\?)(.*)/, ''));
    }, [id, to, location.pathname]);

    if (isCurrent) {
      return (
        <Category isCurrent onClick={onClick} data-testid={idToTestid(id)}>
          {icon}
          <CategoryText>{name}</CategoryText>
        </Category>
      );
    }

    return (
      <Link to={to} onClick={onClick} data-testid={idToTestid(id)}>
        <Category isCurrent={false}>
          {icon}
          <CategoryText>{name}</CategoryText>
        </Category>
      </Link>
    );
  },
);

SidebarLink.displayName = 'SidebarLink';

type SidebarProps = DrawerProps & {
  onSidebarLinkClick?: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  onSidebarLinkClick,
  ...rest
}: SidebarProps) => {
  const routes = useFilteredRoutes('sidebar');
  const { t } = useTranslation();
  const rootPath = useRootPath();

  return (
    <Drawer PaperProps={{ style: { width: drawerWidth } }} {...rest}>
      <Brand>
        <Logo src="/assets/img/logo.png" />
        <Box ml={3}>FMS Console</Box>
      </Brand>
      <Scrollbar>
        <LinkList disablePadding>
          <Box py={2.5}>
            {routes.map((link: RouteInfoType, i) => (
              <SidebarLink
                key={i}
                id={link.id}
                name={t(`page.${link.id}`)}
                to={`${rootPath}${link.sideBarPath ?? link.path}`}
                icon={link.icon!}
                onClick={onSidebarLinkClick}
                exact
              />
            ))}
          </Box>
        </LinkList>
      </Scrollbar>
    </Drawer>
  );
};

export default React.memo(Sidebar);
