import React, { useCallback } from 'react';
import { Fab, Icon, colors, styled, Box } from '@mui/material';
import type { CallState } from '@api/media';
import { useRemoteCall } from '@api/media';
import { css, keyframes } from '@emotion/react';

type Props = {
  vehicleName: string;
  vehicleId: string;
};

const Call = (props: Props): JSX.Element => {
  // 通話処理
  const { callState, setState, setIsCancel, isError } = useRemoteCall(
    props.vehicleId,
  );

  const receiveCall = useCallback(() => {
    setState('talking');
  }, [setState]);

  const hangUp = useCallback(() => {
    setIsCancel(true);
    setState('ready');
  }, [setIsCancel, setState]);

  return callState === 'ready' || callState === 'init' ? (
    <></>
  ) : (
    <PopupOuter>
      <Wrapper isError={isError} callState={callState}>
        <CallWrapper>
          <Information>
            {isError && <Label>Error</Label>}
            {!isError && callState === 'ringing' && <Label>Incoming</Label>}
            <VehicleName>{props.vehicleName}</VehicleName>
          </Information>
          <Nav>
            {callState === 'ringing' && !isError ? (
              <CallFab>
                <Icon onClick={receiveCall}>call</Icon>
              </CallFab>
            ) : (
              <></>
            )}
            {isError ? (
              <CloseFab>
                <Icon onClick={hangUp}>close</Icon>
              </CloseFab>
            ) : (
              <CallEndFab>
                <Icon onClick={hangUp}>call_end</Icon>
              </CallEndFab>
            )}
          </Nav>
        </CallWrapper>
      </Wrapper>
    </PopupOuter>
  );
};

export default React.memo(Call);

const PopupOuter = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(170, 170, 170, 0.2);
  z-index: 1500;
`;

type WrapparProps = {
  isError: boolean;
  callState: CallState;
};

const Wrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(15)};
  ${({ isError, callState }: WrapparProps) => {
    if (isError) {
      return css`
        ${CallWrapper} {
          background-color: ${colors.deepOrange[500]} !important;
        }
        ${Information} {
          color: white;
        }
        ${VehicleName} {
          color: white;
        }
      `;
    }
    if (callState === 'talking') {
      return css`
        ${CallWrapper} {
          background-color: ${colors.green[500]};
        }
        ${Information} {
          color: white;
        }
        ${VehicleName} {
          color: white;
        }
      `;
    }
  }}
`;

const CallWrapper = styled('div')`
  width: 400px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: white;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(6)};
  font-family: Roboto;
  font-weight: 700;
`;
const Information = styled('div')`
  font-size: 14px;
  color: ${colors.blueGrey[400]};
  line-height: 1;
  flex: 1;
  padding-right: ${({ theme }) => theme.spacing(3)};
  overflow: hidden;
`;
const Label = styled('p')`
  margin: 0 0 6px;
`;
const VehicleName = styled('p')`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 24px;
  color: ${colors.blueGrey[700]};
`;

const Nav = styled('div')`
  width: 104px;
  display: flex;
  justify-content: flex-end;
`;
const CustomFab = styled(Fab)`
  width: 42px;
  height: 42px;
  &.MuiFab-root {
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
  .MuiFab-label {
    color: white;
  }
  &:last-of-type {
    margin-left: ${({ theme }) => theme.spacing(5)};
  }
`;

const incomingAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`;

const CallFab = styled(CustomFab)`
  position: relative;
  &.MuiFab-root {
    background-color: ${colors.green[500]};
    &:hover {
      background-color: ${colors.green[700]};
    }
  }
  &::before,
  &::after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: solid 1px ${colors.green[500]};
  }
  &::before {
    animation: ${incomingAnimation} 1.6s linear infinite;
  }
  &::after {
    animation: ${incomingAnimation} 1.6s linear infinite;
    animation-delay: 0.4s;
  }
  &:hover {
    &::before,
    &::after {
      border-color: ${colors.green[700]};
    }
  }
`;

const CallEndFab = styled(CustomFab)`
  &.MuiFab-root {
    background-color: ${colors.red[500]};
    &:hover {
      background-color: ${colors.red[700]};
    }
  }
`;

const CloseFab = styled(CustomFab)`
  &.MuiFab-root {
    background-color: white;
    &:hover {
      background-color: ${colors.grey[100]};
    }
  }
  .MuiFab-label {
    color: ${colors.deepOrange[500]};
  }
`;
