import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, ja } from './locales';
import { useCallback, useMemo } from 'react';
import type { DocumentPath, DocumentProduct } from './docs/types';
import { PILOT_AUTO_DOCS_PATH } from './docs/constants';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources: {
        en: {
          translation: en,
        },
        ja: {
          translation: ja,
        },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      if (!['ja', 'en-US'].includes(i18next.language)) {
        i18next.changeLanguage('en-US');
      }
    },
  );

export const useDocumentLink = () => {
  const { i18n } = useTranslation();
  /**
   * @param path {string} 共通の場合は文字列を、言語で分けたい場合はオブジェクトを渡す
   * @param product {string} Web.Auto | Pilot.Auto
   */
  const createDocumentLink = useCallback(
    (path: DocumentPath, product: DocumentProduct = 'web') => {
      const root = `https://docs.${product}.auto${
        i18n.language === 'ja' ? '' : '/en'
      }`;
      if (!path) return `${root}/`;

      if (typeof path === 'string') {
        return `${root}${path}`;
      }
      return `${root}${path[i18n.language]}`;
    },
    [i18n.language],
  );

  const pilotAutoDocumentLinks = useMemo(() => {
    return {
      drivingState: createDocumentLink(
        PILOT_AUTO_DOCS_PATH.drivingState,
        'pilot',
      ),
    };
  }, [createDocumentLink]);

  return {
    createDocumentLink,
    pilotAutoDocumentLinks,
  };
};
