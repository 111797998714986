import React from 'react';
import type { DiagnosticValueHistory } from '@data/datahub/diagnostics/types';
import { TableCell, TableRow, Typography } from '@mui/material';
import { DateTime } from 'luxon';

type Props = {
  no: number;
  valueHistory: DiagnosticValueHistory;
};

const DiagnosticValueHistoryItem: React.FC<Props> = ({
  no,
  valueHistory,
}: Props) => (
  <TableRow>
    <TableCell>{no}</TableCell>
    <TableCell>
      {DateTime.fromISO(valueHistory.timestamp).toFormat(
        'yyyy/MM/dd HH:mm:ss.SSS',
      )}
    </TableCell>
    <TableCell>
      {valueHistory.values.map((value, i) => (
        <Typography key={`value_${i}`}>
          <strong>{value.key}</strong>: {value.value}
        </Typography>
      ))}
    </TableCell>
  </TableRow>
);

export default React.memo(DiagnosticValueHistoryItem);
