import type { ListItemProps } from '@mui/material';
import {
  ListItem,
  Avatar as MuiAvatar,
  Typography,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

type Props = ListItemProps & {
  value: string;
  dataAutomationId?: string;
};

const ProjectEnvironmentListItem: React.FC<Props> = ({
  value,
  dataAutomationId,
  ...rest
}: Props) => {
  return (
    // FIXME: ListItemButton に置き換える
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <ListItemWrapper button {...rest}>
      <Avatar>{value.charAt(0).toUpperCase()}</Avatar>
      <Typography
        data-testid={dataAutomationId}
        sx={{ wordBreak: 'break-all' }}
      >
        {value}
      </Typography>
    </ListItemWrapper>
  );
};

export default React.memo(ProjectEnvironmentListItem);

const ListItemWrapper = styled(ListItem)`
  border: solid 1px ${grey[300]};
  border-left: none;
  border-right: none;
  margin-bottom: 8px;
  padding: 16px;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:nth-last-of-type(n + 6) ~ &:last-of-type {
    border-bottom: none;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 26px;
  height: 26px;
  font-size: 16px;
  margin-right: 16px;
`;
