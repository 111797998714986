import { atom } from 'jotai';
import type { Environment, WebAutoApplicationEnvironmentOption } from './types';

export const environmentChangeLoadingAtom = atom(true);

export const environmentAtom = atom<Environment | null>(null);

export const environmentsAtom = atom<Environment[]>([]);

export const environmentOptionAtom =
  atom<WebAutoApplicationEnvironmentOption | null>(null);
