import type { Result } from '@api';
import { authAPI, Failure, Success, useErrorMessage } from '@api';
import type { Project } from '@data/auth/projects';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { usePostAuthorizationAPI } from '.';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@data/notification';

type APIResponse = {
  projects: Project[];
  next_page_token: string;
};

export const useProjectsAPI = (): {
  loading: boolean;
  getProjects: () => Promise<Project[]>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getToken = usePostAuthorizationAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      token: string,
      params?: URLSearchParams,
    ): Promise<Result<APIResponse, AxiosResponse>> => {
      try {
        const paramsToStr = params ? `?${params.toString()}` : '';
        const res = await authAPI.get(`/projects${paramsToStr}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return new Success(res.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [],
  );

  const getProjects = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    if (!token) {
      throw new axios.Cancel('token is null');
    }
    const req = async (params?: URLSearchParams) =>
      await request(token.accessToken, params);

    const splitRequest = async (
      data: Project[] = [],
      params?: URLSearchParams,
    ): Promise<Project[]> => {
      const res = await req(params);
      // 取得失敗時
      if (!res.value || res.isFailure()) {
        // リクエストに失敗した場合
        notifyError({
          message: `${t('api.auth.get_projects', {
            status: 'failed',
          })}: ${getErrorMessage(res)}`,
        });
        return data;
      }
      // 取得成功時
      // 配列を結合
      const combinedData = [...data, ...res.value.projects];
      if (res.value.next_page_token === '') {
        return combinedData;
      }
      // レスポンスに next_page_token がある場合は次のリクエストを行う
      const requestParams = new URLSearchParams();
      requestParams.set('page_token', res.value.next_page_token);
      return await splitRequest(combinedData, requestParams);
    };

    const data = await splitRequest();
    const filtered = data.filter((project) => project.is_active);
    const sorted = filtered.sort((a: Project, b: Project) => {
      if (a.display_name < b.display_name) return -1;
      return 1;
    });
    setLoading(false);
    return sorted;
  }, [notifyError, getToken, request, t, getErrorMessage]);

  return {
    loading,
    getProjects,
  };
};
