import type { ButtonProps } from '@mui/material';
import { Button, darken, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import type { VehicleTelemetryStatus } from '@data/fms/vehicle/types';

type Props = ButtonProps & {
  status: VehicleTelemetryStatus;
};

const VehicleStatusButton: React.FC<Props> = ({ status, ...args }: Props) => {
  const { t } = useTranslation();

  const label = useMemo(
    () => t(`vehicle.telemetry.status.${status}`),
    [status, t],
  );

  return (
    <StatusButton status={status} {...args}>
      {label}
    </StatusButton>
  );
};

export default React.memo(VehicleStatusButton);

const StatusButton = styled(Button)<Props>`
  color: white;
  ${({ status }: Props) => {
    if (status === 'ready') {
      return css`
        background-color: #4db6ac;

        &:hover {
          background-color: ${darken('#4db6ac', 0.1)};
        }
      `;
    }
  }}
`;
