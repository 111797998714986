import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import { InfoBox, useGoogleMap } from '@react-google-maps/api';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { useRootPath } from '@data/fms/environment/hooks';
import type { Vehicle } from '@data/fms/vehicle/types';
import { isOpenVehicleInfoWindow } from '@data/fms/vehicle/states';
import { useStoredVehicleId } from '@data/fms/vehicle/hooks';
import * as vehicleUtil from '@data/fms/vehicle/utils';
import { useSetAtom } from 'jotai';

type Props = {
  vehicle: Vehicle;
};

const VehicleInfoWindow: React.FC<Props> = ({ vehicle }: Props) => {
  const setIsOpenVehicleInfoWindow = useSetAtom(isOpenVehicleInfoWindow);
  const { setStoredVehicleId } = useStoredVehicleId();
  const map = useGoogleMap();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const rootPath = useRootPath();

  useMount(() => {
    if (!map) return;
    map.setCenter(vehicle.telemetry.location);
  });

  const handleClickClose = useCallback(() => {
    setStoredVehicleId(undefined);
    setIsOpenVehicleInfoWindow(false);
  }, [setIsOpenVehicleInfoWindow, setStoredVehicleId]);

  const handleClickDetailButton = useCallback(() => {
    navigate(`${rootPath}vehicle/${vehicle.vehicle_id}/`);
  }, [vehicle.vehicle_id, navigate, rootPath]);

  /**
   * バッテリー
   */
  const battery = useMemo(() => {
    const label = vehicleUtil.batteryLabel(vehicle.telemetry.battery);
    if (isNullOrUndefined(label)) {
      return t('vehicle.telemetry.battery_not_available');
    }
    return `${label}%`;
  }, [t, vehicle.telemetry.battery]);

  return (
    <InfoBox
      position={vehicle.telemetry.location as unknown as google.maps.LatLng}
      options={{
        zIndex: 50,
        disableAutoPan: true,
        alignBottom: true,
        closeBoxURL: '',
        pixelOffset: new window.google.maps.Size(-100, -6),
      }}
    >
      <Box pb={4}>
        <Wrapper p={4}>
          <Typography variant="h6" align="center" gutterBottom>
            {t('top.vehicle_info')}
          </Typography>
          <Typography gutterBottom sx={{ wordBreak: 'break-all' }}>
            {t('vehicle.vehicle_name')}: {vehicle.vehicle_name}
          </Typography>
          <Typography gutterBottom>
            {t('common.status.status')}:{' '}
            {t(`vehicle.telemetry.status.${vehicle.telemetry.status}`)}
          </Typography>
          <Typography paragraph>
            {t('vehicle.telemetry.battery')}: {battery}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{
              margin: '0 auto 0',
              display: 'block',
            }}
            onClick={handleClickDetailButton}
            data-testid={`info-detailsBtn-${vehicle.vehicle_id}`}
          >
            {t('common.general.detail')}
          </Button>
          <IconButton
            size="small"
            onClick={handleClickClose}
            sx={{
              transform: 'scale(0.6)',
              transformOrigin: 'top right',
              position: 'absolute',
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
        </Wrapper>
      </Box>
    </InfoBox>
  );
};

export default React.memo(VehicleInfoWindow);

const Wrapper = styled(Box)`
  width: 200px;
  background-color: rgba(255, 255, 255, 0.58);
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  pointer-events: none;
  backdrop-filter: blur(5px);

  button {
    pointer-events: all;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border: 7px solid transparent;
    border-top: 7px solid rgba(255, 255, 255, 0.58);
  }
`;
