import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import DialogProgress from '../DialogProgress';
import type {
  SelectedTempState,
  CreateEnvironmentValues,
} from '@components/common/organisms';
import { useAreaMapsAPI, useAreaMapVersionsAPI } from '@api/fms';
import { useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import type { AreaMap, AreaMapVersion } from '@data/fms/areamap/types';

type Props = {
  tempSelect: SelectedTempState;
  isConfirm: boolean;
  createEnvironmentValues: CreateEnvironmentValues;
  onUpdateEnvironmentValues: (value: CreateEnvironmentValues) => void;
  onReturn: () => void;
  onOk: () => void;
};

const ProjectEnvironmentDialogCreateEnvironment: React.FC<Props> = ({
  tempSelect,
  isConfirm,
  createEnvironmentValues,
  onUpdateEnvironmentValues,
  onReturn,
  onOk,
}: Props) => {
  const [areaMaps, setAreaMaps] = useState<AreaMap[]>([]);
  const [areaMapVersions, setAreaMapVersions] = useState<AreaMapVersion[]>([]);
  const { getAreaMaps } = useAreaMapsAPI();
  const { getAreaMapVersions } = useAreaMapVersionsAPI();
  const [isInitialName, setIsInitialName] = useState(true);
  const { t } = useTranslation();
  const { concat } = useConcatWord();

  const title = useMemo(
    () =>
      `${concat(
        [t('page.driving_environment'), t('common.action.create')],
        true,
      )} - ${t(`common.action.${isConfirm ? 'confirm' : 'input'}`)}`,
    [t, concat, isConfirm],
  );

  /**
   * 走行環境名エラーメッセージ
   */
  const nameErrorMessage = useMemo(() => {
    if (isInitialName) return null;
    if (createEnvironmentValues.environmentName.length === 0)
      return t('dialog.driving_environment.create.attention.empty_name');
    if (createEnvironmentValues.environmentName.trim().length === 0)
      return t('dialog.driving_environment.create.attention.only_space');
    if (createEnvironmentValues.environmentName.length > 128)
      return t('dialog.driving_environment.create.attention.long_name');
    return null;
  }, [createEnvironmentValues.environmentName, isInitialName, t]);
  /**
   * 走行環境名説明エラーメッセージ
   */
  const descriptionErrorMessage = useMemo(() => {
    if (createEnvironmentValues.environmentDescription.length > 128)
      return t('dialog.driving_environment.create.attention.long_description');
    return null;
  }, [createEnvironmentValues.environmentDescription.length, t]);

  /**
   * 走行環境名入力時
   */
  const handleChangeEnvironmentName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      onUpdateEnvironmentValues({
        ...createEnvironmentValues,
        environmentName: inputValue,
      });
      if (isInitialName) setIsInitialName(false);
    },
    [createEnvironmentValues, onUpdateEnvironmentValues, isInitialName],
  );

  /**
   * 走行環境説明入力時
   */
  const handleChangeEnvironmentDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      onUpdateEnvironmentValues({
        ...createEnvironmentValues,
        environmentDescription: inputValue,
      });
    },
    [createEnvironmentValues, onUpdateEnvironmentValues],
  );

  /**
   * エリアマップ変更時
   */
  const handleChangeAreaMap = useCallback(
    (e: SelectChangeEvent<string | number>) => {
      onUpdateEnvironmentValues({
        ...createEnvironmentValues,
        areaMapId: Number(e.target.value),
      });
    },
    [createEnvironmentValues, onUpdateEnvironmentValues],
  );

  /**
   * エリアマップバージョン変更時
   */
  const handleChangeAreaMapVersion = useCallback(
    (e: SelectChangeEvent<string>) => {
      onUpdateEnvironmentValues({
        ...createEnvironmentValues,
        areaMapVersionId: e.target.value,
      });
    },
    [createEnvironmentValues, onUpdateEnvironmentValues],
  );

  /**
   * 選択されているエリアマップバージョンを返す
   */
  const selectedAreaMapVersion = useMemo(() => {
    if (isNullOrUndefined(createEnvironmentValues.areaMapVersionId))
      return null;
    return areaMapVersions.find(
      (areaMapVersion) =>
        createEnvironmentValues.areaMapVersionId === areaMapVersion.version_id,
    );
  }, [createEnvironmentValues.areaMapVersionId, areaMapVersions]);

  /**
   * アーカイブされていないエリアマップバージョンを返す
   */
  const availableList = useMemo(
    () =>
      areaMapVersions.filter((areaMapVersion) => {
        return (
          areaMapVersion.release_status === 'released' &&
          areaMapVersion.environment_data_status === 'created'
        );
      }),
    [areaMapVersions],
  );

  /**
   * 走行環境登録可能かどうか
   */
  const environmentRegisterable = useMemo(() => {
    const {
      environmentName,
      environmentDescription,
      areaMapId,
      areaMapVersionId,
    } = createEnvironmentValues;
    return (
      environmentName.trim().length > 0 &&
      environmentName.length <= 128 &&
      environmentDescription.length <= 128 &&
      !isNullOrUndefined(areaMapId) &&
      areaMapVersionId.length > 0
    );
  }, [createEnvironmentValues]);

  /**
   * プロジェクトのエリアマップ一覧取得
   */
  useEffect(() => {
    const updateAreaMaps = async () => {
      const res = await getAreaMaps(tempSelect.project?.id);
      setAreaMaps(res);
    };
    updateAreaMaps();
  }, [tempSelect, getAreaMaps]);

  /**
   * エリアマップが選択された際にエリアマップバージョン一覧取得
   */
  useEffect(() => {
    const updateAreaMapVersions = async () => {
      if (
        isNullOrUndefined(createEnvironmentValues.areaMapId) ||
        isNullOrUndefined(tempSelect.project)
      )
        return;
      const res = await getAreaMapVersions(
        tempSelect.project!.id,
        createEnvironmentValues.areaMapId,
        'deleted',
      );
      setAreaMapVersions(res);
    };
    updateAreaMapVersions();
  }, [
    createEnvironmentValues.areaMapId,
    getAreaMapVersions,
    tempSelect.project,
  ]);

  return (
    <>
      <DialogTitle data-testid="createEnvTitle">{title}</DialogTitle>
      <DialogContent>
        <ContentBox>
          <Box my={4}>
            <RequiredTextField
              required
              label={t('dialog.driving_environment.create.label.name')}
              InputProps={{ readOnly: isConfirm }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              error={!isNullOrUndefined(nameErrorMessage)}
              value={createEnvironmentValues.environmentName}
              onChange={handleChangeEnvironmentName}
              helperText={nameErrorMessage}
              data-testid="createEnvName"
            />
          </Box>
          <Box my={4}>
            <TextField
              label={t('dialog.driving_environment.create.label.description')}
              InputProps={{ readOnly: isConfirm }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              error={!isNullOrUndefined(descriptionErrorMessage)}
              value={createEnvironmentValues.environmentDescription}
              onChange={handleChangeEnvironmentDescription}
              helperText={descriptionErrorMessage}
              multiline
              minRows={3}
              maxRows={3}
              data-testid="createEnvDescription"
            />
          </Box>
          <Box mb={4} data-testid="createEnvAreaMap">
            {isConfirm ? (
              <RequiredTextField
                required
                label={concat(
                  [t('common.fms.area_map'), t('common.action.select')],
                  true,
                )}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={
                  areaMaps.find(
                    (areaMap) =>
                      areaMap.area_map_id === createEnvironmentValues.areaMapId,
                  )?.area_map_name
                }
              />
            ) : (
              <>
                <RequiredLabel shrink required>
                  {concat(
                    [t('common.fms.area_map'), t('common.action.select')],
                    true,
                  )}
                </RequiredLabel>
                <Select
                  value={
                    isNullOrUndefined(createEnvironmentValues.areaMapId)
                      ? ''
                      : createEnvironmentValues.areaMapId
                  }
                  inputProps={{ readOnly: isConfirm }}
                  onChange={handleChangeAreaMap}
                  fullWidth
                  data-testid="selectAreaMap"
                >
                  {areaMaps.map((areaMap: AreaMap) => (
                    <MenuItem
                      value={areaMap.area_map_id}
                      key={areaMap.area_map_id}
                    >
                      {areaMap.area_map_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
          <Box mb={4} data-testid="createEnvAreaMapVersion">
            <RequiredLabel shrink required>
              {concat(
                [t('common.fms.area_map_version'), t('common.action.select')],
                true,
              )}
            </RequiredLabel>
            {!isConfirm && (
              <Select
                value={
                  isNullOrUndefined(createEnvironmentValues.areaMapVersionId)
                    ? ''
                    : createEnvironmentValues.areaMapVersionId
                }
                inputProps={{ readOnly: isConfirm }}
                disabled={isNullOrUndefined(createEnvironmentValues.areaMapId)}
                onChange={handleChangeAreaMapVersion}
                fullWidth
                data-testid="selectAreaMapVersion"
              >
                {availableList
                  .filter(
                    (availableList: AreaMapVersion) =>
                      availableList.release_status === 'released' &&
                      availableList.has_map_config === true,
                  )
                  .map((availableList: AreaMapVersion) => (
                    <MenuItem
                      value={availableList.version_id}
                      key={availableList.version_id}
                    >
                      {DateTime.fromISO(
                        availableList.release_status_updated_at,
                      ).toFormat('yyyy/MM/dd HH:mm:ss')}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Box>
          {!isNullOrUndefined(selectedAreaMapVersion) && (
            <Box>
              <CurrentGrid container data-testid="areaMapUpdatedAt">
                <Typography>{t('common.date.updated_at')}</Typography>
                <Typography>
                  {DateTime.fromISO(
                    selectedAreaMapVersion.release_status_updated_at,
                  ).toFormat('yyyy/MM/dd HH:mm:ss')}
                </Typography>
              </CurrentGrid>
              <CurrentGrid container data-testid="areaMapDescription">
                <Typography>
                  {concat([
                    t('common.fms.area_map_version'),
                    t('common.general.description'),
                  ])}
                </Typography>
                <Typography>{selectedAreaMapVersion.description}</Typography>
              </CurrentGrid>
              <CurrentGrid container data-testid="areaMapVersionId">
                <Typography>
                  {concat([
                    t('common.fms.area_map_version'),
                    t('common.general.id'),
                  ])}
                </Typography>
                <Typography>{selectedAreaMapVersion.version_id}</Typography>
              </CurrentGrid>
              <CurrentGrid container data-testid="pcdMapDescription">
                <Typography>
                  {concat([
                    t('common.fms.pcd_map'),
                    t('common.general.description'),
                  ])}
                </Typography>
                <Typography>
                  {selectedAreaMapVersion.pcd_map_description}
                </Typography>
              </CurrentGrid>
              <CurrentGrid container data-testid="vectorMapDescription">
                <Typography>
                  {concat([
                    t('common.fms.vector_map'),
                    t('common.general.description'),
                  ])}
                </Typography>
                <Typography>
                  {selectedAreaMapVersion.vector_map_description}
                </Typography>
              </CurrentGrid>
            </Box>
          )}
        </ContentBox>
        <DialogProgress open={createEnvironmentValues.progress} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onReturn} color="primary">
          {t('common.action.back')}
        </Button>
        <Button
          disabled={!environmentRegisterable}
          onClick={onOk}
          color="primary"
        >
          {t(`common.action.${isConfirm ? 'ok' : 'confirm'}`)}
        </Button>
      </DialogActions>
    </>
  );
};

export default React.memo(ProjectEnvironmentDialogCreateEnvironment);

const ContentBox = styled(Box)`
  min-width: 800px;
`;

const RequiredTextField = styled(TextField)`
  .MuiInputLabel-asterisk {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

const RequiredLabel = styled(InputLabel)`
  .MuiInputLabel-asterisk {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

const BaseGrid = styled(Grid)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const CurrentGrid = styled(BaseGrid)`
  margin-bottom: ${(props) => props.theme.spacing(3)};

  & > *:first-of-type {
    font-weight: bold;
    margin-right: ${(props) => props.theme.spacing(6)};
  }
`;
