import { StoppableMarker } from '@components/common/atoms';
import { usePlaces } from '@data/fms/place/hooks';
import type { Place } from '@data/fms/place/types';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useMemo } from 'react';

type Props = {
  places?: Place[];
};

const StoppableMarkers: React.FC<Props> = ({ places }: Props) => {
  const { places: defaultPlaces } = usePlaces();

  const displayPlaces = useMemo(
    () => (isNullOrUndefined(places) ? defaultPlaces : places),
    [defaultPlaces, places],
  );

  if (isNullOrUndefined(displayPlaces) || displayPlaces.length === 0)
    return null;

  return (
    <>
      {displayPlaces.map((place: Place) => (
        <StoppableMarker key={place.point_id} data={place} />
      ))}
    </>
  );
};

export default React.memo(StoppableMarkers);
