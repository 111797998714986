import {
  Box,
  Chip,
  Icon,
  IconButton,
  Typography,
  styled,
  colors,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableCell as MuiTableCell,
  TableBody,
  InputAdornment,
  Stack,
  OutlinedInput,
} from '@mui/material';
import { DateTime, Interval } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { levelLabel } from '@utils/errorLevel';
import { SCOPES, useHasScope } from '@data/auth';
import { useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import type { HistoryData } from '@components/pages/DrivingHistory/types';
import { Minus } from 'react-feather';
import type { BagFile } from '@data/datahub/log/types';
import { convertBytesToMb } from '@utils/file';
import { SearchRounded } from '@mui/icons-material';
import type { Diagnostic } from '@data/datahub/diagnostics/types';
import type { ManualOverrideTimestamp } from '@data/datahub/manualOverride/types';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const dateText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('yyyy/MM/dd');
  }, [date]);

  const timeText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('HH:mm:ss');
  }, [date]);

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {dateText}
      </Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type ErrorLabelCellProps = {
  diagnostics: Diagnostic[];
  recordStartLuxon: DateTime;
  recordEndLuxon: DateTime;
};

const ErrorLabelCell: React.FC<ErrorLabelCellProps> = ({
  diagnostics,
  recordStartLuxon,
  recordEndLuxon,
}) => {
  const errorLabels = useMemo(() => {
    const includeErrorLevels = diagnostics
      .map((diagnostic) => {
        // Diagnositc の発生期間
        const diagnosticStartTimeLuxon = DateTime.fromISO(
          diagnostic.start_timestamp,
        );
        const diagnosticEndTimeLuxon = DateTime.fromISO(
          diagnostic.end_timestamp,
        );
        // Bag の記録時間に Diagnostic の発生期間が含まれるかどうか
        const includeStartTime = Interval.fromDateTimes(
          recordStartLuxon,
          recordEndLuxon,
        ).contains(diagnosticStartTimeLuxon);
        const includeEndTime = Interval.fromDateTimes(
          recordStartLuxon,
          recordEndLuxon,
        ).contains(diagnosticEndTimeLuxon);
        return (includeStartTime || includeEndTime) && diagnostic.level;
      })
      .filter((errorLevel) => typeof errorLevel === 'number');
    // 重複を削除
    return [...new Set(includeErrorLevels)];
  }, [diagnostics, recordStartLuxon, recordEndLuxon]);

  return (
    <TableCell>
      {errorLabels.map((level, i) => {
        return (
          level && (
            <ErrorChip
              key={`error_${i}`}
              size="small"
              label={levelLabel(level)}
            />
          )
        );
      })}
    </TableCell>
  );
};

type ECUDomainCellProps = {
  rosDomainName?: string;
};

const ECUDomainCell: React.FC<ECUDomainCellProps> = ({ rosDomainName }) => (
  <TableCell>{rosDomainName}</TableCell>
);

type RecordPeriodCellProps = {
  bagFile: BagFile;
};

const RecordPeriodCell: React.FC<RecordPeriodCellProps> = ({ bagFile }) => (
  <TableCell>
    <Stack direction="row" alignItems="center">
      <Date date={bagFile.record_start} />
      <Box mx={1} mt={1.5}>
        <Minus size={14} color={colors.grey[500]} />
      </Box>
      <Date date={bagFile.record_end} />
    </Stack>
  </TableCell>
);

type DownloadCellProps = {
  bagFile: BagFile;
  manualOverrideTimestamps: ManualOverrideTimestamp[];
  recordStartLuxon: DateTime;
  recordEndLuxon: DateTime;
};

const DownloadCell: React.FC<DownloadCellProps> = ({
  bagFile,
  manualOverrideTimestamps,
  recordStartLuxon,
  recordEndLuxon,
}) => {
  const { t } = useTranslation();

  const isIncludeOverride = useMemo(() => {
    return manualOverrideTimestamps.some((timestamp) => {
      const overrideLuxon = DateTime.fromISO(timestamp.start_timestamp);
      return Interval.fromDateTimes(recordStartLuxon, recordEndLuxon).contains(
        overrideLuxon,
      );
    });
  }, [manualOverrideTimestamps, recordStartLuxon, recordEndLuxon]);

  return (
    <TableCell>
      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          color="primary"
          href={bagFile.download_url}
          target="_blank"
        >
          <Icon>get_app</Icon>
        </IconButton>
        <Typography>{convertBytesToMb(bagFile.file_size)} MB</Typography>
        {isIncludeOverride && (
          <Typography variant="caption" color="primary">
            {t('vehicle.override')}
          </Typography>
        )}
      </Stack>
    </TableCell>
  );
};

type BagTableRowProps = {
  no: number;
  bagFile: BagFile;
  data: HistoryData;
  hasDescribeVehicleErrorScope: boolean;
};

const BagTableRow: React.FC<BagTableRowProps> = React.memo(
  ({ no, bagFile, data, hasDescribeVehicleErrorScope }: BagTableRowProps) => {
    const recordStartLuxon = useMemo(
      () => DateTime.fromISO(bagFile.record_start),
      [bagFile.record_start],
    );
    const recordEndLuxon = useMemo(
      () => DateTime.fromISO(bagFile.record_end),
      [bagFile.record_end],
    );

    return (
      <TableRow>
        <TableCell>{no}</TableCell>
        {hasDescribeVehicleErrorScope && (
          <ErrorLabelCell
            diagnostics={data.diagnostics}
            recordStartLuxon={recordStartLuxon}
            recordEndLuxon={recordEndLuxon}
          />
        )}
        <ECUDomainCell rosDomainName={bagFile.ros_domain_name} />
        <RecordPeriodCell bagFile={bagFile} />
        <DownloadCell
          bagFile={bagFile}
          manualOverrideTimestamps={data.manualOverrideTimestamps}
          recordStartLuxon={recordStartLuxon}
          recordEndLuxon={recordEndLuxon}
        />
      </TableRow>
    );
  },
);

BagTableRow.displayName = 'BagTableRow';

type Props = {
  data: HistoryData;
  bagFiles: BagFile[];
  height: number;
};

const BagList: React.FC<Props> = ({ data, bagFiles, height }: Props) => {
  const getHasScope = useHasScope();
  const { t } = useTranslation();
  const { concat } = useConcatWord();
  const [searchWord, setSearchWord] = useState('');

  const columns = useMemo(
    () => [
      // 562
      {
        label: 'No',
        id: 'no',
        style: {
          width: 36,
        },
      },
      {
        label: t('vehicle.error'),
        id: 'level',
        style: {
          width: 120,
        },
      },
      {
        label: t('common.general.domain'),
        id: 'domain',
        style: {
          width: 100,
        },
      },
      {
        label: concat([t('common.action.record'), t('common.date.time')]),
        id: 'record_time',
        style: {
          width: 180,
        },
      },
      {
        label: t('common.action.download'),
        id: 'download',
        style: {
          width: 122,
        },
      },
    ],
    [t, concat],
  );

  const hasDescribeVehicleErrorScope = useMemo(
    () => getHasScope(SCOPES.DescribeVehicleError),
    [getHasScope],
  );

  const filteredColumns = useMemo(
    () =>
      hasDescribeVehicleErrorScope
        ? columns
        : columns.filter((column) => column.id !== 'level'),
    [hasDescribeVehicleErrorScope, columns],
  );

  const searchedBagFiles = useMemo(() => {
    return bagFiles
      .filter(({ ros_domain_name }) => ros_domain_name?.includes(searchWord))
      .reverse();
  }, [bagFiles, searchWord]);

  const handleChangeECUInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
    },
    [],
  );

  return (
    <>
      <Box my={2} textAlign="right">
        <OutlinedInput
          size="small"
          placeholder={t('common.general.domain')}
          value={searchWord}
          onChange={handleChangeECUInput}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded fontSize="small" color="disabled" />
            </InputAdornment>
          }
          sx={{
            backgroundColor: 'white',
          }}
        />
      </Box>
      <TableContainer sx={{ width: '100%', height: height }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {filteredColumns.map((column) => (
                <TableCell key={column.id} sx={{ ...column.style }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedBagFiles.map((bagFile, i) => (
              <BagTableRow
                key={`bag${i}`}
                no={i + 1}
                bagFile={bagFile}
                data={data}
                data-testid={`bag${i}`}
                hasDescribeVehicleErrorScope={hasDescribeVehicleErrorScope}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(BagList);

const ErrorChip = styled(Chip)`
  color: white;
  font-weight: bold;
  background-color: ${({ theme }) => theme.palette.error.main};
  height: 20px;
  border-radius: 10px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px !important;
`;
