import {
  useVehicleDoorCloseAPI,
  useVehicleDoorOpenAPI,
  useVehicleEmergencyStopAPI,
  useVehicleStartAPI,
} from '@api/fms';
import { FeatureToggle, ToggleOn } from '@components/common/atoms';
import { DialogProgress } from '@components/common/molecules';
import { SCOPES } from '@data/auth';
import type { TypographyProps } from '@mui/material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
  styled,
  Tooltip,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import type { RemoteSelectedView } from '../RemoteMonitor/RemoteMonitor';
import type { Vehicle } from '@data/fms/vehicle/types';
import { fixedSpeed } from '@data/fms/vehicle/utils';

type DialogSetting = {
  open: boolean;
  progress: boolean;
  type: DialogSettingType | null;
};

type DialogSettingType =
  | 'door_open'
  | 'door_close'
  | 'emergency_stop'
  | 'departure';

type Props = {
  view: RemoteSelectedView;
  vehicle: Vehicle;
  displayViz: boolean;
  isActiveViz: boolean;
  isAutowareLaunched: boolean;
  onChangeView: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDisplayViz: (e: React.SyntheticEvent<Element, Event>) => void;
};

const RemoteOperation: React.FC<Props> = ({
  view,
  vehicle,
  displayViz,
  isActiveViz,
  isAutowareLaunched,
  onChangeView,
  onChangeDisplayViz,
}: Props) => {
  const { t } = useTranslation();
  const [dialogSetting, setDialogSetting] = useState<DialogSetting>({
    open: false,
    progress: false,
    type: null,
  });
  const { postVehicleDoorOpen } = useVehicleDoorOpenAPI();
  const { postVehicleDoorClose } = useVehicleDoorCloseAPI();
  const { postVehicleStart } = useVehicleStartAPI();
  const { postVehicleEmergencyStop } = useVehicleEmergencyStopAPI();
  const windowSize = useWindowSize();
  const theme = useTheme();

  const switchDisabled = useMemo(
    () => windowSize.width >= theme.breakpoints.values.fhd,
    [windowSize.width, theme.breakpoints.values.fhd],
  );

  const dialogTitle = useMemo(() => {
    if (
      dialogSetting.type === 'door_open' ||
      dialogSetting.type === 'door_close'
    ) {
      return t('vehicle.door');
    }
    return t(`vehicle.${dialogSetting.type}`);
  }, [dialogSetting.type, t]);

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { operation } = e.currentTarget.dataset;
      if (isNullOrUndefined(operation)) return;
      setDialogSetting({
        open: true,
        progress: false,
        type: operation as DialogSettingType,
      });
    },
    [],
  );

  const handleCloseDialog = useCallback(() => {
    if (dialogSetting.progress) return;
    setDialogSetting((prevState) => ({
      ...prevState,
      open: false,
      progress: false,
    }));
  }, [dialogSetting.progress]);

  const handleClickDialogOk = useCallback(async () => {
    if (isNullOrUndefined(vehicle)) return;

    setDialogSetting((prevState) => ({
      ...prevState,
      progress: true,
    }));

    if (dialogSetting.type === 'door_open') {
      await postVehicleDoorOpen(vehicle.vehicle_id);
    }
    if (dialogSetting.type === 'door_close') {
      await postVehicleDoorClose(vehicle.vehicle_id);
    }
    if (dialogSetting.type === 'departure') {
      await postVehicleStart(vehicle.vehicle_id);
    }
    if (dialogSetting.type === 'emergency_stop') {
      await postVehicleEmergencyStop(vehicle.vehicle_id);
    }

    setDialogSetting((prevState) => ({
      ...prevState,
      open: false,
      progress: false,
    }));
  }, [
    dialogSetting.type,
    vehicle,
    postVehicleDoorOpen,
    postVehicleDoorClose,
    postVehicleStart,
    postVehicleEmergencyStop,
  ]);

  return (
    <>
      <Wrapper>
        <WrapperInner>
          <Box sx={{ color: 'white' }}>
            <Box mb={2}>
              <VehicleInfoLabel>{t('vehicle.name')}</VehicleInfoLabel>
              <VehicleInfoText
                sx={{ wordBreak: 'break-all' }}
                data-testid="vehicle_name"
              >
                {vehicle.vehicle_name}
              </VehicleInfoText>
            </Box>
            <Box mb={2}>
              <VehicleInfoLabel>{t('common.status.status')}</VehicleInfoLabel>
              <VehicleInfoText data-testid="vehicle_status">
                {t(`vehicle.telemetry.status.${vehicle.telemetry.status}`)}
              </VehicleInfoText>
            </Box>
            <Box mb={2}>
              <VehicleInfoLabel>
                {t('vehicle.telemetry.speed')}
              </VehicleInfoLabel>
              <VehicleInfoText data-testid="speed">
                {`${fixedSpeed(vehicle.telemetry.speed)} km/h`}
              </VehicleInfoText>
            </Box>
          </Box>
          <Box mt={2} mb={4}>
            <Divider sx={{ backgroundColor: 'white' }} />
          </Box>
          <Box>
            <Label sx={{ mb: 0 }}>{t('remote.operation.view.label')}</Label>
            <RadioGroup value={view} onChange={onChangeView}>
              <SwitchControlLabel
                value="media"
                control={<SwitchRadio disabled={switchDisabled} />}
                label={t('remote.operation.view.camera_and_call')}
                disabled={switchDisabled}
                sx={{
                  color: 'white',
                }}
                data-testid="radio_camera_and_call"
              />
              <SwitchControlLabel
                value="viz"
                control={
                  <SwitchRadio
                    disabled={switchDisabled || !isAutowareLaunched}
                  />
                }
                disabled={switchDisabled || !isAutowareLaunched}
                label={
                  isActiveViz
                    ? t('remote.operation.view.map_and_viz')
                    : t('common.general.map')
                }
                sx={{
                  color: 'white',
                }}
                data-testid="radio_map"
              />
            </RadioGroup>
            {isActiveViz && (
              <Box ml={8}>
                <SwitchControlLabel
                  disabled={!isAutowareLaunched}
                  checked={displayViz}
                  control={
                    <Checkbox
                      size="small"
                      sx={{
                        color: 'white',
                        '&.Mui-checked': { color: 'white' },
                      }}
                    />
                  }
                  label={t('remote.operation.view.display_viz')}
                  onChange={onChangeDisplayViz}
                />
              </Box>
            )}
          </Box>
          <Box mt={2} mb={4}>
            <Divider sx={{ backgroundColor: 'white' }} />
          </Box>
          <FeatureToggle scope={SCOPES.OperateVehicleDoor}>
            <ToggleOn>
              <Box>
                <Label>{t('vehicle.door')}</Label>
                <OperationButton
                  data-operation="door_open"
                  onClick={handleClickButton}
                  data-testid="door-open"
                >
                  <Box className="label">{t('common.action.open')}</Box>
                  <Grid height={18} container justifyContent="center">
                    <Grid item>
                      <KeyboardArrowLeft />
                    </Grid>
                    <Grid item>
                      <KeyboardArrowRight />
                    </Grid>
                  </Grid>
                </OperationButton>
                <OperationButton
                  data-operation="door_close"
                  onClick={handleClickButton}
                  data-testid="door-close"
                >
                  <Box className="label">{t('common.action.close')}</Box>
                  <Grid height={18} container justifyContent="center">
                    <Grid item>
                      <KeyboardArrowRight />
                    </Grid>
                    <Grid item>
                      <KeyboardArrowLeft />
                    </Grid>
                  </Grid>
                </OperationButton>
              </Box>
            </ToggleOn>
            <Box my={4}>
              <Divider sx={{ backgroundColor: 'white' }} />
            </Box>
          </FeatureToggle>
          <Box>
            <Label>{t('vehicle.vehicle')}</Label>
            {vehicle.firmware_type === 'Autoware.T4-beta' && (
              <FeatureToggle scope={SCOPES.EmergencyStopVehicle}>
                <ToggleOn>
                  <OperationButton
                    disabled={vehicle.telemetry.status !== 'driving'}
                    data-operation="emergency_stop"
                    onClick={handleClickButton}
                    data-testid="emergency-stop"
                  >
                    {t('vehicle.emergency_stop')}
                  </OperationButton>
                </ToggleOn>
              </FeatureToggle>
            )}
            <FeatureToggle scope={SCOPES.StartVehicle}>
              <ToggleOn>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    vehicle.can_start ? '' : t('vehicle.can_not_start.message')
                  }
                >
                  <span>
                    <OperationButton
                      data-testid="vehicleDetails-depart-btn"
                      disabled={
                        vehicle.telemetry.status !== 'ready' ||
                        !vehicle.can_start
                      }
                      data-operation="departure"
                      onClick={handleClickButton}
                    >
                      {t('vehicle.departure')}
                    </OperationButton>
                  </span>
                </Tooltip>
              </ToggleOn>
            </FeatureToggle>
          </Box>
        </WrapperInner>
      </Wrapper>
      <Dialog open={dialogSetting.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(`dialog.vehicle.${dialogSetting.type}.message`)}
          </DialogContentText>
          <DialogProgress open={dialogSetting.progress} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>
            {t('common.action.cancel')}
          </Button>
          <Button color="primary" onClick={handleClickDialogOk}>
            {t('common.action.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(RemoteOperation);

const Wrapper = styled(Box)`
  background-color: #808080;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(4)};
  overflow: auto;
`;

const WrapperInner = styled(Box)`
  height: 100%;
  min-height: 622px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
`;

const OperationButton = styled(Button)`
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.palette.text.primary};
  display: block;

  &:hover {
    background-color: #c4c4c4;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &[data-operation='departure'] {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &[data-operation='emergency_stop'] {
    color: ${({ theme }) => theme.palette.error.main};
  }

  &:disabled {
    background-color: #9c9c9c;
    color: #686868;
  }

  .label {
    line-height: 10px;
    font-size: 10px;
  }

  .MuiGrid-item {
    &:first-of-type {
      margin-right: -10px;
    }
  }
`;

const SwitchControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: white;
    font-size: 13px;
  }

  .Mui-disabled {
    opacity: 0.5;
  }
`;

const SwitchRadio = styled(Radio)`
  &.MuiRadio-root {
    color: white;
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.info.light};
  }
`;

const Label = styled(Typography)`
  color: white;
`;

Label.defaultProps = {
  variant: 'caption',
  component: 'p',
  align: 'center',
  paragraph: true,
} as TypographyProps;

const VehicleInfoLabel = styled(InputLabel)`
  color: white;
  margin-bottom: 2px;
`;

VehicleInfoLabel.defaultProps = {
  shrink: true,
};

const VehicleInfoText = styled(Typography)`
  color: white;
  font-weight: 600;
  font-size: 0.84rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;
