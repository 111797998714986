import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { SpacingProps } from '@mui/system';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import type { VehicleDriven } from '@data/datahub/vehicleDriven/types';
import { vehiclesDrivenAtom } from '@data/datahub/vehicleDriven';
import { useStoredVehicleId } from '@data/fms/vehicle/hooks';
import { useAtomValue } from 'jotai';

type Props = SpacingProps & {
  size?: 'small' | 'medium';
  vehicleId: string;
  isAll?: boolean;
  disabled?: boolean;
  width?: string;
  onChange: (selectedVehicle: VehicleDriven | VehicleDriven[]) => void;
};

const VehicleDrivenSelect: React.FC<Props> = ({
  size = 'medium',
  vehicleId,
  isAll = false,
  disabled = false,
  width = '200px',
  onChange,
  ...rest
}: Props) => {
  const { setStoredVehicleId } = useStoredVehicleId();
  const vehiclesDriven = useAtomValue(vehiclesDrivenAtom);
  const { t } = useTranslation();
  const { concat } = useConcatWord();

  const handleChangeSelect = useCallback(
    (e: SelectChangeEvent<string>) => {
      if (e.target.value === 'all') {
        onChange(vehiclesDriven);
        setStoredVehicleId(undefined);
        return;
      }
      const selectedVehicle = vehiclesDriven.find(
        (vehicle) => vehicle.vehicle_id === e.target.value,
      );
      if (!selectedVehicle) return;
      setStoredVehicleId(selectedVehicle.vehicle_id);
      onChange(selectedVehicle);
    },
    [vehiclesDriven, onChange, setStoredVehicleId],
  );

  const hasVehicleId = useMemo(() => {
    return vehiclesDriven.some((vehicle) => vehicle.vehicle_id === vehicleId);
  }, [vehicleId, vehiclesDriven]);

  const menuItem = useMemo(() => {
    const arr = [];
    if (isAll) {
      arr.push(
        <MenuItem key="all" value="all">
          {t('vehicle.all')}
        </MenuItem>,
      );
    }
    return [
      ...arr,
      vehiclesDriven.map((vehicle) => (
        <MenuItem value={vehicle.vehicle_id} key={vehicle.vehicle_id}>
          {vehicle.name}
        </MenuItem>
      )),
    ];
  }, [isAll, vehiclesDriven, t]);

  const selecteValue = useMemo(() => {
    if (isAll && vehicleId === 'all') return vehicleId;
    return hasVehicleId ? vehicleId : '';
  }, [hasVehicleId, isAll, vehicleId]);

  const label = useMemo(
    () => concat([t('vehicle.vehicle'), t('common.action.select')], true),
    [t, concat],
  );

  return (
    <FormControl size={size} disabled={disabled} sx={{ width }} {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select
        size={size}
        label={label}
        value={selecteValue}
        onChange={handleChangeSelect}
        data-testid="pulldown-driven-vehicles-select"
      >
        {menuItem}
      </Select>
    </FormControl>
  );
};

export default React.memo(VehicleDrivenSelect);
