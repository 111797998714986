import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export type Project = {
  id: string;
  created_at: string;
  updated_at: string;
  owner_group_id: string;
  description: string;
  display_name: string;
  is_active: boolean;
};

export type ProjectMember = {
  subject_id: string;
  name: string;
  participated_at: string;
  organization: {
    id: string;
    display_name: string;
  };
};

export const projectAtom = atom<Project | null>(null);

export const projectsAtom = atom<Project[]>([]);

export const projectMembersAtom = atom<ProjectMember[]>([]);

export const memberInfoSelectorFamily = atomFamily((subjectId: string) =>
  atom((get) => {
    const members = get(projectMembersAtom);
    const targetMember = members.find(
      (member) => member.subject_id === subjectId,
    );
    return targetMember;
  }),
);
