import type { APIState, Result } from '../';
import { Failure, Success, useFMSAPI, useErrorMessage } from '../';
import { environmentAtom } from '@data/fms/environment/states';
import { useCallback, useState } from 'react';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import type { Route } from '@data/fms/route/types';
import { useAtomCallback } from 'jotai/utils';

export type RouteSearchRequestParams = {
  origin_point: number;
  destination_point: number;
  start_time?: string;
};

/**
 * 走行ルート検索API
 */
export const useRouteSearchAPI = (): {
  state: APIState;
  getRouteSearch: (params: RouteSearchRequestParams) => Promise<Route | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      params: RouteSearchRequestParams,
    ): Promise<Result<Route[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/route_search`,
          {
            params,
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getRouteSearch = useAtomCallback(
    useCallback(
      async (get, _, params: RouteSearchRequestParams) => {
        setState('loading');
        if (!getHasScope(SCOPES.SearchRoute)) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_route_search', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }

        const res = await request(
          project.id,
          environment.environment_id,
          params,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_route_search', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          return null;
        }

        setState('hasValue');
        return res.value[0];
      },
      [notifyError, request, getHasScope, t, getErrorMessage],
    ),
  );

  return {
    state,
    getRouteSearch,
  };
};

export type RouteSearchFromCurrentPositionRequestParams = {
  destination_point: number;
  start_time?: string;
};

/**
 * 現在の車両位置から指定地点までの走行ルート検索API
 */
export const useRouteSearchFromCurrentPositionAPI = (): {
  state: APIState;
  getRouteSearchFromCurrentPosition: (
    vehicleId: string,
    params: RouteSearchFromCurrentPositionRequestParams,
  ) => Promise<Route | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: RouteSearchFromCurrentPositionRequestParams,
    ): Promise<Result<Route[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/route_search`,
          {
            params,
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getRouteSearchFromCurrentPosition = useAtomCallback(
    useCallback(
      async (
        get,
        _,
        vehicleId: string,
        params: RouteSearchFromCurrentPositionRequestParams,
      ) => {
        setState('loading');
        if (!getHasScope(SCOPES.SearchRoute)) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_route_search_current_position', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }
        const res = await request(
          project.id,
          environment.environment_id,
          vehicleId,
          params,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_route_search_current_position', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          return null;
        }
        setState('hasValue');
        return res.value[0];
      },
      [notifyError, request, getHasScope, t, getErrorMessage],
    ),
  );

  return {
    state,
    getRouteSearchFromCurrentPosition,
  };
};
