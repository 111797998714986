import { atom } from 'jotai';
import type { Place } from './types';

export const isPlacesLoadedAtom = atom(false);

export const placesAtom = atom<Place[]>([]);

export const hasPlacesSelector = atom((get) => {
  const places = get(placesAtom);
  return places.length > 0;
});

/**
 * 停車地点の緯度経度を返す
 */
export const placesLatLngsSelector = atom((get) => {
  const places = get(placesAtom);
  return places.map((place) => place.location);
});
