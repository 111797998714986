import store from 'store2';
import type {
  DrivingHistoryStorageKey,
  DrivingHistoryStorageValue,
} from './types';

export const getDrivingHistoryStorage = (key: DrivingHistoryStorageKey) => {
  const savedConfig = store.local.get('drivingHistoryConfig');
  if (!savedConfig) return null;
  return key ? savedConfig[key] : null;
};

export const setDrivingHistoryStorage = (
  key: DrivingHistoryStorageKey,
  value: DrivingHistoryStorageValue,
) => {
  const storageKey = 'drivingHistoryConfig';
  const savedConfig = store.local.get(storageKey);
  store.local.set(storageKey, {
    ...savedConfig,
    [key]: value,
  });
};
