import { useDiagnosticValueHistoriesAPI } from '@api/datahub';
import { DiagnosticValueHistoryItem } from '@components/common/atoms';
import type {
  Diagnostic,
  DiagnosticValueHistory,
} from '@data/datahub/diagnostics/types';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const VALUE_HISTORY_COLSPAN_VALUE = 6;

type ValueHistoryCellProps = {
  isFirstRequest: boolean;
  showLoadMoreValueHistories: boolean;
  diagnosticValueHistoriesLoading: boolean;
  valueHistories: DiagnosticValueHistory[];
  onClickLoadMore: () => void;
};

const ValueHistoryCell: React.FC<ValueHistoryCellProps> = ({
  isFirstRequest,
  showLoadMoreValueHistories,
  diagnosticValueHistoriesLoading,
  valueHistories,
  onClickLoadMore,
}) => {
  const { t } = useTranslation();

  if (isFirstRequest) {
    return (
      <TableCell
        colSpan={VALUE_HISTORY_COLSPAN_VALUE}
        sx={{ backgroundColor: colors.grey[50] }}
      >
        <Button
          size="small"
          onClick={onClickLoadMore}
          startIcon={<ExpandMore />}
        >
          {t('error_detail.load_value.first')}
        </Button>
      </TableCell>
    );
  }

  if (!valueHistories.length) {
    return diagnosticValueHistoriesLoading ? (
      <TableCell colSpan={VALUE_HISTORY_COLSPAN_VALUE}>
        <Box mt={2}>
          <CircularProgress size={20} />
        </Box>
      </TableCell>
    ) : null;
  }

  return (
    <TableCell colSpan={VALUE_HISTORY_COLSPAN_VALUE} sx={{ p: 0 }}>
      <Box
        sx={{
          backgroundColor: colors.grey[50],
          'tr:last-of-type td': {
            borderBottom: 'none',
          },
        }}
        px={4}
      >
        <TableContainer>
          <Table size="small" sx={{ borderBottom: 'none' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 60 }}>No</TableCell>
                <TableCell sx={{ width: 220 }}>
                  {t('error_detail.data.start_time')}
                </TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valueHistories.map((history, i) => (
                <DiagnosticValueHistoryItem
                  key={`history_${i}`}
                  no={i + 1}
                  valueHistory={history}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {diagnosticValueHistoriesLoading ? (
          <Box pb={4}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          showLoadMoreValueHistories && (
            <Box py={2}>
              <Button
                size="small"
                onClick={onClickLoadMore}
                startIcon={<ExpandMore />}
              >
                {t('error_detail.load_value.more')}
              </Button>
            </Box>
          )
        )}
      </Box>
    </TableCell>
  );
};

type Props = {
  vehicleId: string;
  no: number;
  diagnostic: Diagnostic;
};

const DiagnosticRow: React.FC<Props> = ({ vehicleId, no, diagnostic }) => {
  const { t } = useTranslation();
  const {
    loading: diagnosticValueHistoriesLoading,
    valueHistories,
    nextPageToken,
    getDiagnosticValueHistories,
  } = useDiagnosticValueHistoriesAPI();
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const showLoadMoreValueHistories = useMemo(
    () => isFirstRequest || !!nextPageToken,
    [nextPageToken, isFirstRequest],
  );

  const loadValueHistories = useCallback(
    (nextPageTokenValue?: string) => {
      setIsFirstRequest(false);
      const params = new URLSearchParams();
      params.append('from_datetime', diagnostic.start_timestamp);
      params.append('to_datetime', diagnostic.end_timestamp);
      params.append('hardware_id', diagnostic.hardware_id);
      params.append('name', diagnostic.name);
      params.append('level', diagnostic.level.toString());
      params.append('hardware_id', diagnostic.hardware_id);
      params.append('sort_order_asc_flag', 'false');
      if (!isNullOrUndefined(nextPageTokenValue)) {
        params.append('page_token', nextPageTokenValue);
      }
      getDiagnosticValueHistories(vehicleId, params);
    },
    [diagnostic, getDiagnosticValueHistories, vehicleId],
  );

  const handleClickLoadMore = useCallback(() => {
    loadValueHistories(nextPageToken);
  }, [nextPageToken, loadValueHistories]);

  const diagPeriod = useMemo(() => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          {DateTime.fromISO(diagnostic.start_timestamp).toFormat(
            'HH:mm:ss.SSS',
          )}
        </Grid>
        <Grid item>-</Grid>
        <Grid item>
          {DateTime.fromISO(diagnostic.end_timestamp).toFormat('HH:mm:ss.SSS')}
        </Grid>
      </Grid>
    );
  }, [diagnostic.start_timestamp, diagnostic.end_timestamp]);

  return (
    <>
      <TableRow>
        <TableCell>{no}</TableCell>
        <TableCell>{diagnostic.name}</TableCell>
        <TableCell>{diagnostic.hardware_id}</TableCell>
        <TableCell>
          <Chip
            size="small"
            label={t(
              `vehicle.telemetry.diagnostic_notice.level.${diagnostic.level}`,
            )}
          />
        </TableCell>
        <TableCell>{diagnostic.message}</TableCell>
        <TableCell>{diagPeriod}</TableCell>
      </TableRow>
      <TableRow sx={{ '&:last-of-type > td': { borderBottom: 'none' } }}>
        <ValueHistoryCell
          isFirstRequest={isFirstRequest}
          diagnosticValueHistoriesLoading={diagnosticValueHistoriesLoading}
          showLoadMoreValueHistories={showLoadMoreValueHistories}
          valueHistories={valueHistories}
          onClickLoadMore={handleClickLoadMore}
        />
      </TableRow>
    </>
  );
};

export default React.memo(DiagnosticRow);
