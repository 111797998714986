import { mapMenuAtom } from '@data/ui/states';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const StopPointsMenu: React.FC = () => {
  const { t } = useTranslation();
  const [mapMenu, setMapMenu] = useAtom(mapMenuAtom);

  const checks = useMemo(
    () => Object.keys(mapMenu.stopPointNames),
    [mapMenu.stopPointNames],
  );

  const isAllUnselected = useMemo(
    () => !checks.filter((key) => mapMenu.stopPointNames[key]).length,
    [checks, mapMenu.stopPointNames],
  );

  const handleChangeStopPoints = useCallback(() => {
    setMapMenu((prevState) => {
      const checks = Object.keys(prevState.stopPointNames);
      const isAllSelected = checks.every(
        (key) => prevState.stopPointNames[key],
      );
      return {
        ...prevState,
        stopPointNames: Object.fromEntries(
          Object.keys(prevState.stopPointNames).map((key) => [
            key,
            !isAllSelected,
          ]),
        ),
      };
    });
  }, [setMapMenu]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!isAllUnselected}
          size="small"
          indeterminate={
            !isAllUnselected &&
            !checks.every((key) => mapMenu.stopPointNames[key])
          }
          onChange={handleChangeStopPoints}
          data-testid="stop_point_name"
        />
      }
      label={t('map_menu.stop_point_names')}
    />
  );
};

export default React.memo(StopPointsMenu);
