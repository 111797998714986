import { projectAtom } from '@data/auth';
import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { environmentAtom, environmentsAtom } from './states';

export const useEnvironment = () => {
  const getEnvironmentName = useAtomCallback(
    useCallback((get, _, environmentId: string) => {
      const einvironments = get(environmentsAtom);
      const target = einvironments.find(
        (environment) => environment.environment_id === environmentId,
      );
      return target?.environment_name;
    }, []),
  );

  return {
    getEnvironmentName,
  };
};

export const useRootPath = () => {
  const project = useAtomValue(projectAtom);
  const environment = useAtomValue(environmentAtom);

  const path = useMemo(
    () => `/${project?.id}/${environment?.environment_id}/`,
    [project, environment],
  );

  return path;
};
