import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { useCallback, useState } from 'react';
import { environmentAtom } from '@data/fms/environment/states';
import { projectAtom } from '@data/auth';
import type { AxiosResponse, AxiosError } from 'axios';
import { useNotification } from '@data/notification';
import { useTranslation } from 'react-i18next';
import type {
  AreaMapVersion,
  EnvironmentAreaMapVersion,
} from '@data/fms/areamap/types';
import { useAtomCallback } from 'jotai/utils';

/**
 * プロジェクト内のエリアマップバージョン一覧取得API
 */
export type ParamsExclude = 'deleted' | 'not_deleted';

export const useAreaMapVersionsAPI = (): {
  state: APIState;
  getAreaMapVersions: (
    projectId?: string,
    areaMapId?: number,
    exclude?: ParamsExclude,
  ) => Promise<AreaMapVersion[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      areaMapId: number,
      exclude: ParamsExclude | undefined,
    ): Promise<Result<AreaMapVersion[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/area_maps/${areaMapId}/versions/`,
          {
            params: {
              exclude: exclude,
            },
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getAreaMapVersions = useAtomCallback(
    useCallback(
      async (
        get,
        _,
        projectId?: string,
        areaMapId?: number,
        exclude?: ParamsExclude,
      ) => {
        let paramProjectId = projectId;
        let paramAreaMapId = areaMapId;
        if (!paramProjectId || !paramAreaMapId) {
          const project = get(projectAtom);
          const environment = get(environmentAtom);
          if (!project || !environment) {
            notifyError({
              message: t('api.fms.get_area_map_versions', { status: 'failed' }),
            });
            setState('hasError');
            return [];
          }
          paramProjectId = project.id;
          paramAreaMapId = environment.area_map_id;
        }
        setState('loading');
        const res = await request(paramProjectId, paramAreaMapId, exclude);
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_map_versions', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getAreaMapVersions,
  };
};

/**
 * 単一エリアマップバージョン情報取得
 */
export const useAreaMapVersionAPI = (): {
  state: APIState;
  getAreaMapVersion: (
    areaMapVersionId: string,
  ) => Promise<AreaMapVersion | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      areaMapId: number,
      areaMapVersionId: string,
    ): Promise<Result<AreaMapVersion, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/area_maps/${areaMapId}/versions/${encodeURIComponent(
            areaMapVersionId,
          )}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  /**
   * 指定IDのエリアマップバージョン情報取得
   */
  const getAreaMapVersion = useAtomCallback(
    useCallback(
      async (get, _, areaMapVersionId: string) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_area_map_versions', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(
          project.id,
          environment.area_map_id,
          areaMapVersionId,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_map_versions', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getAreaMapVersion,
  };
};

/**
 * 走行環境内のエリアマップバージョン一覧取得API
 */
export const useEnvironmentAreaMapVersionsAPI = (): {
  state: APIState;
  getEnvironmentAreaMapVersions: (
    exclude?: ParamsExclude,
  ) => Promise<EnvironmentAreaMapVersion[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      areaMapId: number,
      exclude: ParamsExclude | undefined,
    ): Promise<Result<EnvironmentAreaMapVersion[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/area_maps/${areaMapId}/versions/`,
          {
            params: {
              exclude: exclude,
            },
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getEnvironmentAreaMapVersions = useAtomCallback(
    useCallback(
      async (get, _, exclude?: ParamsExclude) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_area_map_versions', { status: 'failed' }),
          });
          setState('hasError');
          return [];
        }
        setState('loading');
        const res = await request(
          project.id,
          environment.environment_id,
          environment.area_map_id,
          exclude,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_map_versions', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getEnvironmentAreaMapVersions,
  };
};

/**
 * 単一エリアマップバージョン情報取得
 */
export const useEnvironmentAreaMapVersionAPI = (): {
  state: APIState;
  getEnvironmentAreaMapVersion: (
    areaMapVersionId: string,
  ) => Promise<EnvironmentAreaMapVersion | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      areaMapId: number,
      areaMapVersionId: string,
    ): Promise<Result<EnvironmentAreaMapVersion, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/area_maps/${areaMapId}/versions/${encodeURIComponent(
            areaMapVersionId,
          )}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  /**
   * 指定IDのエリアマップバージョン情報取得
   */
  const getEnvironmentAreaMapVersion = useAtomCallback(
    useCallback(
      async (get, _, areaMapVersionId: string) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_area_map_versions', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(
          project.id,
          environment.environment_id,
          environment.area_map_id,
          areaMapVersionId,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_map_versions', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getEnvironmentAreaMapVersion,
  };
};
