export const SERVICE_NAMES = [
  'auth',
  'drive',
  'evaluator',
  'fms-api',
  'fms-console',
  'fms-go',
  'fms-call',
  'scenario-editor',
  'software-update',
  'tier-iv-account',
  'vector-map-builder',
  'vehicle-data-search',
  'vehicle-data-upload',
  'web-auto-console',
] as const;

export type ServiceName = (typeof SERVICE_NAMES)[number];

type ServiceDisplayNames = {
  [key: string]: string;
};

export const DISPLAY_SERVICE_NAMES: ServiceDisplayNames = {
  auth: 'Web.Auto Auth',
  drive: 'Autoware Drive',
  evaluator: 'Evaluator',
  'fms-api': 'FMS API',
  'fms-console': 'FMS Console',
  'fms-go': 'FMS Go',
  'fms-call': 'FMS Calls',
  'scenario-editor': 'Scenario Editor',
  'software-update': 'Software Update',
  'tier-iv-account': 'TIER IV Account',
  'vector-map-builder': 'Vector Map Builder',
  'vehicle-data-search': 'Vehicle Data Search',
  'vehicle-data-upload': 'Vehicle Data Upload',
  'web-auto-console': 'Web.Auto Console',
} as const;

export const SERVICE_STATUS = [
  'available',
  'partially_available',
  'not_available',
] as const;
export type ServiceStatus = (typeof SERVICE_STATUS)[number];

export const SERVICE_STATUS_REASON = [
  'maintenance',
  'incident',
  'other',
] as const;
export type ServiceStatusReason = (typeof SERVICE_STATUS_REASON)[number];

export type Service = {
  planned_maintenance: string[];
  status: ServiceStatus;
  status_reason?: ServiceStatusReason;
  maintenance_event_list?: MaintenanceEvent[];
};

export const MAINTENANCE_INCIDENT_LEVEL = [
  'critical',
  'high',
  'moderate',
  'low',
  'informational',
] as const;
export type MaintenanceIncidentLevel =
  (typeof MAINTENANCE_INCIDENT_LEVEL)[number];

export const MAINTENANCE_STATUS = [
  'not started',
  'in progress',
  'completed',
  'canceled',
] as const;
export type MaintenanceStatus = (typeof MAINTENANCE_STATUS)[number];

export const MAINTENANCE_TYPE = ['scheduled', 'unplanned'] as const;
export type MaintenanceType = (typeof MAINTENANCE_TYPE)[number];

export type MaintenanceSummary = {
  en: string;
  ja: string;
};

export type MaintenanceEvent = {
  summary: MaintenanceSummary;
  id: string;
  start_time: string;
  end_time?: string;
  status: MaintenanceStatus;
  type: MaintenanceType;
  target_services: string[];
  last_updated_time: string;
  incident_level?: MaintenanceIncidentLevel;
};
