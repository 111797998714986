import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  TextField,
} from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useTranslation } from 'react-i18next';
import { X } from 'react-feather';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
  size?: 'small' | 'medium';
  width?: string | number;
  spacing?: number;
  disabled?: boolean;
  clearAction?: boolean;
  fromDateValue: DateTime | null;
  fromDateErrorMessage: string | null;
  toDateValue: DateTime | null;
  toDateErrorMessage: string | null;
  errorMessage: string | null;
  startMaxDate?: DateTime;
  endMaxDate?: DateTime;
  onChangeFromDate: (fromDate: DateTime | null) => void;
  onChangeToDate: (toDate: DateTime | null) => void;
  onClearFromDate?: () => void;
  onClearToDate?: () => void;
};

const RangeDatePicker: React.FC<Props> = ({
  size = 'medium',
  width = 'auto',
  spacing = 6,
  disabled = false,
  clearAction = false,
  fromDateValue,
  fromDateErrorMessage,
  toDateValue,
  toDateErrorMessage,
  errorMessage,
  startMaxDate = DateTime.now().minus({ days: 1 }),
  endMaxDate = DateTime.now().minus({ days: 1 }),
  onChangeFromDate,
  onChangeToDate,
  onClearFromDate,
  onClearToDate,
}: Props) => {
  const { t } = useTranslation();

  const handleClickFromClearButton = useCallback(() => {
    if (isNullOrUndefined(onClearFromDate)) return;
    onClearFromDate();
  }, [onClearFromDate]);

  const handleClickToClearButton = useCallback(() => {
    if (isNullOrUndefined(onClearToDate)) return;
    onClearToDate();
  }, [onClearToDate]);

  const startMaxDateLuxon = useMemo(() => {
    if (isNullOrUndefined(toDateValue)) return startMaxDate;
    return toDateValue;
  }, [toDateValue, startMaxDate]);

  const handleChangeFromDate = useCallback(
    (date: DateTime | null) => {
      onChangeFromDate(
        date ? DateTime.fromISO(date.toFormat('yyyyMMdd')) : null,
      );
    },
    [onChangeFromDate],
  );

  const handleChangeToDate = useCallback(
    (date: DateTime | null) => {
      onChangeToDate(date ? DateTime.fromISO(date.toFormat('yyyyMMdd')) : null);
    },
    [onChangeToDate],
  );

  return (
    <Box date-testid="rangeDatePicker">
      <Grid container spacing={spacing} alignItems="flex-start">
        <GridItem data-testid="rangeDatePicker-startDate">
          <DatePicker
            disabled={disabled}
            label={t('common.date.start_date')}
            minDate={DateTime.fromISO('2010-01-01')}
            maxDate={startMaxDateLuxon}
            disableFuture
            mask="____/__/__"
            inputFormat="yyyy/MM/dd"
            value={fromDateValue}
            onChange={handleChangeFromDate}
            renderInput={(params) => (
              <TextField
                {...params}
                size={size}
                sx={{ width }}
                error={!isNullOrUndefined(fromDateErrorMessage)}
                helperText={fromDateErrorMessage}
              />
            )}
          />
          {clearAction && !isNullOrUndefined(fromDateValue) && (
            <ClearButtonWrapper sx={{ top: size === 'medium' ? 37 : 28 }}>
              <IconButton
                size="small"
                disabled={disabled}
                onClick={handleClickFromClearButton}
              >
                <X size={18} />
              </IconButton>
            </ClearButtonWrapper>
          )}
        </GridItem>
        <GridItem data-testid="rangeDatePicker-endDate">
          <DatePicker
            disabled={disabled}
            label={t('common.date.end_date')}
            value={toDateValue}
            minDate={fromDateValue ?? undefined}
            maxDate={endMaxDate}
            disableFuture
            mask="____/__/__"
            inputFormat="yyyy/MM/dd"
            onChange={handleChangeToDate}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size={size}
                  sx={{ width }}
                  error={!isNullOrUndefined(toDateErrorMessage)}
                  helperText={toDateErrorMessage}
                />
              );
            }}
          />
          {clearAction && !isNullOrUndefined(toDateValue) && (
            <ClearButtonWrapper sx={{ top: size === 'medium' ? 37 : 28 }}>
              <IconButton
                size="small"
                disabled={disabled}
                onClick={handleClickToClearButton}
              >
                <X size={18} />
              </IconButton>
            </ClearButtonWrapper>
          )}
        </GridItem>
      </Grid>
      {!isNullOrUndefined(errorMessage) &&
        isNullOrUndefined(fromDateErrorMessage) &&
        isNullOrUndefined(toDateErrorMessage) && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
    </Box>
  );
};

export default React.memo(RangeDatePicker);

const GridItem = styled(Grid)`
  position: relative;
`;

GridItem.defaultProps = {
  item: true,
};

const ClearButtonWrapper = styled(Box)`
  position: absolute;
  right: 43px;
`;
