import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { useCallback, useState } from 'react';
import { projectAtom } from '@data/auth';
import type { AxiosResponse, AxiosError } from 'axios';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useTranslation } from 'react-i18next';
import type { AreaMap } from '@data/fms/areamap/types';
import { useAtomCallback } from 'jotai/utils';

/**
 * エリアマップ一覧取得API
 */
export const useAreaMapsAPI = (): {
  state: APIState;
  getAreaMaps: (projectId?: string) => Promise<AreaMap[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getAreaMapsAPI = useCallback(
    async (projectId: string): Promise<Result<AreaMap[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(`/${projectId}/area_maps`);
        if (isNullOrUndefined(res.data?.area_maps)) {
          return new Success([]);
        }
        // 昇順ソート
        res?.data?.area_maps.sort((a: AreaMap, b: AreaMap) => {
          if (a.area_map_name < b.area_map_name) return -1;
          return 1;
        });
        return new Success(res?.data?.area_maps);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getAreaMaps = useAtomCallback(
    useCallback(
      async (get, _, projectId: string | undefined) => {
        let paramProjectId = projectId;
        if (isNullOrUndefined(paramProjectId)) {
          const project = get(projectAtom);
          if (isNullOrUndefined(project)) {
            notifyError({
              message: t('api.fms.get_area_maps', { status: 'failed' }),
            });
            setState('hasError');
            return [];
          }
          paramProjectId = project!.id;
        }
        setState('loading');
        const res = await getAreaMapsAPI(paramProjectId);
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_maps', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, getAreaMapsAPI, t, getErrorMessage],
    ),
  );

  return {
    state,
    getAreaMaps,
  };
};

/**
 * 個別エリアマップ取得API
 */
export const useAreaMapAPI = (): {
  state: APIState;
  getAreaMap: (areaMapId: number) => Promise<AreaMap | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getAreaMapAPI = useCallback(
    async (
      projectId: string,
      areaMapId: number,
    ): Promise<Result<AreaMap, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(`/${projectId}/area_maps/${areaMapId}`);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getAreaMap = useAtomCallback(
    useCallback(
      async (get, _, areaMapId: number) => {
        const project = get(projectAtom);
        if (isNullOrUndefined(project)) {
          notifyError({
            message: t('api.fms.get_area_maps', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await getAreaMapAPI(project.id, areaMapId);
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_area_maps', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, getAreaMapAPI, t, getErrorMessage],
    ),
  );

  return {
    state,
    getAreaMap,
  };
};
