import { atom } from 'jotai';
import { environmentAtom } from '../environment/states';
import type { NoEntryZoneSettingWithLanelets } from '../noEntryZone/type';
import type { EnvironmentAreaMapVersion } from './types';

export const areaMapVersionsAtom = atom<EnvironmentAreaMapVersion[]>([]);

/**
 * 現在のエリアマップバージョンを返す
 * @return EnvironmentAreaMapVersion[]
 */
export const currentAreaMapVersionSelector = atom<
  EnvironmentAreaMapVersion | undefined
>((get) => {
  const environment = get(environmentAtom);
  const areaMapVersions = get(areaMapVersionsAtom);
  return areaMapVersions.find(
    (areaMapVersion) =>
      environment?.area_map_version_id === areaMapVersion.version_id,
  );
});

/**
 * 現在のエリアマップバージョンがレーン情報が持っているかどうかを返す
 * @return Boolean
 */
export const hasCurrentAreaMapVersionLaneletsSelector = atom((get) => {
  const currentAreaMapVersion = get(currentAreaMapVersionSelector);
  return (
    currentAreaMapVersion?.lanelets_center_line_points_status === 'created'
  );
});

/**
 * 現在のエリアマップバージョンに反映されている通行禁止区域設定
 */
export const appliedNoEntryZoneSettingAtom =
  atom<NoEntryZoneSettingWithLanelets | null>(null);
