import { useDiagnosticValueHistoriesAPI } from '@api/datahub';
import { useProbesAPI } from '@api/datahub/getProbes';
import type { SelectedHistoryData } from '@components/pages/DrivingHistory/types';
import type {
  Diagnostic,
  DiagnosticValueHistory,
} from '@data/datahub/diagnostics/types';
import type { Probe } from '@data/datahub/probe/types';
import styled from '@emotion/styled';
import { ExpandMore, Room } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import isNullOrUndefined, { notNull } from '@utils/isNullOrUndefined';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Minus } from 'react-feather';
import { useTranslation } from 'react-i18next';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const dateText = useMemo(() => {
    if (isNullOrUndefined(date)) return null;
    return DateTime.fromISO(date).toFormat('yyyy/MM/dd');
  }, [date]);

  const timeText = useMemo(() => {
    if (isNullOrUndefined(date)) return null;
    return (
      <>
        {DateTime.fromISO(date).toFormat('HH:mm:ss')}
        <span
          style={{
            fontSize: 14,
          }}
        >
          {DateTime.fromISO(date).toFormat('.SSS')}
        </span>
      </>
    );
  }, [date]);

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {dateText}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 500 }}>
        {timeText}
      </Typography>
    </>
  );
});

Date.displayName = 'Date';

type ValueHistoryItemProps = {
  no: number;
  valueHistory: DiagnosticValueHistory;
  probeDatas: Probe[];
  onClickMapIcon: (data: SelectedHistoryData<Probe>) => void;
};

const ValueHistoryItem: React.FC<ValueHistoryItemProps> = React.memo(
  ({ no, valueHistory, probeDatas, onClickMapIcon }: ValueHistoryItemProps) => {
    const { t } = useTranslation();

    const targetProbe = useMemo(() => {
      if (isNullOrUndefined(probeDatas) || probeDatas.length === 0) {
        return null;
      }
      const target = probeDatas
        .map((probe, i) => {
          if (
            DateTime.fromISO(probe.timestamp).valueOf() ===
            DateTime.fromISO(valueHistory.timestamp).valueOf()
          ) {
            return {
              no: i,
              data: probe,
            } as SelectedHistoryData<Probe>;
          }
          return null;
        })
        .filter(notNull);
      return target[0];
    }, [probeDatas, valueHistory.timestamp]);

    const mapIconDisabled = useMemo(
      () =>
        isNullOrUndefined(targetProbe) ||
        isNullOrUndefined(targetProbe.data.lat) ||
        isNullOrUndefined(targetProbe.data.lng),
      [targetProbe],
    );

    const handleClickMapIcon = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isNullOrUndefined(targetProbe)) {
          return;
        }
        const no = Number(e.currentTarget.dataset.index);
        onClickMapIcon({
          ...targetProbe,
          no,
        });
      },
      [targetProbe, onClickMapIcon],
    );

    return (
      <TableRow>
        <TableCell>{no}</TableCell>
        <TableCell>
          {DateTime.fromISO(valueHistory.timestamp).toFormat(
            'yyyy/MM/dd HH:mm:ss.SSS',
          )}
        </TableCell>
        <TableCell>
          {valueHistory.values.map((value, i) => (
            <Typography key={`value_${i}`}>
              <strong>{value.key}</strong>: {value.value}
            </Typography>
          ))}
        </TableCell>
        <TableCell align="center">
          <Tooltip
            arrow
            placement="top"
            title={
              mapIconDisabled
                ? t('driving_history.foa_list.location_unknown')
                : ''
            }
          >
            <span>
              <IconButton
                size="small"
                data-index={no}
                disabled={mapIconDisabled}
                onClick={handleClickMapIcon}
              >
                <Room />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  },
);

ValueHistoryItem.displayName = 'ValueHistoryItem';

type Props = {
  vehicleId: string;
  diagnostic: Diagnostic;
  onClickMapIcon: (selectedData: SelectedHistoryData<Probe>) => void;
};

const DiagnosticItem: React.FC<Props> = ({
  vehicleId,
  diagnostic,
  onClickMapIcon,
}) => {
  const { t } = useTranslation();
  const {
    loading: diagnosticValueHistoriesLoading,
    valueHistories,
    nextPageToken,
    getDiagnosticValueHistories,
  } = useDiagnosticValueHistoriesAPI();
  const { probeDatas, getProbes } = useProbesAPI();

  const showLoadMoreValueHistories = useMemo(() => {
    // 走行履歴ページの場合
    return !isNullOrUndefined(nextPageToken);
  }, [nextPageToken]);

  const loadValueHistories = useCallback(
    (nextPageTokenValue?: string) => {
      const params = new URLSearchParams();
      params.append('from_datetime', diagnostic.start_timestamp);
      params.append('to_datetime', diagnostic.end_timestamp);
      getProbes(vehicleId, params);

      const historyParams = new URLSearchParams(params);
      historyParams.append('hardware_id', diagnostic.hardware_id);
      historyParams.append('name', diagnostic.name);
      historyParams.append('level', diagnostic.level.toString());
      historyParams.append('hardware_id', diagnostic.hardware_id);
      if (!isNullOrUndefined(nextPageTokenValue)) {
        historyParams.append('page_token', nextPageTokenValue);
      }
      getDiagnosticValueHistories(vehicleId, historyParams);
    },
    [diagnostic, getDiagnosticValueHistories, getProbes, vehicleId],
  );

  const handleClickLoadMore = useCallback(() => {
    loadValueHistories(nextPageToken);
  }, [nextPageToken, loadValueHistories]);

  const valueHistoriesContent = useMemo(() => {
    if (valueHistories.length === 0 && diagnosticValueHistoriesLoading) {
      return (
        <TableCell colSpan={4} align="center">
          <Box mt={2}>
            <CircularProgress size={20} />
          </Box>
        </TableCell>
      );
    }
    if (valueHistories.length === 0 && !diagnosticValueHistoriesLoading) {
      return null;
    }
    return (
      <TableCell colSpan={4} sx={{ borderBottom: 'none', p: 0 }}>
        <Box sx={{ backgroundColor: colors.grey[50] }} p={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 60 }}>No</TableCell>
                  <TableCell sx={{ width: 220 }}>
                    {t('error_detail.data.start_time')}
                  </TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell align="center" sx={{ width: 80 }}>
                    Map
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {valueHistories.map((history, i) => (
                  <ValueHistoryItem
                    key={`history_${i}`}
                    no={i + 1}
                    valueHistory={history}
                    probeDatas={probeDatas}
                    onClickMapIcon={onClickMapIcon}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {diagnosticValueHistoriesLoading ? (
            <Box textAlign="center" mt={6}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            showLoadMoreValueHistories && (
              <Box mt={2}>
                <Button
                  size="small"
                  onClick={handleClickLoadMore}
                  startIcon={<ExpandMore />}
                >
                  {t('common.general.load_more')}
                </Button>
              </Box>
            )
          )}
        </Box>
      </TableCell>
    );
  }, [
    diagnosticValueHistoriesLoading,
    valueHistories,
    handleClickLoadMore,
    probeDatas,
    onClickMapIcon,
    showLoadMoreValueHistories,
    t,
  ]);

  useEffect(() => {
    loadValueHistories();
  }, [loadValueHistories]);

  return (
    <Wrapper>
      <Box p={4} sx={{ backgroundColor: colors.grey[100] }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Date date={diagnostic.start_timestamp} />
              </Grid>
              <Grid item>
                <Box mt={1} width={24} height={24}>
                  <Minus />
                </Box>
              </Grid>
              <Grid item>
                <Date date={diagnostic.end_timestamp} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TableContainer sx={{ borderTop: `solid 1px ${colors.grey[300]}` }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 360 }}>
                {t('error_detail.data.name')}
              </TableCell>
              <TableCell>{t('error_detail.data.hardware_id')}</TableCell>
              <TableCell sx={{ width: 100 }}>
                {t('error_detail.data.level')}
              </TableCell>
              <TableCell>{t('error_detail.data.message')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{diagnostic.name}</TableCell>
              <TableCell>{diagnostic.hardware_id}</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={t(
                    `vehicle.telemetry.diagnostic_notice.level.${diagnostic.level}`,
                  )}
                />
              </TableCell>
              <TableCell>{diagnostic.message}</TableCell>
            </TableRow>
            <TableRow>{valueHistoriesContent}</TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default React.memo(DiagnosticItem);

const Wrapper = styled(Box)`
  border-radius: 4px;
  border: solid 1px ${colors.grey[300]};
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
