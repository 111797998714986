import { DIAGNOSTIC_LEVEL_STRING } from '@data/datahub/diagnostics/constants';
import type {
  DiagnosticLevelString,
  DiagnosticLevel,
} from '@data/datahub/diagnostics/types';

export const levelLabel = (level: DiagnosticLevel): string | null => {
  if (level === 0) return null;
  if (level === 1) return 'SF';
  if (level === 2) return 'LF';
  if (level === 3) return 'SPF';
  return null;
};

export const levelNumberToString = (
  no: DiagnosticLevel,
): DiagnosticLevelString => {
  return DIAGNOSTIC_LEVEL_STRING[no];
};

export const levelStringToNumber = (
  level: DiagnosticLevelString,
): DiagnosticLevel => {
  return DIAGNOSTIC_LEVEL_STRING.findIndex(
    (str) => str === level,
  ) as DiagnosticLevel;
};
