import {
  styled,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText as MuiFormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import type { ControllerRenderProps } from 'react-hook-form';
import type { FormData } from '@pages/VehicleRegister/VehicleRegister';
type Props = {
  label: string;
  displayValue?: string;
  field?:
    | ControllerRenderProps<FormData, 'password'>
    | ControllerRenderProps<FormData, 'passwordConfirm'>;
  errorMessage?: string;
  readOnly?: boolean;
  disabled?: boolean;
};

const PasswordInput: React.FC<Props> = ({
  label,
  displayValue,
  field,
  errorMessage,
  readOnly,
  disabled,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const id = `${field?.name}-input`;
  const isError = !disabled && !!errorMessage;
  const value = displayValue ?? field?.value;
  const required = !readOnly && !disabled;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" required={required} fullWidth>
      <RequiredInputLabel
        htmlFor={id}
        error={isError}
        disabled={disabled}
        shrink
      >
        {label}
      </RequiredInputLabel>
      <OutlinedInput
        {...field}
        id={id}
        type={showPassword ? 'text' : 'password'}
        label={label}
        error={isError}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(PasswordInput);

const RequiredInputLabel = styled(InputLabel)`
  .MuiInputLabel-asterisk {
    color: ${({ theme }) => theme.palette.error.main};
  }
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.secondary};
  background-color: white;
  padding-left: 7px;
  padding-right: 8px;
  left: -5px;
`;

const FormHelperText = styled(MuiFormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;
