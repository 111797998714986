import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import type { Environment } from '@data/fms/environment/types';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CreateEnvironmentBody = {
  environment_name: string;
  description: string;
  area_map_id: number;
  area_map_version_id: string;
};

/**
 * 走行環境作成API
 */
export const useCreateEnvironmentAPI = (): {
  state: APIState;
  postCreateEnvironment: (
    projectId: string,
    body: CreateEnvironmentBody,
  ) => Promise<Environment | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError, notifySuccess } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const postCreateEnvironmentAPI = useCallback(
    async (
      projectId: string,
      body: CreateEnvironmentBody,
    ): Promise<Result<Environment, AxiosResponse>> => {
      try {
        const res = await fmsAPI.post(`/${projectId}/environments`, body);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const postCreateEnvironment = useCallback(
    async (projectId: string, body: CreateEnvironmentBody) => {
      setState('loading');
      const res = await postCreateEnvironmentAPI(projectId, body);
      if (isNullOrUndefined(res.value) || res.isFailure()) {
        notifyError({
          message: `${t('api.fms.post_create_environment', {
            status: 'failed',
          })}: ${getErrorMessage(res)}`,
        });
        setState('hasError');
        return null;
      }
      notifySuccess({
        message: t('api.fms.post_create_environment', {
          status: 'successfully',
        }),
      });
      setState('hasValue');
      return res.value;
    },
    [notifyError, notifySuccess, postCreateEnvironmentAPI, t, getErrorMessage],
  );

  return {
    state,
    postCreateEnvironment,
  };
};
