import {
  FEATURE_SCOPES,
  SCOPES,
  useHasFeatureScope,
  useHasScope,
} from '@data/auth';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useMemo } from 'react';
import type { RouteInfoType } from './types';
import {
  AlertTriangle,
  BarChart2,
  Calendar,
  CheckSquare,
  Clock,
  Layout,
  List,
  Map,
  MapPin,
  MessageSquare,
  PieChart,
  PlusCircle,
  Repeat,
  Tool,
  TrendingUp,
} from 'react-feather';

const Top = React.lazy(() => import(/* viteChunkName: 'top' */ '@pages/Top'));
const DrivingEnvironment = React.lazy(
  () =>
    import(
      /* viteChunkName: 'driving-environment' */ '@pages/DrivingEnvironment'
    ),
);
const DrivingEnvironmentNoEntryZoneSetting = React.lazy(
  () =>
    import(
      /* viteChunkName: 'driving-environment-no-entry-zone-setting' */ '@pages/DrivingEnvironmentNoEntryZoneSetting'
    ),
);
const DrivingHistory = React.lazy(
  () => import(/* viteChunkName: 'driving-history' */ '@pages/DrivingHistory'),
);
const ErrorDetail = React.lazy(
  () => import(/* viteChunkName: 'error-detail' */ '@pages/ErrorDetail'),
);
const InitialPosition = React.lazy(
  () =>
    import(/* viteChunkName: 'initial-position' */ '@pages/InitialPosition'),
);
const OperationReport = React.lazy(
  () =>
    import(/* viteChunkName: 'operation-report' */ '@pages/OperationReport'),
);
const ScheduleRegister = React.lazy(
  () =>
    import(/* viteChunkName: 'schedule-register' */ '@pages/ScheduleRegister'),
);
const ScheduleCheck = React.lazy(
  () => import(/* viteChunkName: 'schedule-check' */ '@pages/ScheduleCheck'),
);
const MaintenanceSchedule = React.lazy(
  () =>
    import(
      /* viteChunkName: 'maintenance-schedule' */ '@pages/MaintenanceSchedule'
    ),
);
const VehicleDetail = React.lazy(
  () => import(/* viteChunkName: 'vehicle-detail' */ '@pages/VehicleDetail'),
);
const VehicleManagement = React.lazy(
  () =>
    import(
      /* viteChunkName: 'vehicle-management' */ '@pages/VehicleManagement'
    ),
);
const VehicleConditionManagement = React.lazy(
  () =>
    import(
      /* viteChunkName: 'vehicle-condition-management' */ '@pages/VehicleConditionManagement'
    ),
);
const VehicleRegister = React.lazy(
  () =>
    import(/* viteChunkName: 'vehicle-register' */ '@pages/VehicleRegister'),
);
const DrivingNoteList = React.lazy(
  () =>
    import(/* viteChunkName: 'driving-note-list' */ '@pages/DrivingNoteList'),
);
const DrivingNoteRegister = React.lazy(
  () =>
    import(
      /* viteChunkName: 'driving-note-management' */ '@pages/DrivingNoteRegister'
    ),
);
const DrivingNoteDetail = React.lazy(
  () =>
    import(
      /* viteChunkName: 'driving-note-detail' */ '@pages/DrivingNoteDetail'
    ),
);
const PlanActualVariance = React.lazy(
  () =>
    import(
      /* viteChunkName: 'plan-actual-variance' */ '@pages/PlanActualVariance'
    ),
);
const Analysis = React.lazy(
  () => import(/* viteChunkName: 'analysis' */ '@pages/Analysis'),
);
const Settings = React.lazy(
  () => import(/* viteChunkName: 'settings' */ '@pages/Settings'),
);

export const sideBarRoutes: RouteInfoType[] = [
  {
    id: 'top',
    path: '',
    icon: <Layout />,
    component: Top,
  },
  {
    id: 'vehicle_register',
    path: 'vehicle-register/',
    icon: <PlusCircle />,
    component: VehicleRegister,
    scope: SCOPES.CreateVehicle,
  },
  {
    id: 'vehicle_management',
    sideBarPath: 'vehicle-management/',
    path: 'vehicle-management/',
    icon: <List />,
    component: VehicleManagement,
  },
  {
    id: 'vehicle_management_vehicle_id',
    path: 'vehicle-management/:vehicle_id/',
    component: VehicleManagement,
  },
  {
    id: 'vehicle_condition',
    sideBarPath: 'vehicle-condition/',
    path: 'vehicle-condition/',
    icon: <Tool />,
    component: VehicleConditionManagement,
  },
  {
    id: 'vehicle_condition_type',
    path: 'vehicle-condition/:type/',
    component: VehicleConditionManagement,
  },
  {
    id: 'vehicle_condition_type_vehicle_id',
    path: 'vehicle-condition/:type/:vehicle_id/',
    component: VehicleConditionManagement,
  },
  {
    id: 'schedule_register',
    sideBarPath: 'schedule-register/',
    path: 'schedule-register/',
    icon: <Repeat />,
    component: ScheduleRegister,
    featureScope: [FEATURE_SCOPES.BasicSchedule, FEATURE_SCOPES.LoopSchedule],
    scope: [SCOPES.CreateBasicSchedule, SCOPES.CreateLoopSchedule],
  },
  {
    id: 'schedule_register_vehicle_id',
    path: 'schedule-register/:vehicle_id/',
    component: ScheduleRegister,
    scope: [SCOPES.CreateBasicSchedule, SCOPES.CreateLoopSchedule],
  },
  {
    id: 'driving_history',
    sideBarPath: 'driving-history/?type=task',
    path: 'driving-history/',
    icon: <TrendingUp />,
    component: DrivingHistory,
  },
  {
    id: 'driving_history_vehicle_id',
    path: 'driving-history/:vehicle_id/',
    component: DrivingHistory,
  },
  {
    id: 'operation_report',
    sideBarPath: 'operation-report/',
    path: 'operation-report/',
    icon: <CheckSquare />,
    component: OperationReport,
    scope: SCOPES.DescribeReport,
  },
  {
    id: 'operation_report_vehicle_id',
    path: 'operation-report/:vehicle_id/',
    component: OperationReport,
    scope: SCOPES.DescribeReport,
  },
  {
    id: 'operation_report_vehicle_id_date',
    path: 'operation-report/:vehicle_id/:date/',
    component: OperationReport,
    scope: SCOPES.DescribeReport,
  },
  {
    id: 'maintenance_schedule',
    path: 'maintenance-schedule/',
    icon: <Clock />,
    component: MaintenanceSchedule,
    scope: SCOPES.DescribeSchedule,
    featureScope: FEATURE_SCOPES.MaintenanceSchedule,
  },
  {
    id: 'schedule_check',
    sideBarPath: 'schedule-check/',
    path: 'schedule-check/',
    icon: <Calendar />,
    component: ScheduleCheck,
    scope: SCOPES.DescribeSchedule,
  },
  {
    id: 'schedule_check_vehicle_id',
    path: 'schedule-check/:vehicle_id/',
    component: ScheduleCheck,
    scope: SCOPES.DescribeSchedule,
  },
  {
    id: 'error_detail',
    sideBarPath: 'error-detail/',
    path: 'error-detail/',
    icon: <AlertTriangle />,
    component: ErrorDetail,
    scope: SCOPES.DescribeVehicleError,
  },
  {
    id: 'error_detail_vehicle_id',
    path: 'error-detail/:vehicle_id/',
    component: ErrorDetail,
    scope: SCOPES.DescribeVehicleError,
  },
  {
    id: 'initial_position',
    path: 'initial-position/',
    icon: <MapPin />,
    component: InitialPosition,
    scope: SCOPES.UpdateVehicleInitialPoint,
  },
  {
    id: 'driving_environment',
    path: 'driving-environment/',
    icon: <Map />,
    component: DrivingEnvironment,
  },
  {
    id: 'driving_memo',
    sideBarPath: 'driving-note-management/',
    path: 'driving-note-management/',
    icon: <MessageSquare />,
    component: DrivingNoteList,
    scope: SCOPES.DescribeDrivingNote,
  },
  {
    id: 'driving_memo_date',
    path: 'driving-note-management/:date/',
    component: DrivingNoteList,
    scope: SCOPES.DescribeDrivingNote,
  },
  {
    id: 'driving_memo_date_vehicle_id',
    path: 'driving-note-management/:date/:vehicle_id/',
    component: DrivingNoteList,
    scope: SCOPES.DescribeDrivingNote,
  },
  {
    id: 'plan_actual_variance',
    sideBarPath: 'plan-actual-variance/',
    path: 'plan-actual-variance/',
    icon: <BarChart2 />,
    component: PlanActualVariance,
    scope: 'planActualVariance',
  },
  {
    id: 'plan_actual_variance',
    path: 'plan-actual-variance/:vehicle_id/',
    component: PlanActualVariance,
    scope: 'planActualVariance',
  },
  {
    id: 'analysis',
    sideBarPath: 'analysis/?type=error',
    path: 'analysis/',
    icon: <PieChart />,
    component: Analysis,
  },
  {
    id: 'analysis_vehicle_id',
    path: 'analysis/:vehicle_id/',
    component: Analysis,
  },
];

const otherRoutes: RouteInfoType[] = [
  {
    id: 'vehicle_detail',
    path: 'vehicle/',
    component: VehicleDetail,
  },
  {
    id: 'vehicle_detail_vehicle_id',
    path: 'vehicle/:vehicle_id/',
    component: VehicleDetail,
  },
  {
    id: 'driving_memo',
    path: 'driving-note-register/',
    component: DrivingNoteRegister,
    scope: SCOPES.CreateDrivingNote,
  },
  {
    id: 'driving_memo_vehicle_id',
    path: 'driving-note-register/:vehicle_id/',
    component: DrivingNoteRegister,
    scope: SCOPES.CreateDrivingNote,
  },
  {
    id: 'driving_memo',
    path: 'driving-note-details/',
    component: DrivingNoteDetail,
    scope: SCOPES.DescribeDrivingNote,
  },
  {
    id: 'driving_memo_note_id',
    path: 'driving-note-details/:vehicle_id/:note_id?/',
    component: DrivingNoteDetail,
    scope: SCOPES.DescribeDrivingNote,
  },
  {
    id: 'driving_environment_no_entry_zone',
    path: 'driving-environment/no-entry-zone-setting/*',
    component: DrivingEnvironmentNoEntryZoneSetting,
    featureScope: FEATURE_SCOPES.NoEntryZoneManagement,
    scope: SCOPES.DescribeNoEntryZoneSetting,
  },
  {
    id: 'settings',
    path: 'settings/',
    component: Settings,
  },
];

export const allRoutes: RouteInfoType[] = [...sideBarRoutes, ...otherRoutes];

export const useFilteredRoutes = (
  type: 'all' | 'sidebar' = 'all',
): RouteInfoType[] => {
  const getHasScope = useHasScope();
  const getHasFeatureScope = useHasFeatureScope();

  const hasDescribeEnvironmentAreaMapScope = useMemo(
    () => getHasScope(SCOPES.DescribeAreaMap),
    [getHasScope],
  );

  const routes = useMemo(() => {
    const target = type === 'all' ? allRoutes : sideBarRoutes;
    return target
      .map((route) => {
        if (type === 'sidebar' && isNullOrUndefined(route.icon)) {
          return null;
        }
        // Feature Scope の検証
        if (
          isNullOrUndefined(route.featureScope) &&
          isNullOrUndefined(route.scope)
        ) {
          return route;
        }
        const checkFeatureScope = () => {
          // FeatureScope の検証
          if (typeof route.featureScope === 'string') {
            return getHasFeatureScope(route.featureScope);
          }
          if (Array.isArray(route.featureScope)) {
            // 配列内の FeatureScope がひとつでもあればサイドメニューに表示し、ページをレンダリングする
            return route.featureScope.some((featureScope) =>
              getHasFeatureScope(featureScope),
            );
          }
          return false;
        };
        const checkScope = () => {
          // Scope の検証
          if (
            route.scope === 'planActualVariance' &&
            hasDescribeEnvironmentAreaMapScope
          ) {
            return true;
          }
          if (typeof route.scope === 'string') {
            return getHasScope(route.scope);
          }
          if (Array.isArray(route.scope)) {
            // 配列内の Scope がひとつでもあればサイドメニューに表示し、ページをレンダリングする
            return route.scope.some((scope) => getHasScope(scope));
          }
          return false;
        };
        // FeatureScope & Scope どちらも
        if (
          !isNullOrUndefined(route.featureScope) &&
          !isNullOrUndefined(route.scope)
        ) {
          if (checkFeatureScope() && checkScope()) {
            return route;
          }
          return null;
        } else {
          // FeatureScope のみ
          if (!isNullOrUndefined(route.featureScope) && checkFeatureScope()) {
            return route;
          }
          // Scope のみ
          if (!isNullOrUndefined(route.scope) && checkScope()) {
            return route;
          }
          return null;
        }
        return null;
      })
      .filter((route) => !isNullOrUndefined(route));
  }, [
    getHasScope,
    getHasFeatureScope,
    type,
    hasDescribeEnvironmentAreaMapScope,
  ]);

  return routes as RouteInfoType[];
};
