import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

const ProbeNotGenerated: React.FC = () => (
  <Box
    display="flex"
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Typography color="error" align="center">
      <Trans i18nKey="driving_history.attention.not_probe_data_generated" />
    </Typography>
  </Box>
);

export default React.memo(ProbeNotGenerated);
