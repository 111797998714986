import type { APIState } from '@api';
import { Failure, Success, maintenanceAPI } from '@api';
import type { MaintenanceEvent } from '@data/maintenance';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * メンテナンス情報取得API
 */
export const useMaintenanceEvents = (): {
  state: APIState;
  getMaintenanceEvents: () => Promise<MaintenanceEvent[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const { t } = useTranslation();

  const request = useCallback(async () => {
    try {
      let results = [];
      const now = DateTime.now();
      const params = new URLSearchParams();
      params.append('service_name', 'auth');
      params.append('service_name', 'fms-api');
      params.append('service_name', 'fms-console');
      params.append('service_name', 'tier-iv-account');
      params.append('service_name', 'software-update');

      // 現在メンテナンス中のイベントを取得
      params.append('valid_at', now.toJSDate().toISOString());
      const progressRes = await maintenanceAPI.get(
        `/maintenance_events?${params.toString()}`,
      );
      if (!isNullOrUndefined(progressRes.data.maintenance_event_list)) {
        results = progressRes.data.maintenance_event_list;
      }

      // 予定を取得
      params.delete('valid_at');
      params.append('start_from', now.toJSDate().toISOString());
      params.append(
        'start_to',
        now.plus({ weeks: 1 }).toJSDate().toISOString(),
      );
      const planRes = await maintenanceAPI.get(
        `/maintenance_events?${params.toString()}`,
      );
      if (!isNullOrUndefined(planRes.data.maintenance_event_list)) {
        results = [...results, ...planRes.data.maintenance_event_list];
      }
      return new Success(results);
    } catch (error) {
      return new Failure((error as AxiosError).response as AxiosResponse);
    }
  }, []);

  const getMaintenanceEvents = useCallback(async () => {
    setState('loading');
    const res = await request();
    if (!res.value || res.isFailure()) {
      notifyError({
        message: `${t('api.maintenance.get_maintenance_events', {
          status: 'failed',
        })}`,
      });
      setState('hasError');
      return [];
    }
    setState('hasValue');
    return res.value as MaintenanceEvent[];
  }, [request, notifyError, t]);

  return {
    state,
    getMaintenanceEvents,
  };
};
