import { Box, IconButton, styled } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { MediaClient } from '@tier4/webauto-media-client';
import { useMount, usePrevious, useUnmount } from 'react-use';
import { usePostAuthorizationAPI } from '@api/auth';
import type { RemoteCamera } from '@tier4/webauto-media-client/dist/RemoteDevices';
import { RemoteMedia, RemoteOperation, RemoteViz } from '..';
import { useRemoteCamera } from '@api/media';
import { drawerWidth } from '@components/common/templates/Layout';
import { headerHeightSelector } from '@data/maintenance';
import * as vehicleUtils from '@data/fms/vehicle/utils';
import { projectAtom } from '@data/auth';
import {
  connectedVehiclesSelector,
  remoteMonitorConfigAtom,
} from '@data/fms/vehicle/states';
import { useAtom, useAtomValue } from 'jotai';

export type RemoteSelectedView = 'media' | 'viz';

const RemoteMonitor: React.FC = () => {
  const project = useAtomValue(projectAtom);
  const getToken = usePostAuthorizationAPI();
  const { getCameras } = useRemoteCamera();
  const [cameras, setCameras] = useState<RemoteCamera[]>([]);
  const [remoteMonitorConfig, setRemoteMonitorConfig] = useAtom(
    remoteMonitorConfigAtom,
  );
  const [displayViz, setDisplayViz] = useState(true);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const connectedVehicles = useAtomValue(connectedVehiclesSelector(false));
  const headerHeight = useAtomValue(headerHeightSelector);

  const selectedVehicle = useMemo(() => {
    const findVehicle = connectedVehicles.find(
      (v) => v.vehicle_id === remoteMonitorConfig.vehicle?.vehicle_id,
    );
    if (isNullOrUndefined(findVehicle)) return remoteMonitorConfig.vehicle!;
    return findVehicle;
  }, [remoteMonitorConfig, connectedVehicles]);

  const isAutowareLaunched = useMemo(() => {
    return (
      !vehicleUtils.isConnected(selectedVehicle) &&
      !vehicleUtils.isDisconnected(selectedVehicle) &&
      !vehicleUtils.isShutdown(selectedVehicle)
    );
  }, [selectedVehicle]);

  const [selectedView, setSelectedView] = useState<RemoteSelectedView>(
    isAutowareLaunched ? 'viz' : 'media',
  );

  const isActiveViz = useMemo(
    () =>
      vehicleUtils.isActiveVisualization(selectedVehicle.visualization_status),
    [selectedVehicle],
  );

  const prevIsActiveViz = usePrevious(isActiveViz);

  const handleClickClose = useCallback(() => {
    setRemoteMonitorConfig({
      open: false,
      vehicle: null,
      monitorType: null,
    });
  }, [setRemoteMonitorConfig]);

  const handleChangeView = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedView(e.target.value as RemoteSelectedView);
    },
    [],
  );

  const handleChangeDisplayViz = useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      const target = e.target as HTMLInputElement;
      setDisplayViz(target.checked);
    },
    [],
  );

  const connectWebsocket = useCallback(() => {
    (async () => {
      const token = await getToken();
      if (isNullOrUndefined(token)) return;
      const socket = new WebSocket(
        `wss://${import.meta.env.VITE_DRIVE_API_DOMAIN}/${
          import.meta.env.VITE_DRIVE_API_VERSION
        }/control/connect?access_token=${token.accessToken}`,
      );
      if (!isNullOrUndefined(socket)) {
        socket.addEventListener('open', () => {
          setWs(socket);
        });
      } else {
        setWs(null);
      }
    })();
  }, [getToken]);

  /**
   * マウント時
   */
  useMount(async () => {
    const token = await getToken();
    if (isNullOrUndefined(token)) return;
    MediaClient.addAuthTokenCallback(async () => ({
      token: token.accessToken,
      expiredAt: new Date(token.expiry * 1000),
    }));
    if (isNullOrUndefined(selectedVehicle)) return;
    const camerasRes = await getCameras(selectedVehicle.vehicle_id);
    setCameras(camerasRes);
    // Visualization が有効でない or Autoware が起動していない場合は中断
    if (!isActiveViz || !isAutowareLaunched) return;
    connectWebsocket();
  });

  useEffect(() => {
    if (!isAutowareLaunched) return;

    // Viz が有効 -> 無効 となった場合
    if (prevIsActiveViz && !isActiveViz) {
      if (!isNullOrUndefined(ws)) {
        ws.close();
        setWs(null);
      }
    }
    // Viz が無効 -> 有効 となった場合
    if (!prevIsActiveViz && isActiveViz) {
      connectWebsocket();
    }
  }, [prevIsActiveViz, isActiveViz, ws, connectWebsocket, isAutowareLaunched]);

  /**
   * アンマウント時
   */
  useUnmount(() => {
    if (!isNullOrUndefined(ws)) {
      ws.close();
      setWs(null);
    }
    setRemoteMonitorConfig({
      open: false,
      vehicle: null,
      monitorType: null,
    });
  });

  if (
    !remoteMonitorConfig.open ||
    isNullOrUndefined(remoteMonitorConfig.vehicle)
  )
    return null;

  return (
    <Wrapper
      height={`calc(100vh - ${headerHeight}px)`}
      sx={{
        left: { xs: 0, xl: `${drawerWidth}px` },
        width: { xs: '100%', xl: `calc(100% - ${drawerWidth}px)` },
        top: headerHeight,
      }}
    >
      <View>
        <Box width="100%" position="relative">
          <SwitchView
            sx={{ display: selectedView === 'viz' ? 'block' : 'none' }}
          >
            <RemoteViz
              ws={ws}
              vehicle={selectedVehicle}
              projectId={project!.id}
              displayViz={displayViz}
              isActiveViz={isActiveViz}
              isAutowareLaunced={isAutowareLaunched}
            />
          </SwitchView>
          <SwitchView
            sx={{ display: selectedView === 'media' ? 'block' : 'none' }}
          >
            <RemoteMedia cameras={cameras} />
          </SwitchView>
        </Box>
        <Box width={200}>
          <RemoteOperation
            view={selectedView}
            vehicle={selectedVehicle}
            displayViz={displayViz}
            isActiveViz={isActiveViz}
            isAutowareLaunched={isAutowareLaunched}
            onChangeView={handleChangeView}
            onChangeDisplayViz={handleChangeDisplayViz}
          />
        </Box>
      </View>
      <CloseButton onClick={handleClickClose}>
        <KeyboardArrowLeft data-testid="vehicleDetails-back-btn" />
      </CloseButton>
    </Wrapper>
  );
};

export default RemoteMonitor;

const Wrapper = styled(Box)`
  width: 100%;
  background-color: black;
  position: absolute;
  left: 0;
  z-index: 5;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 200;
  background-color: rgba(255, 255, 255, 1);
  top: 15px;
  left: 15px;
  color: black;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  span {
    transform: scale(1.5);
  }
`;

const View = styled(Box)`
  min-width: 1440px;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SwitchView = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints.values.fhd}px) {
        display: block !important;
        width: 50%;

        &:last-of-type {
          left: 50%;
        }
      }
    `;
  }}
`;
