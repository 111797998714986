import { useVehiclesDrivenAPI } from '@api/datahub/getVehiclesDriven';
import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import type { VehicleDriven } from './types';
import { atomFamily } from 'jotai/utils';

export const vehiclesDrivenAtom = atom<VehicleDriven[]>([]);

export const findVehicleDrivenSelectorFamily = atomFamily((vehicleId: string) =>
  atom((get) => {
    const vehicles = get(vehiclesDrivenAtom);
    return vehicles.find((vehicle) => vehicle.vehicle_id === vehicleId);
  }),
);

export const useVehiclesDriven = () => {
  const { getVehiclesDriven } = useVehiclesDrivenAPI();
  const [vehiclesDriven, setVehiclesDriven] = useAtom(vehiclesDrivenAtom);

  const loadVehiclesDriven = useCallback(async () => {
    const res = await getVehiclesDriven();
    setVehiclesDriven(res);
  }, [getVehiclesDriven, setVehiclesDriven]);

  return {
    loadVehiclesDriven,
    vehiclesDriven,
  };
};
