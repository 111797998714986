import { useCallback, useState } from 'react';
import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useTranslation } from 'react-i18next';
import type { Probe } from '@data/datahub/probe/types';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useAtomCallback } from 'jotai/utils';

type APIResponse = {
  probes: Probe[];
  page_size: number;
  next_page_token?: string;
};

const useRequest = () => {
  const { datahubAPI } = useDatahubAPI({ cancelWhenUnmounting: true });

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse, AxiosResponse | AxiosError>> => {
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/probes?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return axios.isCancel(error)
          ? new Success({
              probes: [],
              page_size: 0,
            })
          : new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  return request;
};

export const useProbesAPI = (): {
  loading: boolean;
  probeDatas: Probe[];
  getProbes: (vehicleId: string, params: URLSearchParams) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const [probeDatas, setProbeDatas] = useState<Probe[]>([]);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const request = useRequest();

  const getProbes = useAtomCallback(
    useCallback(
      async (get, _, vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = get(projectAtom);
        const environment = get(environmentAtom);

        setProbeDatas([]);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_probes', { status: 'failed' }),
          });
          setLoading(false);
          return;
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (): Promise<void> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            // リクエストに失敗した場合
            notifyError({
              message: `${t('api.datahub.get_probes', {
                status: 'failed',
              })}: ${getErrorMessage(
                res as Result<APIResponse, AxiosResponse>,
              )}`,
            });
            setLoading(false);
            return;
          }
          // 配列を結合
          setProbeDatas((prevState) => prevState.concat(res.value.probes));
          if (res.value.next_page_token) {
            // レスポンスに next_page がある場合は次のリクエストを行う
            if (params) {
              params.set('page_token', res.value.next_page_token);
            }
            await splitRequest();
          }
        };

        await splitRequest();

        setLoading(false);
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    loading,
    probeDatas,
    getProbes,
  };
};

export const useSyncProbesAPI = (): {
  loading: boolean;
  getSyncProbes: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<Probe[]>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const request = useRequest();

  const getSyncProbes = useAtomCallback(
    useCallback(
      async (get, _, vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = get(projectAtom);
        const environment = get(environmentAtom);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_probes', { status: 'failed' }),
          });
          setLoading(false);
          return [];
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (data: Probe[] = []): Promise<Probe[]> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            // リクエストに失敗した場合
            notifyError({
              message: `${t('api.datahub.get_probes', {
                status: 'failed',
              })}: ${getErrorMessage(
                res as Result<APIResponse, AxiosResponse>,
              )}`,
            });
            setLoading(false);
            return data;
          }
          if (res.isSuccess()) {
            // 配列を結合
            const combinedData = [...data, ...res.value.probes];
            if (isNullOrUndefined(res.value.next_page_token)) {
              return combinedData;
            }
            // レスポンスに next_page がある場合は次のリクエストを行う
            params.set('page_token', res.value.next_page_token);
            return await splitRequest(combinedData);
          }
          return data;
        };

        const data = await splitRequest();
        setLoading(false);
        return data;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    loading,
    getSyncProbes,
  };
};
