import {
  useEnvironmentAPI,
  useEnvironmentAreaMapVersionAPI,
  useLaneletsCenterLinePointsAPI,
} from '@api/fms';
import { useAtomCallback, useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { areaMapVersionsAtom } from '../areamap/states';
import { environmentAtom } from '../environment/states';
import {
  laneletsCenterLinePointsAtom,
  laneletsCenterLinePointsLoadingAtom,
} from './states';

export const useLaneletsCenterPoints = () => {
  const { getEnvironment } = useEnvironmentAPI();
  const { getEnvironmentAreaMapVersion } = useEnvironmentAreaMapVersionAPI();
  const { getLaneletsCenterLinePoints } = useLaneletsCenterLinePointsAPI();
  const resetLaneletsCenterLinePoints = useResetAtom(
    laneletsCenterLinePointsAtom,
  );

  const updateToLatestLanelets = useAtomCallback(
    useCallback(
      async (get, set) => {
        set(laneletsCenterLinePointsLoadingAtom, true);
        const environment = get(environmentAtom);
        if (!environment) {
          set(laneletsCenterLinePointsLoadingAtom, false);
          return;
        }

        // 走行環境情報取得
        const environmentRes = await getEnvironment(environment.environment_id);
        // 走行環境情報を取得できなかった場合は中断
        if (!environmentRes) {
          set(laneletsCenterLinePointsLoadingAtom, false);
          return;
        }
        if (
          // 走行環境のエリアマップバージョンが変更されていた場合は atom を更新
          environment.area_map_version_id !== environmentRes.area_map_version_id
        ) {
          set(environmentAtom, environmentRes);
        }
        const laneletsCenterLinePoints = get(laneletsCenterLinePointsAtom);
        // 既にレーン情報取得済み && 取得前のエリアマップバージョンIDが同じは中断
        if (
          laneletsCenterLinePoints.lanelets_center_line_points.length &&
          laneletsCenterLinePoints.area_map_version_id ===
            environmentRes.area_map_version_id
        ) {
          set(laneletsCenterLinePointsLoadingAtom, false);
          return;
        }
        // エリアマップバージョン情報を取得
        const areaMapVersionRes = await getEnvironmentAreaMapVersion(
          environment.area_map_version_id,
        );
        // エリアマップバージョン情報を取得できなかった場合は中断
        if (!areaMapVersionRes) {
          set(laneletsCenterLinePointsLoadingAtom, false);
          return;
        }
        set(areaMapVersionsAtom, (prev) =>
          prev.map((areaMapVersion) =>
            areaMapVersionRes.version_id === areaMapVersion.version_id
              ? areaMapVersionRes
              : areaMapVersion,
          ),
        );
        // エリアマップバージョンのレーン情報が作成済みでない場合は中断
        if (
          areaMapVersionRes.lanelets_center_line_points_status !== 'created'
        ) {
          resetLaneletsCenterLinePoints();
          set(laneletsCenterLinePointsLoadingAtom, false);
          return;
        }
        // レーン情報を取得
        const laneletsCenterPointsRes = await getLaneletsCenterLinePoints();
        if (laneletsCenterPointsRes) {
          set(laneletsCenterLinePointsAtom, laneletsCenterPointsRes);
        } else {
          resetLaneletsCenterLinePoints();
        }
        set(laneletsCenterLinePointsLoadingAtom, false);
      },
      [
        getEnvironment,
        getEnvironmentAreaMapVersion,
        getLaneletsCenterLinePoints,
        resetLaneletsCenterLinePoints,
      ],
    ),
  );

  return {
    updateToLatestLanelets,
  };
};
