import { Box, styled } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

type Props = LinkProps & {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  fontSize?: string;
};

const LinkButton: React.FC<Props> = ({
  startIcon,
  endIcon,
  fontSize = '0.875rem',
  children,
  ...args
}) => {
  return (
    <Link {...args}>
      {!isNullOrUndefined(startIcon) && (
        <Box display="flex" mr={2}>
          {startIcon}
        </Box>
      )}
      <Text fontSize={fontSize}>{children}</Text>
      {!isNullOrUndefined(endIcon) && (
        <Box display="flex" ml={2}>
          {endIcon}
        </Box>
      )}
    </Link>
  );
};

export default React.memo(LinkButton);

const Link = styled(RouterLink)`
  display: inline-flex;
  align-items: center;
  line-height: 1;
  color: ${({ theme }) => theme.palette.primary.main};
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

type TextProps = {
  fontSize: string;
};

const Text = styled('span')<TextProps>`
  font-size: ${({ fontSize }: TextProps) => fontSize};
  font-weight: 600;
`;
