import type { Settings } from './types';

export const DEFAULT_SETTINGS: Settings = {
  alert: {
    autoClose: false,
    mute: false,
    volume: 0.5,
  },
  vehicle: { alwaysCenter: true, displayRoute: true },
};

export const SETTINGS_STORAGE_KEY_NAME = 'settings';
