import type { ScopeFilterType } from '@data/auth';
import { useHasFeatureScope, useHasScope } from '@data/auth';
import React, { useMemo } from 'react';

type ToggleProps = Partial<{
  enabled: boolean;
  children: React.ReactNode;
}>;

// 権限がある場合に表示
export const ToggleOn: React.FC<ToggleProps> = ({
  enabled,
  children,
}: ToggleProps) => <>{enabled && children}</>;

// 権限がない場合に表示
export const ToggleOff: React.FC<ToggleProps> = ({
  enabled,
  children,
}: ToggleProps) => <>{!enabled && children}</>;

type Props = {
  type?: 'scope' | 'featureScope';
  scope: string | string[];
  filter?: ScopeFilterType;
  children?: React.ReactNode;
};

const FeatureToggle: React.FC<Props> = ({
  type = 'scope',
  scope,
  filter = 'and',
  children,
}: Props) => {
  const getHasScope = useHasScope();
  const getHasFeatureScope = useHasFeatureScope();

  const toggleChildren = useMemo(
    () =>
      React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<ToggleProps>, {
          enabled:
            type === 'scope'
              ? getHasScope(scope, filter)
              : getHasFeatureScope(scope, filter),
        }),
      ),
    [children, getHasScope, getHasFeatureScope, scope, type, filter],
  );

  return <>{toggleChildren}</>;
};

export default React.memo(FeatureToggle);
