import type { SelectedHistoryData } from '@components/pages/DrivingHistory/types';
import type { Probe } from '@data/datahub/probe/types';
import type { PeriodTimestamp } from '@data/datahub/types';
import { colors } from '@mui/material';
import { Polyline } from '@react-google-maps/api';
import React from 'react';
import { useOverridePolyline } from './hooks';

type Props = {
  probeData: Probe[];
  selectedManualOverride: SelectedHistoryData<PeriodTimestamp>;
};

const OverridePolyline: React.FC<Props> = ({
  probeData,
  selectedManualOverride,
}: Props) => {
  const { path } = useOverridePolyline(probeData, selectedManualOverride);

  return (
    <Polyline
      path={path}
      options={{
        strokeColor: colors.red[500],
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1000,
      }}
    />
  );
};

export default React.memo(OverridePolyline);
