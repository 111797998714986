import type { LatLng } from '@data/fms/place/types';
import type { TaskRoute } from '@data/fms/route/types';
import { colors } from '@mui/material';
import { Polyline } from '@react-google-maps/api';
import React, { useMemo } from 'react';

type Props = {
  taskRoute: TaskRoute;
};

const ActiveScheduleRoutePolyline: React.FC<Props> = ({ taskRoute }: Props) => {
  const strokeColor = useMemo(() => {
    if (taskRoute.status === 'todo') return '#35a9b0';
    if (taskRoute.status === 'doing') return '#0071b0';
    if (taskRoute.status === 'done') return '#02005b';
    return colors.grey[300];
  }, [taskRoute.status]);

  return (
    <>
      {taskRoute.routes.map((route, i) => {
        if (!route) return null;
        const locations: LatLng[] = route.points.map((point) => point.location);
        return (
          <Polyline
            key={i}
            path={locations}
            options={{
              strokeColor,
              strokeOpacity: 1.0,
              strokeWeight: 4,
              clickable: false,
              zIndex: 100,
            }}
          />
        );
      })}
    </>
  );
};

export default React.memo(ActiveScheduleRoutePolyline);
