import { mapMenuAtom } from '@data/ui/states';
import { laneletsCenterLinePointsLoadingAtom } from '@data/fms/lanelet/states';
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';

const LaneletsCenterPointsMenu: React.FC = () => {
  const { t } = useTranslation();
  const [mapMenu, setMapMenu] = useAtom(mapMenuAtom);
  const laneletsCenterLinePointsLoading = useAtomValue(
    laneletsCenterLinePointsLoadingAtom,
  );

  const handleChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMapMenu((prevState) => ({
        ...prevState,
        laneletsCenterPoints: e.target.checked,
      }));
    },
    [setMapMenu],
  );

  return (
    <>
      <Divider />
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={mapMenu.laneletsCenterPoints}
              size="small"
              disabled={laneletsCenterLinePointsLoading}
              onChange={handleChangeCheckbox}
              data-testid="lanelets_center_points"
            />
          }
          label={t('map_menu.lanelets_center_points')}
        />
        {laneletsCenterLinePointsLoading && (
          <CircularProgress size={12} thickness={4} />
        )}
      </Stack>
    </>
  );
};

export default LaneletsCenterPointsMenu;
