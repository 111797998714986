import { authenticationManager } from '@tier4/webauto-auth-frontend-client';

const LOGOUT_URL = `${import.meta.env.VITE_OIDC_REDIRECT_URL}/logout.html`;

const AuthenticationManager = authenticationManager({
  authority: import.meta.env.VITE_OIDC_PROVIDER_ENDPOINT,
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
  client_secret: import.meta.env.VITE_OIDC_CLIENT_SECRET,
  redirect_uri: import.meta.env.VITE_OIDC_REDIRECT_URL,
  post_logout_redirect_uri: LOGOUT_URL,
  tokenServiceAPIUrl: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}`,
  response_type: 'code',
  scope: 'openid profile email offline_access',
  automaticSilentRenew: true,
  revokeTokensOnSignout: true,
  logLevel: import.meta.env.VITE_OIDC_LOG_LEVEL,
  logMethod: {
    ...console,
    error(errorMessage: string) {
      console.log(errorMessage);
    },
  },
  afterSignOutPropagation: () => {
    window.location.href = LOGOUT_URL;
  },
});

export default AuthenticationManager;
