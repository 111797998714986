import type { LinearProgressProps } from '@mui/material';
import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

type Props = LinearProgressProps & {
  ratio: number;
};

const LinearProgressWithLabel: React.FC<Props> = (props) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.ratio} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(props.ratio)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(LinearProgressWithLabel);
