import { hasCurrentAreaMapVersionLaneletsSelector } from '@data/fms/areamap/states';
import { usePlaces } from '@data/fms/place/hooks';
import type { Place } from '@data/fms/place/types';
import { mapMenuAtom } from '@data/ui/states';
import { FeatureToggle, ToggleOn } from '@components/common/atoms';
import { SCOPES } from '@data/auth';
import styled from '@emotion/styled';
import { Box, Button, Popover } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LaneletsCenterPointsMenu from './LaneletsCenterPointsMenu';
import NoEntryZonesMenu from './NoEntryZonesMenu';
import StopPointsMenu from './StopPointsMenu';
import { useAtomValue, useSetAtom } from 'jotai';

type Props = {
  places?: Place[];
  showStopPointsMenu?: boolean;
  showNoEntryLaneMenu?: boolean;
  showLaneletsCenterPointsMenu?: boolean;
};

const MapMenu: React.FC<Props> = ({
  places,
  showStopPointsMenu = true,
  showNoEntryLaneMenu = true,
  showLaneletsCenterPointsMenu = true,
}) => {
  const { t } = useTranslation();
  const { places: defaultPlaces } = usePlaces();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const hasCurrentAreaMapVersionLanelets = useAtomValue(
    hasCurrentAreaMapVersionLaneletsSelector,
  );
  const setMapMenu = useSetAtom(mapMenuAtom);

  const displayPlaces = useMemo(
    () => (isNullOrUndefined(places) ? defaultPlaces : places),
    [defaultPlaces, places],
  );

  const handleClickOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    setMapMenu((prevState) => {
      return {
        ...prevState,
        stopPointNames: Object.fromEntries(
          displayPlaces.map((place) => [place.point_id, true]),
        ),
      };
    });
  }, [displayPlaces, setMapMenu]);

  if (!showStopPointsMenu && !showNoEntryLaneMenu) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <OpenButton
          color="primary"
          onClick={handleClickOpen}
          data-testid="display_change_btn"
        >
          {t('map_menu.title')}
        </OpenButton>
      </Wrapper>
      <Popover
        open={!isNullOrUndefined(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box py={2} px={4} maxWidth={600} sx={{ userSelect: 'none' }}>
          {showStopPointsMenu && <StopPointsMenu />}
          {hasCurrentAreaMapVersionLanelets && showLaneletsCenterPointsMenu && (
            <FeatureToggle scope={SCOPES.DescribeLaneletsCenterLinePoints}>
              <ToggleOn>
                <LaneletsCenterPointsMenu />
              </ToggleOn>
            </FeatureToggle>
          )}
          {showNoEntryLaneMenu && <NoEntryZonesMenu />}
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(MapMenu);

const OpenButton = styled(Button)`
  background-color: white;
  color: black;
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;

  &:hover {
    background-color: white;
  }
`;

const Wrapper = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
