import React from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography, Box, styled } from '@mui/material';
import type { CircularProgressProps } from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import isNullOrUndefined from '@utils/isNullOrUndefined';

type Props = CircularProgressProps & {
  ratio: number | null;
};

const CircularProgressWithLabel: React.FC<Props> = (props) => (
  <ProgressBox>
    <Progress {...props} />
    {!isNullOrUndefined(props.ratio) && (
      <TextBox>
        <Text>{Math.round(props.ratio)}%</Text>
      </TextBox>
    )}
  </ProgressBox>
);

export default React.memo(CircularProgressWithLabel);

const ProgressBox = styled(Box)`
  position: relative;
  display: inline-flex;
`;

const TextBox = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled(Typography)`
  font-size: 0.7rem;
`;

Text.defaultProps = {
  component: 'div',
  color: 'textSecondary',
} as TypographyProps;

const Progress = styled(CircularProgress)`
  position: relative;
`;
