import { Box, colors, Grid, Icon, IconButton, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import DataCell, { DataCellBox } from '../../atoms/DataCell';
import { useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import { DateTime } from 'luxon';
import { Minus } from 'react-feather';
import type { LogFile } from '@data/datahub/log/types';
import { convertBytesToMb } from '@utils/file';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const dateText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('yyyy/MM/dd');
  }, [date]);

  const timeText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('HH:mm:ss');
  }, [date]);

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {dateText}
      </Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type Props = {
  files: LogFile[];
  height: number;
  isPeriod: boolean;
};

const LogFileList: React.FC<Props> = ({ files, isPeriod, height }) => {
  const { t } = useTranslation();
  const { concat } = useConcatWord();

  const columns = useMemo(
    () => [
      // 562
      {
        label: 'No',
        dataKey: 'no',
        width: 56,
      },
      {
        label: concat([t('common.action.record'), t('common.date.time')]),
        dataKey: 'record_time',
        width: 256,
      },
      {
        label: t('common.action.download'),
        dataKey: 'download',
        width: 250,
      },
    ],
    [t, concat],
  );

  const headerRenderer = useCallback(
    ({ label }: { label: React.ReactNode }) => {
      return (
        <DataCell variant="head" align="left" component="div">
          <span>{label}</span>
        </DataCell>
      );
    },
    [],
  );

  const cellRenderer = useCallback(
    ({
      dataKey,
      rowData,
      rowIndex,
    }: {
      dataKey: string;
      rowData: LogFile;
      rowIndex: number;
    }) => {
      const getCellData = () => {
        if (dataKey === 'no') return <DataCellBox>{rowIndex + 1}</DataCellBox>;
        if (dataKey === 'record_time') {
          return (
            <DataCellBox>
              <DataCellBox>
                <Date date={rowData.record_start} />
                {isPeriod && (
                  <>
                    <Box mx={2} mt={6.5}>
                      <Minus size={14} color={colors.grey[500]} />
                    </Box>
                    <Date date={rowData.record_end} />
                  </>
                )}
              </DataCellBox>
            </DataCellBox>
          );
        }
        if (dataKey === 'download') {
          return (
            <DataCellBox>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton
                    size="small"
                    color="primary"
                    data-index={rowIndex}
                    href={rowData.download_url}
                    target="_blank"
                    data-testid="download_icon"
                  >
                    <Icon>get_app</Icon>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography>
                    {convertBytesToMb(rowData.file_size)} MB
                  </Typography>
                </Grid>
              </Grid>
            </DataCellBox>
          );
        }
      };

      return (
        <DataCell component="div" sx={{ height: 76 }}>
          {getCellData()}
        </DataCell>
      );
    },
    [isPeriod],
  );

  return (
    <Box sx={{ width: '100%', height: height }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={45}
            rowCount={files.length}
            rowGetter={({ index }) => files[index]}
            rowHeight={76}
            columns={files}
          >
            {columns.map(({ dataKey, ...other }) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    label: headerProps.label,
                  })
                }
                cellRenderer={({ dataKey, rowData, rowIndex }) =>
                  cellRenderer({
                    dataKey,
                    rowData,
                    rowIndex,
                  })
                }
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(LogFileList);
