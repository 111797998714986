import type { ButtonProps } from '@mui/material';
import { Box, Button, CircularProgress, Tooltip, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 200,
    textAlign: 'center',
  },
});

type Props = ButtonProps &
  Partial<{
    progress: boolean;
    tooltipTitle: string | React.ReactNode;
  }>;

const ProgressButton: React.FC<Props> = ({
  progress = false,
  tooltipTitle = undefined,
  disabled,
  ...rest
}: Props) => {
  const classes = useStyles();

  const button = useMemo(
    () =>
      isNullOrUndefined(tooltipTitle) || disabled ? (
        <Button disabled={disabled} {...rest} />
      ) : (
        <Tooltip
          arrow
          title={tooltipTitle}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button disabled={disabled} {...rest} />
        </Tooltip>
      ),
    [tooltipTitle, rest, classes.tooltip, disabled],
  );

  return (
    <WrapperBox>
      {button}
      {progress && <Progress size={24} />}
    </WrapperBox>
  );
};

export default React.memo(ProgressButton);

const WrapperBox = styled(Box)`
  position: relative;
`;

const Progress = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;
