import { Box, TableCell, styled } from '@mui/material';

const DataCell = styled(TableCell)`
  display: block;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export default DataCell;

export const DataCellBox = styled(Box)`
  display: flex;
  height: 100%;
  align-items: center;
`;
