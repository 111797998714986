import React from 'react';
import {
  FormControl,
  Input as MuiInput,
  InputAdornment,
  styled,
} from '@mui/material';
import { SearchRounded } from '@mui/icons-material';

type Props = {
  placeholder?: string;
  dataAutomationId?: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
};

const Search: React.FC<Props> = ({
  placeholder = '',
  dataAutomationId = '',
  onChange,
}: Props) => {
  return (
    <Wrapper>
      <Input
        onChange={onChange}
        placeholder={placeholder}
        inputProps={
          {
            'data-testid': dataAutomationId,
          } as React.InputHTMLAttributes<HTMLInputElement>
        }
        startAdornment={
          <InputAdornment position="start">
            <SearchRounded color="disabled" />
          </InputAdornment>
        }
        data-testid="search"
      />
    </Wrapper>
  );
};

export default React.memo(Search);

const Wrapper = styled(FormControl)`
  position: relative;
  width: 100%;
`;

const Input = styled(MuiInput)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
`;
