import VehicleInfoWindow from '@components/common/molecules/VehicleInfoWindow';
import { Marker } from '@react-google-maps/api';
import React, { useCallback, useMemo } from 'react';
import DrivingImageSrc from '/assets/img/maps/vehicle_driving.png';
import ReadyImageSrc from '/assets/img/maps/vehicle_ready.png';
import AbnormalImageSrc from '/assets/img/maps/vehicle_abnormal.png';
import PauseImageSrc from '/assets/img/maps/vehicle_pause.png';
import type { Vehicle } from '@data/fms/vehicle/types';
import {
  isConnected,
  isDisconnected,
  isShutdown,
} from '@data/fms/vehicle/utils';
import { useStoredVehicleId } from '@data/fms/vehicle/hooks';

const DrivingImage = new Image();
DrivingImage.src = DrivingImageSrc as unknown as string;
const ReadyImage = new Image();
ReadyImage.src = ReadyImageSrc as unknown as string;
const AbnormalImage = new Image();
AbnormalImage.src = AbnormalImageSrc as unknown as string;
const PauseImage = new Image();
PauseImage.src = PauseImageSrc as unknown as string;

type Props = {
  vehicle: Vehicle;
  isDetail?: boolean;
};

const SIZE = 42;
const CENTER = SIZE * 0.5;

const VehicleMarker: React.FC<Props> = ({
  vehicle,
  isDetail = false,
}: Props) => {
  const { storedVehicleId, setStoredVehicleId } = useStoredVehicleId();

  const markerImage = useMemo(() => {
    const { status, heading } = vehicle.telemetry;
    let img = DrivingImage;
    if (status === 'ready') {
      img = ReadyImage;
    }
    if (status === 'abnormal') {
      img = AbnormalImage;
    }
    if (status === 'suspending' || status === 'stopping') {
      img = PauseImage;
    }
    const canvas = window.document.createElement('canvas');
    canvas.width = SIZE;
    canvas.height = SIZE;
    const context = canvas.getContext('2d');
    context?.clearRect(0, 0, SIZE, SIZE);
    context?.save();
    context?.translate(CENTER, CENTER);
    context?.rotate(heading);
    context?.translate(-CENTER, -CENTER);
    context?.drawImage(img, 0, 0, SIZE, SIZE);
    context?.restore();
    return canvas.toDataURL('image/png');
  }, [vehicle.telemetry]);

  const handleClickMarker = useCallback(() => {
    if (
      isDetail ||
      isDisconnected(vehicle) ||
      isConnected(vehicle) ||
      isShutdown(vehicle)
    )
      return;
    if (storedVehicleId !== vehicle.vehicle_id) {
      setStoredVehicleId(vehicle.vehicle_id);
      return;
    }
    setStoredVehicleId(undefined);
  }, [isDetail, vehicle, storedVehicleId, setStoredVehicleId]);

  if (!vehicle) return null;

  return (
    <>
      <Marker
        position={vehicle.telemetry.location}
        icon={{
          url: markerImage,
          scaledSize: new window.google.maps.Size(CENTER, CENTER),
          labelOrigin: new window.google.maps.Point(10, 32),
          anchor: new window.google.maps.Point(CENTER * 0.5, CENTER * 0.5),
        }}
        zIndex={1000}
        label={{
          text: vehicle.vehicle_name,
          fontSize: '16px',
        }}
        onClick={handleClickMarker}
      />
      {!isDetail && storedVehicleId === vehicle.vehicle_id && (
        <VehicleInfoWindow vehicle={vehicle} />
      )}
    </>
  );
};

export default React.memo(VehicleMarker);
