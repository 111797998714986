import type { TypographyProps } from '@mui/material';
import { Typography, styled } from '@mui/material';
import React from 'react';
import { css } from '@emotion/react';

type Props = TypographyProps & {
  size?: 'small' | 'medium';
};

// eslint-disable-next-line
const MinCaption = styled(({ size = 'medium', ...rest }: Props) => (
  <Typography {...rest} />
))`
  font-size: 11px;

  ${({ size }) => {
    if (size === 'small') {
      return css`
        font-size: 10px;
      `;
    }
    return '';
  }}
`;

export default React.memo(MinCaption);
