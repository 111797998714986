import { useCallback, useState } from 'react';
import type { APIState, Result } from '..';
import { Failure, Success, useAuthAPI } from '..';
import type { AxiosResponse, AxiosError } from 'axios';
import type { ProjectMember } from '@data/auth';
import getSplitColonId from '@utils/getSplitColonId';

type APIResponse = {
  members: ProjectMember[];
  next_page_token?: string;
};

/**
 * プロジェクトのメンバー一覧取得
 */
export const useGetProjectMembersAPI = (): {
  state: APIState;
  getProjectMembers: (projectId: string) => Promise<ProjectMember[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const authAPI = useAuthAPI();

  const request = useCallback(
    async (
      projectId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse, AxiosResponse>> => {
      try {
        const res = await authAPI.get(
          `/projects/${projectId}/members?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [authAPI],
  );

  const getProjectMembers = useCallback(
    async (projectId: string): Promise<ProjectMember[]> => {
      setState('loading');
      // 分割リクエスト
      // let count = 0;
      let members: ProjectMember[] = [];
      const params = new URLSearchParams();
      params.append('page_size', '100');

      const splitRequest = async (): Promise<'error' | 'success'> => {
        const res = await request(projectId, params);
        // 取得失敗時
        // 取得成功時
        if (!!res && res.isSuccess()) {
          // count += 1;
          members = members.concat(res.value.members);
          if (
            !!res.value.next_page_token &&
            res.value.next_page_token.length > 0
          ) {
            // レスポンスに next_page_token がある場合は次のリクエストを行う
            if (params) {
              params.set('page_token', res.value.next_page_token);
            }
            await splitRequest();
          }
        }
        //
        return 'success';
      };

      const splitRequestRes = await splitRequest();
      if (splitRequestRes === 'error') {
        setState('hasError');
        return [];
      }

      setState('hasValue');
      members.sort((a, b) =>
        Number(getSplitColonId(a.subject_id)) <
        Number(getSplitColonId(b.subject_id))
          ? 1
          : -1,
      );
      return members;
    },
    [request],
  );

  return { state, getProjectMembers };
};
