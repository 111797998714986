import { usePostAssertAPI } from '@api/auth';
import { useCreateEnvironmentAPI } from '@api/fms';
import {
  ProjectEnvironmentDialogList,
  ProjectEnvironmentDialogCreateEnvironment,
} from '@components/common/molecules';
import type { Project } from '@data/auth/projects';
import { projectAtom } from '@data/auth/projects';
import {
  environmentAtom,
  environmentsAtom,
} from '@data/fms/environment/states';
import type { Environment } from '@data/fms/environment/types';
import type { DialogProps as MuiDialogProps } from '@mui/material';
import { Dialog } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import store from 'store2';

export type SelectedTempState = {
  project: Project | null;
  environment: Environment | null;
};

export type CreateEnvironmentValues = {
  environmentName: string;
  environmentDescription: string;
  areaMapId: number | null;
  areaMapVersionId: string;
  progress: boolean;
};

type ProjectEnvironmentDialogProps = MuiDialogProps &
  Partial<{
    project: Project | null;
    environment: Environment | null;
    onClose: () => void;
    onInitialSelected: () => void;
  }>;

type DialogType =
  | 'list'
  | 'createEnvironmentInput'
  | 'createEnvironmentConfirm';

const ProjectEnvironmentDialog: React.FC<ProjectEnvironmentDialogProps> = ({
  project = null,
  environment = null,
  onClose,
  onInitialSelected,
  ...rest
}: ProjectEnvironmentDialogProps) => {
  const setProject = useSetAtom(projectAtom);
  const setEnvironment = useSetAtom(environmentAtom);
  const [tempSelect, setTempSelect] = useState<SelectedTempState>({
    project,
    environment,
  });
  const prevTempSelect = usePrevious(tempSelect);
  const setEnvironments = useSetAtom(environmentsAtom);
  const { postCreateEnvironment } = useCreateEnvironmentAPI();
  const [dialogType, setDialogType] = useState<DialogType>('list');
  const [createEnvironmentValues, setCreateEnvironmentValues] =
    useState<CreateEnvironmentValues>({
      environmentName: '',
      environmentDescription: '',
      areaMapId: null,
      areaMapVersionId: '',
      progress: false,
    });
  const { postAssertCreateEnvironment } = usePostAssertAPI();

  /**
   * プロジェクト選択時
   */
  const handleSelectProject = useCallback((project: Project) => {
    setTempSelect((prevState) => ({
      ...prevState,
      project,
    }));
  }, []);

  /**
   * 走行環境選択時
   */
  const handleSelectEnvironment = useCallback((environment: Environment) => {
    setTempSelect((prevState) => ({
      ...prevState,
      environment,
    }));
  }, []);

  /**
   * 走行環境作成ボタンクリック時
   */
  const handleCreateEnvironment = useCallback(() => {
    setCreateEnvironmentValues({
      environmentName: '',
      environmentDescription: '',
      areaMapId: null,
      areaMapVersionId: '',
      progress: false,
    });
    setDialogType('createEnvironmentInput');
  }, []);

  /**
   * リスト OKボタンクリック時
   */
  const handleClickListOk = useCallback(() => {
    setProject(tempSelect.project);
    setEnvironment(tempSelect.environment);
    store.local.set('environment', tempSelect);
    if (onClose) onClose();
    if (onInitialSelected) onInitialSelected();
  }, [onClose, onInitialSelected, setProject, setEnvironment, tempSelect]);

  /**
   * 走行環境作成時 入力情報更新
   */
  const handleUpdateEnvironmentValues = useCallback(
    (value: CreateEnvironmentValues) => {
      setCreateEnvironmentValues(value);
    },
    [],
  );

  /**
   * 走行環境作成時 戻るボタンクリック
   */
  const handleReturn = useCallback(() => {
    if (dialogType === 'createEnvironmentInput') {
      // 走行環境入力 -> リスト
      setDialogType('list');
      return;
    }
    // 走行環境確認 -> 走行環境入力
    setDialogType('createEnvironmentInput');
  }, [dialogType]);

  /**
   * 走行環境作成時 OKボタンクリック
   */
  const handleClickInputOk = useCallback(async () => {
    if (dialogType === 'createEnvironmentInput') {
      setDialogType('createEnvironmentConfirm');
      return;
    }
    // APIリクエスト
    if (
      createEnvironmentValues.environmentName.length > 0 &&
      !isNullOrUndefined(createEnvironmentValues.areaMapId) &&
      createEnvironmentValues.areaMapVersionId.length > 0
    ) {
      setCreateEnvironmentValues((prevState) => ({
        ...prevState,
        progress: true,
      }));
      const res = await postCreateEnvironment(tempSelect.project!.id, {
        environment_name: createEnvironmentValues.environmentName,
        description: createEnvironmentValues.environmentDescription,
        area_map_id: createEnvironmentValues.areaMapId,
        area_map_version_id: createEnvironmentValues.areaMapVersionId,
      });
      if (!isNullOrUndefined(res)) {
        // 登録成功時

        // 選択されている走行環境を作成されたものに変更
        setTempSelect((prevState) => ({
          ...prevState,
          environment: res,
        }));

        // 走行環境一覧を更新
        setEnvironments((prevState) =>
          // 昇順ソート
          [...prevState, res].sort((a: Environment, b: Environment) => {
            if (a.environment_name < b.environment_name) return -1;
            return 1;
          }),
        );

        // 入力情報をリセット
        setCreateEnvironmentValues({
          environmentName: '',
          environmentDescription: '',
          areaMapId: null,
          areaMapVersionId: '',
          progress: false,
        });
        // リスト画面へ
        setDialogType('list');
      }
    }
  }, [
    dialogType,
    createEnvironmentValues,
    postCreateEnvironment,
    setEnvironments,
    tempSelect.project,
  ]);

  /**
   * ダイアログクローズ
   */
  const handleClose = useCallback(() => {
    if (createEnvironmentValues.progress) return;
    if (onClose) onClose();
  }, [onClose, createEnvironmentValues.progress]);

  /**
   * ダイアログクローズ後の処理
   */
  const handleExited = useCallback(() => {
    setTempSelect({
      project,
      environment,
    });
    setDialogType('list');
    setCreateEnvironmentValues({
      environmentName: '',
      environmentDescription: '',
      areaMapId: null,
      areaMapVersionId: '',
      progress: false,
    });
  }, [project, environment]);

  /**
   * コンテンツ
   */
  const content = useMemo(() => {
    if (dialogType === 'list') {
      // プロジェクト & 走行環境選択
      return (
        <ProjectEnvironmentDialogList
          tempSelect={tempSelect}
          isInitialSelected={!isNullOrUndefined(onInitialSelected)}
          onCreateEnvironment={handleCreateEnvironment}
          onSelectProject={handleSelectProject}
          onSelectEnvironment={handleSelectEnvironment}
          onOk={handleClickListOk}
          onClose={handleClose}
        />
      );
    }
    if (
      dialogType === 'createEnvironmentInput' ||
      dialogType === 'createEnvironmentConfirm'
    ) {
      // 走行環境作成 - 入力 or 確認
      const isConfirm = dialogType === 'createEnvironmentConfirm';
      return (
        <ProjectEnvironmentDialogCreateEnvironment
          tempSelect={tempSelect}
          isConfirm={isConfirm}
          createEnvironmentValues={createEnvironmentValues}
          onUpdateEnvironmentValues={handleUpdateEnvironmentValues}
          onReturn={handleReturn}
          onOk={handleClickInputOk}
        />
      );
    }

    return null;
  }, [
    dialogType,
    tempSelect,
    handleSelectProject,
    handleSelectEnvironment,
    handleCreateEnvironment,
    handleClickListOk,
    handleReturn,
    handleClickInputOk,
    handleClose,
    onInitialSelected,
    createEnvironmentValues,
    handleUpdateEnvironmentValues,
  ]);

  useEffect(() => {
    // 走行環境作成権限があるかどうか確認
    const updateEnableCreateEnvironment = async () => {
      await postAssertCreateEnvironment(tempSelect.project!.id);
    };

    if (
      !isNullOrUndefined(tempSelect.project?.id) &&
      prevTempSelect?.project?.id !== tempSelect.project?.id
    ) {
      updateEnableCreateEnvironment();
    }
  }, [tempSelect, prevTempSelect, postAssertCreateEnvironment]);

  useEffect(() => {
    setTempSelect({
      project,
      environment,
    });
  }, [project, environment]);

  return (
    <Dialog
      maxWidth={false}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      {...rest}
    >
      {content}
    </Dialog>
  );
};

export default React.memo(ProjectEnvironmentDialog);
