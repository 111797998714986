import isNullOrUndefined from '@utils/isNullOrUndefined';
import store from 'store2';
import type {
  PlanActualVarianceLocalStorageKey,
  PlanActualVarianceLocalStorageValue,
} from './types';

export const getVarianceStorage = (key: PlanActualVarianceLocalStorageKey) => {
  const savedConfig = store.local.get('planActualVarianceConfig');
  if (isNullOrUndefined(savedConfig)) return null;
  return key ? savedConfig[key] : null;
};

export const setVarianceStorage = (
  key: PlanActualVarianceLocalStorageKey,
  value: PlanActualVarianceLocalStorageValue,
) => {
  const storageKey = 'planActualVarianceConfig';
  const savedConfig = store.local.get(storageKey);
  store.local.set(storageKey, {
    ...savedConfig,
    [key]: value,
  });
};
