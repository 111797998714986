import React, { useMemo } from 'react';
import { Marker } from '@react-google-maps/api';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { LatLng } from '@data/fms/place/types';

type Props = {
  latLng: LatLng;
  type: 'from' | 'to';
};

const FromToMarker: React.FC<Props> = ({ latLng, type }: Props) => {
  const { t, i18n } = useTranslation();

  const width = useMemo(() => {
    if (type === 'from' || i18n.language !== 'en-US') {
      return 50;
    }
    return 92;
  }, [type, i18n.language]);

  const placeType = useMemo(
    () => ({
      to: {
        label: t('common.fms.place.destination'),
        color: colors.lightBlue[800],
      },
      from: {
        label: t('common.fms.place.origin'),
        color: colors.orange[800],
      },
    }),
    [t],
  );

  const iconSvg = useMemo(
    () =>
      [
        `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="30" viewBox="0 0 ${width} 30">
          <g transform="translate(0 -192)">
            <rect width="${width}" height="24" rx="4" transform="translate(0 192)" fill="${
          placeType[type].color
        }"/>
            <path d="M6,0l6,7H0Z" transform="translate(${
              width * 0.5 + 5
            } 222) rotate(180)" fill="${placeType[type].color}"/>
            <text transform="translate(${
              width * 0.5
            } 208)" fill="#fff" font-size="11" letter-spacing="0.5" font-family="sans-serif" font-weight="600">
              <tspan x="0" y="0" text-anchor="middle">${
                placeType[type].label
              }</tspan>
            </text>
          </g>
        </svg>`,
      ].join('\n'),
    [type, placeType, width],
  );
  const icon = useMemo(
    () => ({
      url: `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(
        unescape(encodeURIComponent(iconSvg)),
      )}`,
      anchor: new window.google.maps.Point(width * 0.5, 30),
    }),
    [iconSvg, width],
  );

  return (
    <Marker zIndex={500} position={latLng} icon={icon} clickable={false} />
  );
};

export default React.memo(FromToMarker);
