import React, { useMemo } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  copyText: string;
  children: React.ReactElement;
};

const CopyButton: React.FC<Props> = ({ copyText, children }) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const { t } = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleClickCopyButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      copyToClipboard(copyText);
      setIsTooltipOpen(true);
      setTimeout(() => {
        setIsTooltipOpen(false);
      }, 1000);
    },
    [copyToClipboard, copyText],
  );
  const additionalProps = useMemo(
    () => ({ onClick: handleClickCopyButton }),
    [handleClickCopyButton],
  );
  const newChildren = useMemo(
    () => React.cloneElement(children, additionalProps),
    [children, additionalProps],
  );
  return (
    <Tooltip
      open={isTooltipOpen}
      title={t('common.status.copied') as string}
      placement="top"
      arrow
    >
      {newChildren}
    </Tooltip>
  );
};

export default React.memo(CopyButton);
