import { useAtom, useAtomValue } from 'jotai';
import type { SetStateAction } from 'jotai';
import { placesAtom, placesLatLngsSelector } from './states';
import type { LatLng, Place } from './types';

export const usePlaces = (): {
  places: Place[];
  setPlaces: SetAtom<[SetStateAction<Place[]>], void>;
  placesLatLngs: LatLng[];
} => {
  const [places, setPlaces] = useAtom(placesAtom);
  const placesLatLngs = useAtomValue(placesLatLngsSelector);
  return {
    places,
    setPlaces,
    placesLatLngs,
  };
};
