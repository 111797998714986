import { useWeatherForecastAPI } from '@api/datahub/weather/getWeatherForecast';
import { useCallback } from 'react';
import { atom, useSetAtom } from 'jotai';
import type { WeatherForecast, WeatherLocation } from './types';

/**
 * 天気情報地点
 */
export const weatherLocationAtom = atom<WeatherLocation | null>(null);

/**
 * 天気予報情報
 */
export const weatherForecastsAtom = atom<WeatherForecast[]>([]);

/**
 * 天気情報が取得可能かどうか
 */
export const enableWeatherInfoSelector = atom((get) => {
  const location = get(weatherLocationAtom);
  return !!location;
});

export const useWeatherInfo = () => {
  const { getWeatherForecast } = useWeatherForecastAPI();
  const setWeatherForecasts = useSetAtom(weatherForecastsAtom);

  const getWeatherInfo = useCallback(
    async (locationId?: string) => {
      const forecastRes = await getWeatherForecast(locationId);
      setWeatherForecasts(forecastRes);
    },
    [getWeatherForecast, setWeatherForecasts],
  );

  return getWeatherInfo;
};
