import type { ButtonProps } from '@mui/material';
import { Box, Button, Tooltip, styled } from '@mui/material';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import { makeStyles } from '@mui/styles';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 200,
    textAlign: 'center',
  },
});

type Props = ButtonProps &
  Partial<{
    progress: boolean;
    ratio: number | null;
    tooltipTitle: string | React.ReactNode;
  }>;

const ProgressLabelButton: React.FC<Props> = ({
  progress = false,
  tooltipTitle = undefined,
  ratio = null,
  ...args
}: Props) => {
  const classes = useStyles();

  const button = useMemo(
    () =>
      isNullOrUndefined(tooltipTitle) || args.disabled ? (
        <Button {...args} />
      ) : (
        <Tooltip
          arrow
          title={tooltipTitle}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button {...args} />
        </Tooltip>
      ),
    [tooltipTitle, args, classes.tooltip],
  );

  return (
    <WrapperBox>
      {!progress && button}
      {progress && <CircularProgressWithLabel size={32} ratio={ratio} />}
    </WrapperBox>
  );
};

export default React.memo(ProgressLabelButton);

const WrapperBox = styled(Box)`
  position: relative;
`;
