import { useProjectsAPI } from '@api/auth';
import { useEnvironmentsAPI } from '@api/fms';
import type { Project } from '@data/auth';
import { projectsAtom } from '@data/auth';
import { CircularProgress, List, Typography, styled, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import ProjectEnvironmentListItem from '@components/common/atoms/ProjectEnvironmentListItem';
import { useTranslation } from 'react-i18next';
import type { Environment } from '@data/fms/environment/types';
import { environmentsAtom } from '@data/fms/environment/states';
import { useSetAtom } from 'jotai';

type ProjectListProps = {
  selected: Project | null;
  onSelect: (project: Project) => void;
  displayProjects: Project[];
};

export const ProjectList: React.FC<ProjectListProps> = ({
  selected,
  onSelect,
  displayProjects,
}: ProjectListProps) => {
  const { loading, getProjects } = useProjectsAPI();
  const setProjects = useSetAtom(projectsAtom);
  const { t } = useTranslation();

  useMount(async () => {
    const res = await getProjects();
    setProjects(res);
  });

  const handleClickProject = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const selectedId = e.currentTarget.dataset.id;
      if (!isNullOrUndefined(onSelect) && !isNullOrUndefined(selectedId)) {
        const targetProject = displayProjects.find(
          (project) => project.id === selectedId,
        );
        if (isNullOrUndefined(targetProject)) return;
        onSelect(targetProject);
      }
    },
    [onSelect, displayProjects],
  );

  if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (displayProjects.length === 0) {
    return (
      <Typography sx={{ mt: 14 }} align="center">
        {t('common.text.none', { name: t('common.fms.project').toLowerCase() })}
      </Typography>
    );
  }

  return (
    <>
      {displayProjects.map((project, i) => (
        <ProjectEnvironmentListItem
          key={i}
          value={project.display_name ?? project.id}
          data-id={project.id}
          data-testid={`select-${project.id}`}
          onClick={handleClickProject}
          selected={selected?.id === project.id}
        />
      ))}
    </>
  );
};

type EnvironmentListProps = {
  selected: Environment | null;
  project: Project | null;
  onSelect: (environment: Environment) => void;
  displayEnvironments: Environment[];
};

export const EnvironmentList: React.FC<EnvironmentListProps> = ({
  project,
  selected,
  onSelect,
  displayEnvironments,
}: EnvironmentListProps) => {
  const { state, getEnvironments } = useEnvironmentsAPI();
  const setEnvironments = useSetAtom(environmentsAtom);
  const { t } = useTranslation();

  const handleClickEnvironment = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const index = Number(e.currentTarget.dataset.no);
      if (!isNullOrUndefined(onSelect)) onSelect(displayEnvironments[index]);
    },
    [displayEnvironments, onSelect],
  );

  useEffect(() => {
    (async () => {
      if (!project) return;
      const res = await getEnvironments(project.id);
      setEnvironments(res);
    })();
  }, [project, getEnvironments, setEnvironments]);

  if (isNullOrUndefined(project?.id)) return null;

  if (state === 'loading') {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (displayEnvironments.length === 0) {
    return (
      <Typography sx={{ mt: 14 }} align="center">
        {t('common.text.none', {
          name: t('common.fms.driving_environment').toLowerCase(),
        })}
      </Typography>
    );
  }

  return (
    <>
      {displayEnvironments.map((environment, i) => (
        <ProjectEnvironmentListItem
          value={environment.environment_name}
          key={i}
          data-no={i}
          data-testid={`select-${environment.environment_id}`}
          onClick={handleClickEnvironment}
          selected={selected?.environment_id === environment.environment_id}
        />
      ))}
    </>
  );
};

export const ProjectEnvironmentList = styled(List)`
  border: solid 1px ${grey[300]};
  border-radius: 4px;
  box-sizing: border-box;
  width: 466px;
  height: 376px;
  overflow: auto;
  padding: 0;
`;
