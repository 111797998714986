import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import type { Schedule } from '@data/fms/schedule/types';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type APIResponse = {
  schedules: Schedule[];
  total: number;
  offset: number;
  maxOffset: number;
};

/**
 * 指定車両の、指定された件数の直近のスケジュール情報取得API
 * 件数が指定されない場合のデフォルト件数は10件。
 */
export const useSchedulesAPI = (): {
  state: APIState;
  getSchedules: (
    vehicleId: string,
    limit?: number,
    offset?: number,
  ) => Promise<APIResponse | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getSchedulesAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      limit: number,
      offset: number,
    ): Promise<Result<APIResponse, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/schedules?limit=${limit}&offset=${offset}`,
        );
        const resData = res?.data;
        const str = res?.request?.responseURL;
        if (str) {
          const url = new URL(str);
          const params = url.searchParams;
          resData.limit = Number(params.get('limit'));
          resData.offset = Number(params.get('offset'));
          resData.maxOffset = Math.ceil(resData.total / resData.limit);
        }
        return new Success(resData);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getSchedules = useAtomCallback(
    useCallback(
      async (
        get,
        _,
        vehicleId: string,
        limit: number | undefined = 10,
        offset: number | undefined = 1,
      ) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribeSchedule)) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_schedules', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        const res = await getSchedulesAPI(
          project!.id,
          environment!.environment_id,
          vehicleId,
          limit as number,
          offset as number,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_schedules', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, getSchedulesAPI, getHasScope, t, getErrorMessage],
    ),
  );

  return {
    state,
    getSchedules,
  };
};

/**
 * 指定スケジュールIDの情報取得API
 */
export const useScheduleAPI = (): {
  state: APIState;
  getSchedule: (scheduleId: string) => Promise<Schedule | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getScheduleAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      scheduleId: string,
    ): Promise<Result<Schedule, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/schedules/${scheduleId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getSchedule = useAtomCallback(
    useCallback(
      async (get, _, scheduleId: string) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribeSchedule)) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project && !environment) {
          notifyError({
            message: t('api.fms.get_schedule', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        const res = await getScheduleAPI(
          project!.id,
          environment!.environment_id,
          scheduleId,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_schedule', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, getScheduleAPI, getHasScope, t, getErrorMessage],
    ),
  );

  return {
    state,
    getSchedule,
  };
};
