import { weatherForecastsAtom } from '@data/datahub/weather';
import type { WeatherForecast } from '@data/datahub/weather/types';
import { environmentOptionAtom } from '@data/fms/environment/states';
import { Box, colors, Grid, Typography, styled } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronRight } from 'react-feather';

const ITEM_WIDTH = 74;
const EXPAND_BUTTON_WIDTH = 16;

type WeatherItemProps = {
  data: WeatherForecast;
  precipitationThreshold: number;
};

const WeatherItem: React.FC<WeatherItemProps> = React.memo(
  ({ data, precipitationThreshold }: WeatherItemProps) => {
    const isWarning = useMemo(
      () => data.precipitation >= precipitationThreshold,
      [data.precipitation, precipitationThreshold],
    );

    if (isNullOrUndefined(data)) return null;

    return (
      <Grid item>
        <Box
          textAlign="center"
          sx={{ pointerEvents: 'none', userSelect: 'none' }}
        >
          <Time>{DateTime.fromISO(data.forecast_time).toFormat('HH')}</Time>
          <Temperature>{data.temperature}&deg;</Temperature>
          <IconWrapper>
            <img src={`/assets/img/weather/dark/${data.weather_code}.png`} />
          </IconWrapper>
          <Precipitation
            color={isWarning ? 'error' : 'textPrimary'}
          >{`${data.precipitation}mm`}</Precipitation>
        </Box>
      </Grid>
    );
  },
);

WeatherItem.displayName = 'WeatherItem';

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 42px;
  height: 42px;
  line-height: 0;
  margin: ${({ theme }) => theme.spacing(2)} 0;

  img {
    width: 100%;
    display: block;
  }
`;

const Time = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
`;

const Temperature = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
`;

const Precipitation = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
`;

// アイコン表示数
const DISPLAY_NUM = 7;

const WeatherInfoPanel: React.FC = () => {
  const environmentOption = useAtomValue(environmentOptionAtom);
  const forecasts = useAtomValue(weatherForecastsAtom);
  const [isExpand, setIsExpand] = useState(false);

  const sliceArr = useMemo(() => {
    const startIndex =
      environmentOption?.weather_forecast_after_hours_threshold ?? 1;
    return [...forecasts].splice(startIndex - 1, DISPLAY_NUM);
  }, [forecasts, environmentOption]);

  const expandWidth = useMemo(() => sliceArr.length * ITEM_WIDTH, [sliceArr]);

  const handleClickExpand = useCallback(() => {
    setIsExpand((prevState) => !prevState);
  }, []);

  // 予報情報が無い場合表示しない
  if (forecasts.length === 0) return null;

  return (
    <Wrapper>
      <Panel expand={+isExpand} expandwidth={expandWidth}>
        <Box width={expandWidth}>
          <Grid container spacing={8}>
            {sliceArr.map((forecast, i) => (
              <WeatherItem
                key={`forecast${i}`}
                data={forecast}
                precipitationThreshold={
                  environmentOption?.weather_forecast_precipitation_threshold ??
                  1.0
                }
              />
            ))}
          </Grid>
        </Box>
        <ExpandButton expand={+isExpand} onClick={handleClickExpand}>
          <ChevronRight size={18} color={colors.grey[500]} />
        </ExpandButton>
      </Panel>
    </Wrapper>
  );
};

export default React.memo(WeatherInfoPanel);

type ExpandProps = {
  expand: number;
};

const Wrapper = styled(Box)`
  position: absolute;
  left: ${({ theme }) => theme.spacing(2)};
  bottom: ${({ theme }) => theme.spacing(8)};
`;

type PanelProps = ExpandProps & { expandwidth: number };

const Panel = styled(Box)<PanelProps>`
  overflow: hidden;
  width: ${({ expand, expandwidth }) =>
    `${(expand ? expandwidth : ITEM_WIDTH) + EXPAND_BUTTON_WIDTH}px`};
  height: 130px;
  position: relative;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  transition: width 300ms ease-out;
`;

const ExpandButton = styled(Box)<ExpandProps>`
  position: absolute;
  right: 0;
  top: 0;
  width: ${EXPAND_BUTTON_WIDTH}px;
  height: 100%;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ expand }) => (expand ? 180 : 0)}deg);
`;
