export const computeLatLngBounds = (
  latLngList: google.maps.LatLngLiteral[],
): google.maps.LatLngBounds => {
  const bounds = new window.google.maps.LatLngBounds();
  latLngList.forEach(({ lat, lng }) => {
    bounds.extend(
      new window.google.maps.LatLng({
        lat,
        lng,
      }),
    );
  });
  return bounds;
};
