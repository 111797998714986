import isNullOrUndefined from '@utils/isNullOrUndefined';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import store from 'store2';
import type {
  AnalysisCSVDiagnostic,
  AnalysisStorageKey,
  AnalysisStorageValue,
} from './types';

export const diagNameColors = [
  '#01FBCF',
  '#013F68',
  '#363638',
  '#AF9F11',
  '#ED8905',
  '#FF0110',
];

export const diagNameColorScale = (splitNum: number): chroma.Scale =>
  chroma.scale(diagNameColors).domain([0, splitNum]);

export const scanMatchingScoreColors = [
  '#FF0110',
  '#BAA80E',
  '#403F35',
  '#013663',
  '#019999',
  '#01FBCF',
];

export const scanMatchingScoreColorScale = chroma
  .scale(scanMatchingScoreColors)
  .domain([0, 6.0]);

export const getAnalysisStorage = (key: AnalysisStorageKey) => {
  const savedConfig = store.local.get('analysis_config');
  if (isNullOrUndefined(savedConfig)) return null;
  return key ? savedConfig[key] : null;
};

export const setAnalysisStorage = (
  key: AnalysisStorageKey,
  value: AnalysisStorageValue,
) => {
  const storageKey = 'analysis_config';
  const savedConfig = store.local.get(storageKey);
  store.local.set(storageKey, {
    ...savedConfig,
    [key]: value,
  });
};

/**
 * ダウンロードする CSV 文字列を返す
 * @param diagnostics
 * @returns str
 */
export const generateDiagnosticCSVStr = (
  diagnostics: AnalysisCSVDiagnostic[],
) => {
  // header 行
  const headerLine =
    'data:text/csv;charset=utf-8,From,To,Message,Hardware ID,Name,Level,Values\n';
  const lines = diagnostics.reduce(
    (
      prev,
      {
        start_timestamp,
        end_timestamp,
        message,
        hardware_id,
        name,
        level,
        valueHistories,
      },
    ) => {
      const startTimestamp = DateTime.fromISO(start_timestamp).toFormat(
        'yyyy/MM/dd HH:mm:ss.SSS',
      );
      const endTimestamp = DateTime.fromISO(end_timestamp).toFormat(
        'yyyy/MM/dd HH:mm:ss.SSS',
      );
      // diag 行
      const diagnosticLine = `${startTimestamp},${endTimestamp},${message},${hardware_id},${name},${level}\n`;
      // diag に紐づく key, value 行を追加
      const keyValueLine = valueHistories.reduce((prevLine, history) => {
        const timestamp = DateTime.fromISO(history.timestamp).toFormat(
          'yyyy/MM/dd HH:mm:ss.SSS',
        );
        const addedTimestamp = (prevLine += `,,,,,,${timestamp}`);
        return history.values.reduce((prevKeyValueLine, keyValue, i) => {
          const keyValueStr = `key: ${keyValue.key},value:${keyValue.value}\n`;
          return (prevKeyValueLine +=
            i === 0 ? `,${keyValueStr}` : `,,,,,,,${keyValueStr}`);
        }, addedTimestamp);
      }, diagnosticLine);
      return `${prev}${keyValueLine}`;
    },
    headerLine,
  );

  return encodeURI(lines);
};
