import axios from 'axios';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount } from 'react-use';
import { usePostAuthorizationAPI } from './auth';

export type APIState = 'none' | 'loading' | 'hasValue' | 'hasError';

export type Result<T, E> = Success<T, E> | Failure<T, E>;

export class Success<T, E> {
  constructor(readonly value: T) {}
  type = 'success' as const;
  isSuccess(): this is Success<T, E> {
    return true;
  }
  isFailure(): this is Failure<T, E> {
    return false;
  }
}

export class Failure<T, E> {
  constructor(readonly value: E) {}
  type = 'failure' as const;
  isSuccess(): this is Success<T, E> {
    return false;
  }
  isFailure(): this is Failure<T, E> {
    return true;
  }
}

const useInterceptors = (api: AxiosInstance) => {
  const getToken = usePostAuthorizationAPI();

  useMount(() => {
    // リクエスト前処理
    api.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        if (!token) {
          // 認可トークンが取得出来ない場合リクエストをキャンセルする
          throw new axios.Cancel('token is null');
        }
        config.headers['Content-Type'] = 'application/json';
        config.headers.Authorization = `Bearer ${token.accessToken}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    // レスポンス前処理
    api.interceptors.response.use(
      (res) => res,
      (error) => {
        // ステータスコードが 503 の場合は 503.html に遷移
        if (error.response && error.response.status === 503) {
          window.location.href = '/503.html';
          return;
        }
        return Promise.reject(error);
      },
    );
  });
};

type Options = {
  cancelWhenUnmounting: boolean;
};

export const authAPI = axios.create({
  baseURL: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}/${
    import.meta.env.VITE_AUTH_API_VERSION
  }`,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

export const maintenanceAPI = axios.create({
  baseURL: `https://${import.meta.env.VITE_MAINTENANCE_API_DOMAIN}/${
    import.meta.env.VITE_MAINTENANCE_API_VERSION
  }`,
  responseType: 'json',
});

export const useAuthAPI = (): AxiosInstance => {
  const authAPI = useMemo(
    () =>
      axios.create({
        baseURL: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}/${
          import.meta.env.VITE_AUTH_API_VERSION
        }`,
        responseType: 'json',
      }),
    [],
  );
  useInterceptors(authAPI);

  return authAPI;
};

export const useFMSAPI = (): AxiosInstance => {
  const fmsAPI = useMemo(
    () =>
      axios.create({
        baseURL: `https://${import.meta.env.VITE_FMS_API_DOMAIN}/${
          import.meta.env.VITE_FMS_API_VERSION
        }/projects`,
        responseType: 'json',
      }),
    [],
  );
  useInterceptors(fmsAPI);

  return fmsAPI;
};

export const useDatahubAPI = (
  options: Options = { cancelWhenUnmounting: false },
) => {
  const optionsRef = useRef(options);
  const abortControllerRef = useRef(new AbortController());

  const cancel = useCallback(() => {
    abortControllerRef.current.abort();
  }, []);

  const datahubAPI = useMemo(
    () =>
      axios.create({
        baseURL: `https://${import.meta.env.VITE_DATA_HUB_API_DOMAIN}/${
          import.meta.env.VITE_DATA_HUB_API_VERSION
        }/projects`,
        responseType: 'json',
        signal: abortControllerRef.current.signal,
      }),
    [],
  );

  useInterceptors(datahubAPI);

  useUnmount(() => {
    if (optionsRef.current.cancelWhenUnmounting) cancel();
  });

  return { datahubAPI, cancel };
};

export const useErrorMessage = (): (<T>(
  res: Result<T, AxiosResponse>,
) => string) => {
  const { t } = useTranslation();

  const getErrorMessage = useCallback(
    <T>(res: Result<T, AxiosResponse>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (res as any).value?.data?.message ?? t('api.cause_unknown');
    },
    [t],
  );

  return getErrorMessage;
};
