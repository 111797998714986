import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

type Props = {
  open: boolean;
  height?: string | number;
  circularSize?: number;
};

const DialogProgress: React.FC<Props> = ({
  open,
  height = '100%',
  circularSize = 24,
}: Props) => {
  if (!open) return null;
  return (
    <WrapperBox sx={{ height }}>
      <CircularProgress size={circularSize} />
    </WrapperBox>
  );
};

export default React.memo(DialogProgress);

const WrapperBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
`;
