import type { LatLng } from '@data/fms/place/types';
import { Marker } from '@react-google-maps/api';
import React from 'react';

type Props = {
  position: LatLng;
};

const SIZE = 28;
const CENTER = SIZE * 0.5;

const RouteOriginDestinationMarker: React.FC<Props> = ({ position }: Props) => (
  <Marker
    position={position}
    zIndex={150}
    icon={{
      url: '/assets/img/maps/origin_destination_point.png',
      scaledSize: new window.google.maps.Size(SIZE, SIZE),
      anchor: new window.google.maps.Point(CENTER, CENTER),
    }}
  />
);

export default React.memo(RouteOriginDestinationMarker);
