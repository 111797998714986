import { useEffect, useState } from 'react';
import { VisualizeEngine } from '@tier4/autowareviz-core';
import { MessageEngine } from '@tier4/autowareviz-messages';
import { MediaEngine, DataEngine } from '@tier4/autowareviz-react';
import { ObjectEngine } from '@tier4/autowareviz-threejs';
import { MessageSource } from '@tier4/autowareviz-extension-ct-message-source';

export const useViz = (ws: WebSocket, vehicleId: string, projectId: string) => {
  const [engine, setEngine] = useState<VisualizeEngine>();

  useEffect(() => {
    const engine = new VisualizeEngine({
      messageEngine: new MessageEngine(),
      mediaEngine: new MediaEngine(),
      dataEngine: new DataEngine(),
      objectEngine: new ObjectEngine(),
    });

    const messageSource = new MessageSource(ws, vehicleId, projectId);
    engine.connect(messageSource);

    setEngine(engine);

    return () => {
      engine.disconnect();
      engine.dispose();
    };
  }, [projectId, vehicleId, ws]);

  return engine;
};
