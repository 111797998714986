import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import type {
  LaneletCenterLinePoints,
  LaneletsCenterLinePoint,
  LaneletsCenterLinePoints,
  Lanes,
} from '@data/fms/lanelet/types';
import type {
  NoEntryZoneSetting,
  NoEntryZoneSettingWithLanelets,
  NoEntryZoneWithLanelets,
} from '@data/fms/noEntryZone/type';
import { useNotification } from '@data/notification';
import { notNull } from '@utils/isNullOrUndefined';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type APIResponse = LaneletsCenterLinePoints & {
  next_page_token?: string;
};

/**
 * Lanelets Center Points 取得API
 */
export const useLaneletsCenterLinePointsAPI = (): {
  state: APIState;
  getLaneletsCenterLinePoints: () => Promise<LaneletsCenterLinePoints | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/lanelets_center_line_points?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getLaneletsCenterLinePoints = useAtomCallback(
    useCallback(
      async (get) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_lanelets_center_line_points', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }

        // 分割リクエスト
        let points: LaneletsCenterLinePoint[] = [];
        let areaMapVersionId = '';
        const params = new URLSearchParams();
        params.append('page_size', '150');

        const splitRequest = async (): Promise<'error' | 'success'> => {
          const res = await request(
            project.id,
            environment.environment_id,
            params,
          );
          // 取得失敗時
          if (!res || res.isFailure()) {
            // リクエストに失敗した場合
            notifyError({
              message: `${t('api.fms.get_lanelets_center_line_points', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
            return 'error';
          }
          // 取得成功時
          if (res && res.isSuccess()) {
            areaMapVersionId = res.value.area_map_version_id;
            points = points.concat(res.value.lanelets_center_line_points);
            if (
              res.value.next_page_token &&
              res.value.next_page_token.length > 0
            ) {
              // レスポンスに next_page_token がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            }
          }
          return 'success';
        };

        const splitRequestRes = await splitRequest();
        if (splitRequestRes === 'error') {
          setState('hasError');
          return null;
        }

        setState('hasValue');
        return {
          area_map_version_id: areaMapVersionId,
          lanelets_center_line_points: points,
        };
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  return {
    state,
    getLaneletsCenterLinePoints,
  };
};

/**
 * 指定レーンの Center Points 取得API
 */
export const useLaneletIdCenterLinePointsAPI = (): {
  state: APIState;
  getLaneletIdCenterLinePoints: (
    laneId: number,
  ) => Promise<LaneletCenterLinePoints | null>;
  getMultipleLaneletIdCenterLinePoints: (
    laneIds: number[],
  ) => Promise<LaneletCenterLinePoints[]>;
  getNoEntryZoneSettingLanelets: (
    setting: NoEntryZoneSetting,
  ) => Promise<NoEntryZoneSettingWithLanelets | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      areaMapId: number,
      areaMapVersionId: string,
      laneletId: number,
    ): Promise<Result<LaneletCenterLinePoints, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/area_maps/${areaMapId}/versions/${areaMapVersionId}/lanelets/${laneletId}/center_line_points`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getLaneletIdCenterLinePoints = useAtomCallback(
    useCallback(
      async (get, _, laneletId: number) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_lanelets_center_line_points', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }

        const res = await request(
          project.id,
          environment.environment_id,
          environment.area_map_id,
          environment.area_map_version_id,
          laneletId,
        );
        // 取得失敗時
        if (!res || res.isFailure()) {
          // リクエストに失敗した場合
          notifyError({
            message: `${t('api.fms.get_lanelets_center_line_points', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }

        setState('hasValue');
        return res.value;
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  const getMultipleLaneletIdCenterLinePoints = useAtomCallback(
    useCallback(
      async (get, _, laneIds: number[]) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_lanelets_center_line_points', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return [];
        }

        const responses = await Promise.all(
          laneIds.map(async (laneId) => {
            const res = await request(
              project.id,
              environment.environment_id,
              environment.area_map_id,
              environment.area_map_version_id,
              laneId,
            );
            if (!res.value || res.isFailure()) {
              // notifyError({ message:
              //   `${t('api.data.get_aggregated_data', {
              //     status: 'failed',
              //   })}: ${getErrorMessage(res)}`,
              // );
              return null;
            }
            return res.value;
          }),
        );

        return responses.filter(notNull);
      },
      [notifyError, request, t],
    ),
  );

  const getNoEntryZoneSettingLanelets = useAtomCallback(
    useCallback(
      async (get, _, setting: NoEntryZoneSetting) => {
        setState('loading');
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_lanelets_center_line_points', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return null;
        }

        const responses = (await Promise.all(
          setting.no_entry_zones.map(async (zone) => {
            const lanes: Lanes = {};
            const datas = await Promise.all(
              zone.lanelet_ids.map(async (laneId) => {
                const res = await request(
                  project.id,
                  environment.environment_id,
                  environment.area_map_id,
                  environment.area_map_version_id,
                  laneId,
                );
                if (!res.value || res.isFailure()) {
                  return null;
                }

                return res.value;
              }),
            );

            datas.forEach((data) => {
              if (data) {
                data.lanelet_center_line_points.forEach((points, i) => {
                  const laneId = points.lanelet_id.toString();
                  if (i === 0) {
                    lanes[laneId] = [];
                  }
                  lanes[laneId].push({
                    lat: points.location.lat,
                    lng: points.location.lng,
                  });
                });
              }
            });

            return {
              ...zone,
              lanelets: lanes,
            } as NoEntryZoneWithLanelets;
          }),
        )) as NoEntryZoneWithLanelets[];

        return {
          ...setting,
          no_entry_zones: responses,
        } as NoEntryZoneSettingWithLanelets;
      },
      [request, notifyError, t],
    ),
  );

  return {
    state,
    getLaneletIdCenterLinePoints,
    getMultipleLaneletIdCenterLinePoints,
    getNoEntryZoneSettingLanelets,
  };
};
