import { SCOPES, useHasScope } from '@data/auth';
import { Box, colors, IconButton, Popover, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  areaMapVersionsAtom,
  currentAreaMapVersionSelector,
} from '@data/fms/areamap/states';
import { useRootPath } from '@data/fms/environment/hooks';
import { useAtomValue } from 'jotai';

const HeaderMapVersionAlert = (): JSX.Element | null => {
  const areaMapVersions = useAtomValue(areaMapVersionsAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { i18n } = useTranslation();
  const rootPath = useRootPath();
  const getHasScope = useHasScope();
  const currentAreaMapVersion = useAtomValue(currentAreaMapVersionSelector);

  const latestAreaMapVersion = useMemo(() => {
    if (areaMapVersions.length <= 0) {
      return undefined;
    }
    const latest = areaMapVersions.reduce((a, b) =>
      DateTime.fromISO(a.release_status_updated_at) >
      DateTime.fromISO(b.release_status_updated_at)
        ? a
        : b,
    );
    return latest;
  }, [areaMapVersions]);

  const usingOldAreaMapVersion = useMemo(
    () =>
      currentAreaMapVersion?.area_map_id ===
        latestAreaMapVersion?.area_map_id &&
      currentAreaMapVersion?.version_id !== latestAreaMapVersion?.version_id,
    [latestAreaMapVersion, currentAreaMapVersion],
  );

  const mapIconColorStyle = useMemo(() => {
    return usingOldAreaMapVersion
      ? { color: colors.amber[700] }
      : { color: colors.grey[500] };
  }, [usingOldAreaMapVersion]);

  const showCaution = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const hideCaution = useCallback(async () => {
    setAnchorEl(null);
  }, []);

  const message = useMemo(() => {
    if (i18n.language === 'ja') {
      return (
        <>
          <Typography>エリアマップバージョンが最新ではありません</Typography>
          <Typography>
            <NavLink
              to={`${rootPath}driving-environment/`}
              style={{ color: colors.blue[800] }}
              onClick={hideCaution}
            >
              走行環境ページ
            </NavLink>
            より最新バージョンを確認してください
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography>Area map version is not up to date.</Typography>
        <Typography>
          Please check the latest version from the{' '}
          <NavLink
            to={`${rootPath}driving-environment/`}
            style={{ color: colors.blue[800] }}
            onClick={hideCaution}
          >
            Driving Environment page
          </NavLink>
          .
        </Typography>
      </>
    );
  }, [i18n.language, hideCaution, rootPath]);

  if (!getHasScope(SCOPES.DescribeAreaMap)) return null;

  return (
    <>
      <IconButton
        aria-owns={!isNullOrUndefined(anchorEl) ? 'map-ver-state' : undefined}
        aria-haspopup="true"
        onClick={showCaution}
        sx={mapIconColorStyle}
        disabled={!usingOldAreaMapVersion}
        data-testid="areaMapVerState-icon"
      >
        <Map />
      </IconButton>
      <Popover
        id="map-ver-state"
        open={!isNullOrUndefined(anchorEl)}
        anchorEl={anchorEl}
        onClose={hideCaution}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={4}>{message}</Box>
      </Popover>
    </>
  );
};

export default HeaderMapVersionAlert;
