import type { Probe } from '@data/datahub/probe/types';
import type { PeriodTimestamp } from '@data/datahub/types';
import type { SelectedHistoryData } from '@components/pages/DrivingHistory/types';
import { useGoogleMap } from '@react-google-maps/api';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { computeLatLngBounds } from '@utils/googlemaps';

export const useOverridePolyline = (
  probeData: Probe[],
  selectedManualOverride: SelectedHistoryData<PeriodTimestamp>,
) => {
  const map = useGoogleMap();

  // Override 期間中の Probe を抽出
  const periodProbeData = useMemo(
    () =>
      probeData.filter((probe) => {
        const probeTimestamp = DateTime.fromISO(probe.timestamp);
        const manualOverrideStartTimestamp = DateTime.fromISO(
          selectedManualOverride.data.start_timestamp,
        );
        const manualOverrideEndTimestamp = DateTime.fromISO(
          selectedManualOverride.data.end_timestamp,
        );
        return (
          manualOverrideStartTimestamp <= probeTimestamp &&
          probeTimestamp <= manualOverrideEndTimestamp
        );
      }),
    [probeData, selectedManualOverride],
  );

  const path = useMemo(() => {
    const clone = periodProbeData.concat();
    if (clone.length === 1) {
      // NOTE: latLng が1つしか無い場合 Polyline を描画できないため複製する
      clone.push(clone[0]);
    }
    return clone
      .filter((probe) => probe.lat !== undefined && probe.lng !== undefined)
      .map((probe) => ({
        lat: probe.lat,
        lng: probe.lng,
      })) as google.maps.LatLngLiteral[];
  }, [periodProbeData]);

  useEffect(() => {
    if (!map || path.length === 0) return;
    const bounds = computeLatLngBounds(path);
    map.fitBounds(bounds);
  }, [map, path]);

  return {
    path,
  };
};
