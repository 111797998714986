import {
  isConnected,
  isDisconnected,
  isError,
  isShutdown,
  isSuspending,
} from '@data/fms/vehicle/utils';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import store from 'store2';
import { environmentAtom } from '../environment/states';
import type { RemoteMonitorConfig, Vehicle } from './types';

export const storedVehicleIdAtom = atom<string | undefined>(
  (store.local.get('vehicle_id') as string) ?? undefined,
);

export const vehiclesAtom = atom<Vehicle[]>([]);

export const remoteMonitorConfigAtom = atom<RemoteMonitorConfig>({
  open: false,
  vehicle: null,
  monitorType: null,
});

/**
 * 走行環境のすべての車両を返す
 */
export const allVehiclesSelector = atom((get) => {
  const environment = get(environmentAtom);
  const vehicles = get(vehiclesAtom);
  return vehicles.filter(
    (vehicle) => vehicle.environment_id === environment?.environment_id,
  );
});

/**
 * 接続済みの車両一覧を返す
 * @param {Boolean} includeConnected
 */
export const connectedVehiclesSelector = atomFamily(
  (includeConnected: boolean) =>
    atom((get) => {
      const vehicles = get(allVehiclesSelector);
      if (includeConnected) {
        return vehicles.filter(
          (vehicle) => !isDisconnected(vehicle) && !isShutdown(vehicle),
        );
      }
      return vehicles.filter(
        (vehicle) =>
          !isDisconnected(vehicle) &&
          !isConnected(vehicle) &&
          !isShutdown(vehicle),
      );
    }),
);

/**
 * 未接続の車両一覧を返す
 * disconnected || shutdown
 * @param {Boolean} includeConnected
 */
export const disconnectedVehiclesSelector = atomFamily(
  (includeConnected: boolean) =>
    atom((get) => {
      const vehicles = get(allVehiclesSelector);
      if (includeConnected) {
        return vehicles.filter(
          (vehicle) => isDisconnected(vehicle) || isShutdown(vehicle),
        );
      }
      return vehicles.filter(
        (vehicle) =>
          isDisconnected(vehicle) ||
          isConnected(vehicle) ||
          isShutdown(vehicle),
      );
    }),
);

/**
 * allVehiclesSelectorからエラーの車両一覧を返す
 */
export const errorVehiclesSelector = atom((get) => {
  const vehicles = get(allVehiclesSelector);
  return vehicles.filter((vehicle) => isError(vehicle));
});

/**
 * connectedVehiclesSelectorから一時停止中の車両一覧を返す
 */
export const suspendingVehiclesSelector = atom((get) => {
  const vehicles = get(connectedVehiclesSelector(false));
  return vehicles.filter((vehicle) => isSuspending(vehicle));
});

/**
 * スケジュール登録可能な車両を返す
 */
export const scheduleRegistrableVehiclesSelector = atom((get) => {
  const vehicles = get(allVehiclesSelector);
  return vehicles.filter(
    (vehicle) =>
      !isDisconnected(vehicle) &&
      !isShutdown(vehicle) &&
      vehicle.acceptable_order !== 'none',
  );
});

/**
 * allVehiclesSelectorから個別の車両情報を返す
 */
export const vehicleSelector = atomFamily((vehicleId: string | undefined) =>
  atom((get) => {
    const vehicles = get(allVehiclesSelector);
    return vehicles.find((vehicle) => vehicle.vehicle_id === vehicleId);
  }),
);

/**
 * 車両マーカーのInfoWindowの開閉状態
 */
export const isOpenVehicleInfoWindow = atom(false);
